<span class="user-device-info" (click)="showDeviceDetails()">
    <ng-container *ngIf="state === 'expanded'">{{ 'notifications.view.deviceName' | translate }}: </ng-container> <span>{{device.deviceName}}</span>
    <fa-icon class="ml-2 fa-icon" *ngIf="state === 'expanded'" [icon]="['fas','chevron-up']" size="sm"></fa-icon>
    <fa-icon class="ml-2 fa-icon" *ngIf="state === 'collapsed'" [icon]="['fas','chevron-down']" size="sm"></fa-icon>
</span>
<div>
    <div [@bodyExpansion]="state">
        <span class="user-device-info">
            {{ 'notifications.view.deviceOperatingSystem' | translate }}: <span>{{device.operatingSystem === 0 ? "Android" : "iOS"}}</span>
        </span>
        <span class="user-device-info">
            {{ 'notifications.view.lastLogin' | translate }}: <span>{{device.lastLoginDateTimeUtc | localizedDate}}</span>
        </span>
    </div>
</div>