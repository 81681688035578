import { Injectable } from '@angular/core';
import { FilterService } from 'primeng/api';
import { LocalizedDatePipe } from '../helpers/localizeddate.pipe';
import * as moment from "moment";

@Injectable()
export class FilterTableService {
  constructor(private filterService: FilterService,
    private datePipe: LocalizedDatePipe
  ) { }

  handleFilterService() {
    this.filterService.register(
      "containsOrIsDate",
      (value, filter): boolean => {
        if (!filter) {
          return true;
        }

        if (!value) {
          return false;
        }
        let dateFilter = new Date(filter);
        let dateValue = Date.parse(value);

        if (moment.isDate(dateFilter) && !isNaN(dateValue)) {
          var datePipeTransform = this.datePipe.transform(dateValue.toString());
          return datePipeTransform.includes(filter);
        }

        return value
          .toString()
          .toLowerCase()
          .includes(filter.toString().toLowerCase());
      }
    );
  }
}