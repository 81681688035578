import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class RedirectGuard implements CanActivate {
    baseUrl: string;

    constructor(private router: Router) { }

    canActivate() {
        let redirectUrl = "/home";
        if (localStorage.getItem("systemType") === "1" || localStorage.getItem("systemType") === "3") {
            redirectUrl = "/smart-gate";
        }
        this.router.navigate([redirectUrl]);
        return true;
    }
}