import { ElementRef, Injectable, ViewChild } from "@angular/core";

export class AutomaticScroll {
    scrollToAddedContent(addedContent: ElementRef<any>) {
        setTimeout(() => {
            if (addedContent) {
                addedContent.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
            }
        }, 1);
    }
}