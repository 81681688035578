import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { TranslateService } from "@ngx-translate/core";
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { CookieLangChanger } from "../cookie/cookie.language.changer";

@Injectable()
export class AuthGuard implements CanActivate {
    baseUrl: string;

    constructor(private oauthService: OAuthService,
        private translate: TranslateService,
        private dateTimeAdapter: DateTimeAdapter<any>,
        private cookieLangChanger: CookieLangChanger,
        private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot) {
        if (this.oauthService.hasValidAccessToken()) {
            this.translate.use(localStorage.getItem("defaultLanguage"));
            this.dateTimeAdapter.setLocale(localStorage.getItem("defaultLanguage"));
            this.cookieLangChanger.setCookieLanguage();
            let url: string = route.url.toString();
            let systemType = localStorage.getItem("systemType");

            if (systemType === '1' || systemType === '3') {
                if ((url === 'smart-gate' || url === 'smart-gate-archive' || url === 'kpi')) {
                    return true;
                }
                if ((url === 'users' || url === 'company-details' || url === 'reports' || url === 'handled-vehicles') && (localStorage.getItem("userType") == '1' || localStorage.getItem("userType") == '2')){
                    return true;
                }
                if ((url === 'companies' || url === 'security-post' || url === 'predefined-values') || url === 'devices' && localStorage.getItem("userType") == '2') {
                    return true;
                }
                if ((url === 'reports') && (localStorage.getItem("userType") == '0')) {
                    return true;
                }
                return false;
            } else {
                if (url === 'home' && (localStorage.getItem("userType") != '3' && localStorage.getItem("userType") != '4')) {
                    return true;
                }
                if (url === 'longterm' && (localStorage.getItem("userType") != '0' && localStorage.getItem("userType") != '4')) {
                    return true;
                }
                if (url === 'longterm-smartsync' && localStorage.getItem("userType") === '3' ) {
                    return true;
                }
                if (url === 'archive' && (localStorage.getItem("userType") != '3' && localStorage.getItem("userType") != '4')) {
                    return true;
                }
                if ((url === 'company-details' || url === 'users') && (localStorage.getItem("userType") == '1' || localStorage.getItem("userType") == '2')) {
                    return true;
                }
                if ((url === 'companies' || url === 'buildings' || url === 'receptions' || url === 'vehicles' || url === 'devices' || url === 'reception-messages' || url === 'parkings') && localStorage.getItem("userType") == '2') {
                    return true;
                }
                if (url === 'security' && localStorage.getItem("userType") == '4') {
                    return true;
                }
                if (url === 'home' && localStorage.getItem("userType") == '4') {
                    this.router.navigate(['/security']);
                }
                return false;
            }
        } else {
            this.router.navigate(['/login']);
        }
        return false;
    }
}