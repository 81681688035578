import {Component, OnInit, ViewEncapsulation, Inject, Input} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CorrectlyLoggedService } from "../../correctlylogged-service/correctly-logged-service";

@Component({
    selector: 'app-mobile-info',
    templateUrl: './mobile-app-info.component.html',
    styleUrls: ['./mobile-app-info.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MobileAppInfoComponent implements OnInit {
    @Input() showQrCode: boolean;
    imageBlobUrl: any = null;
    portalType = localStorage.getItem("systemType");
    isMobileApplicationInstalled: boolean;
    private baseUrl: string;

    constructor(private http: HttpClient,
        private correctlyLoggedService: CorrectlyLoggedService,
        @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    ngOnInit() {
        this.correctlyLoggedService.correctlyLogged$().subscribe(response => {
            if (response) {
                this.isMobileApplicationInstalled = JSON.parse(localStorage.getItem("isMobileApplicationInstalled"));
            }
        });
    }

    generateQrCode() {
        this.http.get(this.baseUrl + `api/Qr?textToEncode=${window.origin}`, { responseType: 'blob' }).subscribe(result => {
            if (result) {
                this.createImageFromBlob(result);
            }
        });
    }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.imageBlobUrl = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }
}