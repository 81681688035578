<ng-container *ngIf="data && enableEmbedView">
    <div class="gallery-preview">
        <div class="gallery-preview__content">
            <div class="gallery-preview__main-image-container">
                <ng-container
                        *ngIf="data.images[currentImageIndex].imageUrl; then imageBlock else noImageBlock"></ng-container>
                <ng-template #imageBlock>
                    <div (click)="mainImage_onClick($event)" *ngIf="data.images[currentImageIndex].imageUrl"
                         class="gallery-preview__main-image-container__image"
                         [ngStyle]="{'background-image': 'url(' + data.images[currentImageIndex].imageUrl + ')'}"></div>
                </ng-template>
                <ng-template #noImageBlock>
                    <fa-icon class="gallery-preview__main-image-container__no-image-icon"
                             [icon]="['fal','camera-slash']"></fa-icon>
                </ng-template>
            </div>
        </div>
        <div class="gallery-preview__footer">
            <div (click)="thumbnail_onClick($event, i)" class="gallery-preview__thumbnail"
                 *ngFor="let image of data.images; let i = index;"
                 [ngStyle]="{'background': 'url('+image.imageUrl+')', 'background-size': 'contain', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-color': 'lightsteelblue'}"></div>
        </div>
    </div>
    <div #gallery *ngIf="fullscreenMode" class="gallery" tabindex="0">
        <div class="gallery__header">
            <button class="gallery__button gallery__button--refresh" (click)="resetTransform()">
                <fa-icon class="fa-icon-image" [icon]="['far','arrows-rotate']" size=xs></fa-icon>
            </button>
            <button class="gallery__button gallery__button--rotateleft" (click)="rotate(-90)">
                <fa-icon class="fa-icon-image" [icon]="['far','rotate-left']" size=xs></fa-icon>
            </button>
            <button class="gallery__button gallery__button--rotateright" (click)="rotate(90)">
                <fa-icon class="fa-icon-image" [icon]="['far','rotate-right']" size=xs></fa-icon>
            </button>
            <button class="gallery__button gallery__button--download"
                    (click)="getImageToDownload(data.images[currentImageIndex].imageUrl)">
                <fa-icon class="fa-icon-image" [icon]="['far','download']" size=xs></fa-icon>
            </button>
            <button *ngIf="isDeleteButtonVisible" class="gallery__button gallery__button--delete" (click)="removeImage(data.images[currentImageIndex].imageUrl)">
                <fa-icon class="fa-icon-image" [icon]="['far','trash-alt']" size="xs"></fa-icon>
            </button>
            <button class="gallery__button gallery__button--close" (click)="closeBtn_onClick($event)">
                <fa-icon class="fa-icon-image" [icon]="['fal','times']" size="xs"></fa-icon>
            </button>
        </div>
        <div class="gallery__content">
            <ng-container *ngIf="data">
                <div class="gallery__navigation-bar gallery__navigation-bar--left">
                    <button (click)="leftNavigationBtn_onClick($event)" class="gallery__button"
                            [ngClass]="{'gallery__button--inactive': currentImageIndex <= 0}">
                        <fa-icon class="fa-icon-image" [icon]="['far','chevron-left']"></fa-icon>
                    </button>
                </div>
                <div class="gallery__background-image-container">
                    <ng-container
                            *ngIf="data.images[currentImageIndex].imageUrl; then imageBlock else noImageBlock"></ng-container>
                    <ng-template #imageBlock>
                        <div id="galleryBackgroundImage" class="gallery__background-image-container__image"
                             [ngStyle]="{'background-image': 'url(' + data.images[currentImageIndex].imageUrl + ')',  'transform': 'translate(' + pointX + 'px, ' + pointY + 'px) scale(' + imageScale + ') rotate(' + imageRotate + 'deg)'}">
                        </div>
                    </ng-template>
                    <ng-template #noImageBlock>
                        <fa-icon class="gallery__background-image-container__no-image-icon"
                                 [icon]="['fal','camera-slash']"></fa-icon>
                    </ng-template>
                </div>
                <div class="gallery__navigation-bar gallery__navigation-bar--right">
                    <button (click)="rightNavigationBtn_onClick($event)" class="gallery__button"
                            [ngClass]="{'gallery__button--inactive': currentImageIndex >= data.images.length - 1}">
                        <fa-icon class="fa-icon-image" [icon]="['far','chevron-right']"></fa-icon>
                    </button>
                </div>
            </ng-container>
        </div>
        <ng-template galleryCustomDescriptionHost></ng-template>
        <div class="gallery__footer">
            <ng-container *ngFor="let image of data?.images; let i = index">
                <ng-container
                        *ngIf="(i === currentImageIndex - 1 || i === currentImageIndex || i === currentImageIndex + 1)">
                    <ng-container *ngIf="image.imageUrl; then thumbnailBlock else noThumbnailBlock"></ng-container>
                    <ng-template #thumbnailBlock>
                        <div @cameIn class="gallery__thumbnail"
                             (click)="i === currentImageIndex - 1 && pickPreviousImage() || i === currentImageIndex + 1 && pickNextImage()"
                             [ngStyle]="{'background-image': 'url(' + image.imageUrl + ')'}"
                             [ngClass]="{'gallery__thumbnail--active': i === currentImageIndex}"></div>
                    </ng-template>
                    <ng-template #noThumbnailBlock>
                        <fa-icon [icon]="['fal','camera-slash']" class="gallery__no-thumbnail-icon"
                                 [ngClass]="{'gallery__no-thumbnail-icon--active': i === currentImageIndex}"></fa-icon>
                    </ng-template>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>