import { Component } from '@angular/core';
import { animateText, animateVersionText, toggleSubMenu } from '../shared/animations/animations'
import { AppService } from '../app.service';
import { SidenavService } from '../shared/sidenav-service/sidenav.service'
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-left-menu',
    templateUrl: './left-menu.component.html',
    styleUrls: ['./left-menu.component.scss'],
    animations: [animateText, animateVersionText, toggleSubMenu]
})

export class LeftMenuComponent {
    toolTipDash: string;
    sideNavState: boolean = false;
    portalVersion: string = environment.portalVersion;
    subMenuCollapse: boolean = false;
    systemType;
    instanceLogoUrl: string = '';
    userType: string = localStorage.getItem("userType");
    manageUser: string = localStorage.getItem("manageUser");
    ignoreParking: boolean = JSON.parse(localStorage.getItem("ignoreParking"));
    ownerName: string;
    private state: string = 'default';

    constructor(public router: Router,
        public appService: AppService,
        private sideNavService: SidenavService,
        private translate: TranslateService) { }

    ngOnInit() {
        this.toolTipDash = this.translate.instant('menu.dashboard');
        setInterval(() => {
            this.userType = localStorage.getItem("userType");
            this.manageUser = localStorage.getItem("manageUser");
            this.ownerName = localStorage.getItem("ownerName");
            this.ignoreParking = JSON.parse(localStorage.getItem("ignoreParking"));
        }, 1000);

        this.sideNavService.isSideNavOpen$.subscribe({
            next: (response: boolean) => {
                this.sideNavState = response;
            }
        });
    }

    ngAfterContentChecked(): void {
        this.systemType = localStorage.getItem("systemType");
        this.instanceLogoUrl = localStorage.getItem("instanceLogoUrl");
    }

    showSubMenu() {
        if (this.subMenuCollapse) {
            this.subMenuCollapse = false;
            this.state = (this.state === 'default' ? 'rotated' : 'default');
        }
        else {
            this.subMenuCollapse = true;
            this.state = (this.state === 'default' ? 'rotated' : 'default');
        }
    }

    getHomeLink() {
        if (this.systemType === "1" || this.systemType === "3")
            return '/smart-gate';
        if (this.userType === "4")
            return '/security';
        return '/home';
    }

    getArchiveLink() {
        if (this.systemType === "1" || this.systemType === "3")
            return '/smart-gate-archive';
        return '/archive';
    }

    getReceptionsLink() {
        if (this.systemType === "1" || this.systemType === "3")
            return '/security-post';
        return '/receptions';
    }

    sideNavToggle() {
        this.sideNavService.isSideNavOpen$.next(!this.sideNavState);
    }

    isCustomInstanceLogoSet() {
        if (this.instanceLogoUrl === "null" || this.instanceLogoUrl === "")
            return false;
        else
            return true;
    }
}