import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Subject, BehaviorSubject } from 'rxjs';
import { ISignalRAlert } from "../interfaces/ISignalRAlert";

@Injectable()
export class SignalrService {
    alertInfo = new Subject<ISignalRAlert>();
    updateShipmentInfo = new Subject<object>();
    updateInvitationInfo = new Subject<object>();
    updateParkingInvitation = new Subject<object>();
    updateExcelDateTimeInfo = new Subject<object>();
    deleteInvitationInfo = new Subject<object>();
    userPermissionUpdate = new Subject<any>();
    connectionReconnected = new Subject<object>();
    webPushResponse = new Subject<object>();
    signalRConnectionId = new BehaviorSubject<string>(null);
    private baseUrl: string;
    private hubConnection;

    constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
        this.baseUrl = baseUrl;
        this.signalRConnectionId$.subscribe(response => {
            if (response) {
                this.addToGroupSignalR();
            }
        });
    }

    get signalRConnectionId$() {
        return this.signalRConnectionId;
    }

    startConnection() {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.baseUrl + "notificationHub")
            .withAutomaticReconnect()
            .build();

        this.hubConnection
            .start()
            .catch(err => console.log('Error while starting connection: ' + err));

        window.document.addEventListener("freeze", () => {
            console.log("page freeze");
        });

        this.hubConnection.onclose(error => {
            setTimeout(() => this.hubConnection.start(), 0);

            if (error) {
                console.error(error);
            }
        });

        this.hubConnection.on('GetConnectionId', (connectionId: string) => {
            this.setSignalRConnectionId(connectionId);
        });
    }

    setSignalRConnectionId(connectionId) {
        this.signalRConnectionId.next(connectionId);
    }

    addReconnectListener() {
        this.hubConnection.onreconnected((connectionId) => {
            this.connectionReconnected.next();
        });
    }

    addUpdateWebPushListener() {
        this.hubConnection.on("UpdateWebPushRequestStatus", (webPushRequestStatus) => {
            let data = {
                webPushRequestStatus
            }
            this.webPushResponse.next(data);
        });
    }

    addBroadcastAlertListener() {
        this.hubConnection.on("VehicleNoInsideAlert", (instanceId, invitationId, alertType) => {
            let data: ISignalRAlert = {
                instanceId: instanceId,
                invitationId: invitationId,
                alertType: alertType
            }
            this.alertInfo.next(data);
        });
    }

    addBroadcastDocumentationPickupDelayAlertListener() {
        this.hubConnection.on("DocumentationPickupDelayAlert", (instanceId, invitationId, alertType) => {
            let data: ISignalRAlert = {
                instanceId: instanceId,
                invitationId: invitationId,
                alertType: alertType
            }
            this.alertInfo.next(data);
        });
    }

    addBroadcastExcelDateTimeInfoListener() {
        this.hubConnection.on("UpdateExcelDateTimeInfo", (dateTime, excelType) => {
            let data = {
                dateTime,
                excelType
            }
            this.updateExcelDateTimeInfo.next(data);
        });
    }

    addBroadcastUpdateShipmentInfoListener() {
        this.hubConnection.on("UpdateShipmentInfo", (shipmentInfo, updateWithoutRemoving) => {
            let data = {
                shipmentInfo,
                updateWithoutRemoving
            }
            this.updateShipmentInfo.next(data);
        });
    }

    addBroadcastUpdateInvitationInfoListener() {
        this.hubConnection.on("UpdateInvitationInfo", (invitationInfo) => {
            let data = {
                invitationInfo,
            }
            this.updateInvitationInfo.next(data);
        });
    }

    addBroadcastUpdateParkingInvitationListener() {
        this.hubConnection.on("UpdateParkingInvitation", (invitationInfo) => {
            let data = {
                invitationInfo,
            }
            this.updateParkingInvitation.next(data);
        });
    }

    addBroadcastDeleteInvitationInfoListener() {
        this.hubConnection.on("DeleteInvitationInfo", (invitationInfo) => {
            let data = {
                invitationInfo,
            }
            this.deleteInvitationInfo.next(data);
        });
    }

    addBroadcastUserPermissionChanged() {
        this.hubConnection.on("UpdateUserPermission", () => {
            this.userPermissionUpdate.next();
        });
    }

    invokeUpdateExcelDateTimeInfo(dateTime: string, excelType: string) {
        this.hubConnection.invoke('UpdateExcelDateTimeInfo', dateTime, excelType)
            .catch(err => console.error(err));
    }

    keepAlive(dateTime: string) {
        if (this.hubConnection) {
            this.hubConnection.invoke('KeepAlive', dateTime)
                .catch(err => console.error(err));
        }
    }

    initCommonSignalR() {
        this.startConnection();
        this.addUpdateWebPushListener();
        this.addReconnectListener();
        this.addBroadcastUserPermissionChanged();
    }

    initSignalRForSmartGate() {
        this.addBroadcastAlertListener();
        this.addBroadcastDocumentationPickupDelayAlertListener();
        this.addBroadcastUpdateShipmentInfoListener();
        this.addBroadcastExcelDateTimeInfoListener();
    }

    initSignalRForSmartIn() {
        this.addBroadcastUpdateInvitationInfoListener();
        this.addBroadcastUpdateParkingInvitationListener();
    }

    addToGroupSignalR() {
        return this.http.post(this.baseUrl + 'api/SignalR/AddToGroup', this.signalRConnectionId$.getValue()).subscribe();
    }

    removeFromGroupSignalr() {
        return this.http.post(this.baseUrl + 'api/signalR/removeFromGroup', this.signalRConnectionId$.getValue(), { observe: 'response' });
    }

    handleNewDataFromSignalR(dataSource, dataFromSignalR) {
        let newDataSource = [];
        Object.assign(newDataSource, dataSource);
        if (dataFromSignalR['invitationInfo'][0]?.status === "Cancelled") {
            newDataSource = this.handleRemoveInvitationFromSignalR(dataSource, dataFromSignalR['invitationInfo']);
        } else {
            newDataSource = this.handleInvitationFromSignalR(newDataSource, dataFromSignalR['invitationInfo']);
        }
        return newDataSource;
    }

    private handleInvitationFromSignalR(array, invitation) {
        const found = array.find((i) => i.id === invitation[0].id);
        if (!found) array.unshift(invitation[0]);
        else {
            let index = array.indexOf(found, 0);
            if (index > -1)
                array[index] = invitation[0];
        }
        return array;
    }

    private handleRemoveInvitationFromSignalR(array, invitation) {
        let filteredArray = array.filter(i => i.id !== invitation[0]?.id);
        return filteredArray;
    }
}