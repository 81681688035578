import { Component, ComponentRef, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, Renderer2 } from '@angular/core';
import { GalleryCustomDescriptionDirective } from "./gallery-custom-description.directive";
import { GalleryData } from './gallery-types';
import { fromEvent, Observable } from "rxjs";
import { cameIn, slideAnimation } from "../../animations/animations";
import { ReportDetailsService } from "../report-details/report-details.service";
import { HttpClient } from "@angular/common/http";
import { ConfirmationDialogComponent } from "../../confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    animations: [cameIn, slideAnimation]
})
export class GalleryComponent implements OnInit {
    @HostListener('document:keyup', ['$event']) gallery_onKeyDown($event: KeyboardEvent) {
        this._galleryKeyboardHandler($event);
    };

    @ViewChild('gallery') gallery!: ElementRef<HTMLDivElement>;
    @ViewChild(GalleryCustomDescriptionDirective, { static: true }) galleryCustomDescriptionHost!: GalleryCustomDescriptionDirective;
    @Input('data') data: GalleryData | null = null;
    @Input('enableEmbedView') enableEmbedView: boolean = false;
    @Output('isFullScreen') isFullScreen: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('dataAfterImageDeletion') dataAfterImageDeletion: EventEmitter<GalleryData> = new EventEmitter<GalleryData>();
    customDescriptionComponentRef: ComponentRef<any> | null;
    currentImageIndex: number = 0;
    fullscreenMode: boolean = false;
    galleryBackgroundImage: any;
    imageRotate: number = 0;
    imageScale: number = 1;
    panning: boolean = false;
    pointX: number = 0;
    pointY: number = 0;
    start = { x: 0, y: 0 };
    mouseup$: Observable<any>;
    mousedown$: Observable<any>;
    mousemove$: Observable<any>;
    mousewheel$: Observable<any>;
    isDeleteButtonVisible: boolean = false;

    constructor(private reportDetailsService: ReportDetailsService,
                private httpClient: HttpClient,
                public renderer: Renderer2,
                private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private translate: TranslateService) {
        this.reportDetailsService.currentImageIndex$.subscribe({
            next: (index: number) => {
                if (this.data) {
                    this.currentImageIndex = index;
                    this._updateCustomDescriptionComponent(index);
                }
            }
        });
    }

    ngOnInit(): void {
        if (this.data && this.data.customDescriptionComponent) {
            let viewContainerRef = this.galleryCustomDescriptionHost.viewContainerRef;
            viewContainerRef.clear();
            this.customDescriptionComponentRef = viewContainerRef.createComponent<any>(this.data.customDescriptionComponent);
            this.customDescriptionComponentRef.instance.data = this.data.images;
            this.customDescriptionComponentRef.instance.currentImageIndex = this.data.initImageIndex;
        }
        this.isDeleteButtonVisible = this.data.eventValue === "AddFormAnswers";

        this.isFullScreen.subscribe(value => {
            if (value) {
                setTimeout(() => {
                    this.galleryBackgroundImage = this.renderer.selectRootElement('#galleryBackgroundImage', true);
                    this.mousedown$ = fromEvent(this.galleryBackgroundImage, 'mousedown');
                    this.mousedown$.subscribe((e) => {
                        this.onmousedownZoom(e);
                    })
                    this.mousemove$ = fromEvent(this.galleryBackgroundImage, 'mousemove');
                    this.mousemove$.subscribe((e) => {
                        this.onmousemoveZoom(e);
                    })
                    this.mouseup$ = fromEvent(this.galleryBackgroundImage, 'mouseup');

                    this.mouseup$.subscribe((e) => {
                        this.onmouseupZoom(e);
                    });

                    this.mousewheel$ = fromEvent(this.galleryBackgroundImage, 'wheel');

                    this.mousewheel$.subscribe((e) => {
                        this.onmousewheelZoom(e);
                    });
                })
            }
        })
    }

    // events handlers
    closeBtn_onClick($event: MouseEvent): void {
        this.toggleFullscreen(false);
    }

    leftNavigationBtn_onClick($event: MouseEvent): void {
        this.pickPreviousImage();
    }

    rightNavigationBtn_onClick($event: MouseEvent): void {
        this.pickNextImage();
    }

    mainImage_onClick($event: MouseEvent) {
        this.toggleFullscreen(true);
    }

    thumbnail_onClick($event: MouseEvent, imgIndex: number) {
        this.pickImage(imgIndex);
    }

    // component logic
    toggleFullscreen(state?: boolean): void {
        if (typeof state !== 'undefined')
            this.fullscreenMode = state;
        else {
            this.fullscreenMode != this.fullscreenMode;
        }
        this.isFullScreen.emit(this.fullscreenMode)
    }

    pickPreviousImage(): void {
        this.pickImage(this.reportDetailsService.currentImageIndex$.value - 1);
        this.resetTransform();
    }

    pickNextImage(): void {
        this.pickImage(this.reportDetailsService.currentImageIndex$.value + 1);
        this.resetTransform();
    }

    pickImage(index: number): void {
        if (this.data?.images[index]) {
            this.reportDetailsService.currentImageIndex$.next(index);
            this.resetTransform();
        }
    }

    getImageToDownload(image: string) {
        this.httpClient.get(image, { responseType: 'blob' }).subscribe(val => {
            const url = URL.createObjectURL(val);
            this.downloadImage(url);
            URL.revokeObjectURL(url);
        });
    }

    downloadImage(url: string) {
        const link = document.createElement('a');
        link.setAttribute('href', url);
        let imageName: string
        if (this.data.shipmentId) {
            imageName = `shipment${this.data.shipmentId}_${this.data.eventValue}_image${this.reportDetailsService.currentImageIndex$.value + 1}.png`
        } else {
            imageName = `Invitation_image.png`
        }
        link.setAttribute('download', imageName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    rotate(degrees: number) {
        this.imageRotate = this.imageRotate + degrees;
    }

    onmousedownZoom(e: any) {
        e.preventDefault();
        this.start = { x: e.clientX - this.pointX, y: e.clientY - this.pointY };
        this.panning = true;
    }

    onmouseupZoom(e: any) {
        this.panning = false;
    }

    onmousemoveZoom(e: any) {
        e.preventDefault();
        if (!this.panning) {
            return;
        }
        this.pointX = (e.clientX - this.start.x);
        this.pointY = (e.clientY - this.start.y);
    }

    onmousewheelZoom(e: any) {
        e.preventDefault();
        let delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);
        (delta > 0) ? (this.imageScale *= 1.2) : (this.imageScale /= 1.2);
    }

    resetTransform() {
        this.imageRotate = 0;
        this.imageScale = 1;
        this.panning = false;
        this.pointX = 0;
        this.pointY = 0;
        this.start = { x: 0, y: 0 };
    }

    removeImage(imageUrl: string) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                headerMessage: this.translate.instant('gallery.component.galleryDelete.header'),
                message: this.translate.instant('gallery.component.galleryDelete.message'),
                buttonText: {
                    ok: this.translate.instant('shared.yes'),
                    cancel: this.translate.instant('shared.no')
                },
            },
            width: '400px',
            autoFocus: false
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.reportDetailsService.removeImage(this.data.shipmentIdFromDb, this.data.eventLogId, imageUrl).subscribe(
                    response => {
                        this.data.images.splice(this.currentImageIndex, 1);
                        this.reportDetailsService.currentImageIndex$.next(0);
                        this.dataAfterImageDeletion.emit(this.data);
                        this.snackBar.open(this.translate.instant('gallery.component.galleryDelete.success'), this.translate.instant('shared.ok'), {
                            duration: 4000,
                            panelClass: ['sicco-snackbar'],
                        });
                    },
                    err => {
                        this.snackBar.open(this.translate.instant('gallery.component.galleryDelete.failed'), this.translate.instant('shared.ok'), {
                            duration: 4000,
                            panelClass: ['sicco-snackbar'],
                        });
                    });
            }
        });
    }

    private _galleryKeyboardHandler($event: KeyboardEvent): void {
        switch ($event.code) {
            case 'KeyA':
            case 'ArrowLeft': {
                this.pickPreviousImage();
                break;
            }
            case 'KeyD':
            case 'ArrowRight': {
                this.pickNextImage();
                break;
            }
            case 'Escape': {
                this.toggleFullscreen(false);
                break;
            }
            default: {
                break;
            }
        }
    }

    private _updateCustomDescriptionComponent(currentImageIndex: number): void {
        if (this.customDescriptionComponentRef)
            this.customDescriptionComponentRef.instance.currentImageIndex = currentImageIndex;
    }
}