import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { AppRoutingModule } from "../../../app-routing-module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from "../../shared.module";
import { AppIconModule } from "../../../app-icon-module";
import { TranslateModule } from '@ngx-translate/core';
import { ErrorControlSharedModule } from "../error-control/error-control.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from '@angular/material/dialog';
import { CountriesSharedModule } from "../countries/countries.module";
import {MobileAppInfoComponent} from "../mobile-app-info/mobile-app-info.component";

@NgModule({
    imports: [
        BrowserAnimationsModule,
        MatMenuModule,
        MatInputModule,
        MatButtonModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatIconModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDividerModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        MatSlideToggleModule,
        AppIconModule,
        SharedModule,
        TranslateModule.forChild({}),
        ErrorControlSharedModule,
        CountriesSharedModule
    ],
    providers: [
        MobileAppInfoComponent
    ]
})

export class UserSettingsComponentModule {
}