import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from "../../shared.module";
import { AppIconModule } from "../../../app-icon-module";
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SecurityAlarmComponent } from './security-alarm.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        SharedModule,
        AppIconModule,
        MatTooltipModule,
        MatButtonModule,
        TranslateModule.forChild({}),
        MatDividerModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        SecurityAlarmComponent
    ],
    exports: [
        SecurityAlarmComponent
    ]
})

export class SecurityAlarmSharedModule {
}