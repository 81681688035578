import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-user-settings-device-info',
    templateUrl: './user-settings-device-info.component.html',
    styleUrls: ['./user-settings-device-info.component.scss'],
    animations: [
        trigger('bodyExpansion', [
            state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed, void => collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ]
})

export class UserSettingsDeviceInfoComponent {
    @Input() device: any;
    state: string = 'collapsed';

    constructor() {
    }

    showDeviceDetails() {
        this.state = this.state === 'collapsed' ? 'expanded' : 'collapsed'; 
    }
}