import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { OAuthService } from 'angular-oauth2-oidc';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { SignalrService } from '../shared/signalr-service/signalr.service';
import { tap } from 'rxjs/operators';
import { UserService } from '../shared/user-sidenav-service/user-sidenav.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { SidenavService } from '../shared/sidenav-service/sidenav.service';
import { backDropAnimation } from '../shared/animations/animations';
import { SidenavContentDirective } from "../shared/helpers/sidenav-content.directive";

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.scss'],
    animations: [backDropAnimation]
})

export class NavMenuComponent implements OnInit {
    @ViewChild('drawer', { static: true }) drawer: MatDrawer;
    @ViewChild('rightPanel', { static: true }) private rightPanel: MatSidenav;
    @ViewChild(SidenavContentDirective, { static: true }) sidenavContent!: SidenavContentDirective;
    hideHeader: boolean = false;
    currentTime: Date = new Date();
    portalType;
    userName: string;
    userLastName: string;
    userInitials: string;
    userEmail: string;
    userType: string;
    claims: any;
    alarmButton: boolean = false;
    infoButton: boolean = false;
    panelType: string = 'Settings';
    baseUrl: string;
    smsInfo;
    public isLeftMenuOpen: boolean = false;
    public isGalleryShown: boolean = false;

    constructor(public dateTimeAdapter: DateTimeAdapter<any>,
        private oauthService: OAuthService,
        private http: HttpClient,
        private sideNavService: SidenavService,
        private signalR: SignalrService,
        private userService: UserService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    ngOnInit() {
        this.sideNavService.rightPanel = this.rightPanel;
        this.sideNavService.sidenavContainer = this.sidenavContent;

        setInterval(() => {
            this.signalR.keepAlive(Date.now().toString());
        }, 5 * 60000);

        this.userService.getInstanceChange$.pipe(
            tap(() => {
                this.userType = localStorage.getItem("userType");
                if (this.userType !== '3')
                this.getMultiCompanyLicenseState();
            })).subscribe();

        this.userService.getInstanceSettings$.pipe(
            tap(() => {
                if ((this.userType === "1" || this.userType === "2"))
                    this.getMultiCompanyLicenseState();
            })).subscribe();

        this.signalR.userPermissionUpdate.subscribe(() => {
            this.snackBar.open(this.translate.instant('menu.permissionChanged'), this.translate.instant('shared.ok'), {
                duration: 10000,
                panelClass: ['sicco-snackbar'],
            });
        });

        this.sideNavService.isSideNavOpen$.subscribe({
            next: (response: boolean) => {
                this.isLeftMenuOpen = response;
            }
        });

        this.sideNavService.isGalleryShown$.subscribe({
            next: (response: boolean) => {
                this.isGalleryShown = response;
            }
        });
    }

    ngAfterContentChecked(): void {
        this.portalType = localStorage.getItem("systemType");

        this.userService.setDrawer(this.drawer);
        if (localStorage.getItem("firstName")) {
            this.userName = localStorage.getItem("firstName");
            this.userInitials = localStorage.getItem("firstName").charAt(0);
        }
        if (localStorage.getItem("lastName")) {
            this.userLastName = localStorage.getItem("lastName");
            this.userInitials += localStorage.getItem("lastName").charAt(0);
        }
        this.userEmail = localStorage.getItem("email");
        this.getClaims();
        this.userType = localStorage.getItem("userType");
        if (this.userType === "4") this.alarmButton = true;
    }

    getClaims() {
        this.claims = this.oauthService.getIdentityClaims();
        return this.claims;
    }

    toggleHeader(evt) {
        this.hideHeader = evt;
    }

    logout() {
        localStorage.removeItem('ignoreParking');
        localStorage.removeItem("instanceId");
        localStorage.removeItem("userId");
        localStorage.removeItem("status");
        localStorage.removeItem("manageUser");
        localStorage.removeItem("email");
        localStorage.removeItem("userType");
        localStorage.removeItem("defaultLanguage");
        localStorage.removeItem("ownerName");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("isMobileApplicationInstalled");
        var body = document.getElementById("isLoggedUser");
        if (body) {
            body.classList.remove("userIsLogged");
        }
        this.oauthService.logOut();
    }

    alarm() {
        this.drawer.toggle();
    }

    getSmsLimits() {
        this.http.get(this.baseUrl + "api/license/GetSmsLimits").subscribe(response => {
            if (response)
                this.smsInfo = response;
        });
    }

    getMultiCompanyLicenseState() {
        this.http.get(this.baseUrl + "api/license/GetMultiCompanyLicenseState").subscribe(response => {
            if (response && this.userType === "2") this.infoButton = true;
            else if (!response && this.userType === "1") this.infoButton = true;
            else this.infoButton = false;
        });
    }

    sineNavToggle() {
        this.sideNavService.isSideNavOpen$.next(!this.isLeftMenuOpen);
    }

    closeSideNav() {
        this.userService.closeSideNav();
    }
}