import { Component } from '@angular/core';

@Component({
    selector: 'app-data-processing',
    templateUrl: './data-processing.component.html',
})

export class DataProcessingComponent {

    ngOnInit() {

    }

}