import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'indefiniteDate'
})

export class IndefiniteDatePipe implements PipeTransform {
    transform(time: string): boolean {
        return time.startsWith('9999-12-31');
    }
}