import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from "@angular/common/http";
import { LongTermInvitationCreateDto } from "../../shared/interfaces/long-term-invitation-create-dto.interface";
import { LongTermInvitationDto } from "../../shared/interfaces/long-term-invitation-dto.interface";
import { UserDto } from "../../shared/interfaces/user-dto.interface";
import { LongTermInvitationUpdateDto } from "../../shared/interfaces/long-term-invitation-update-dto.interface";

const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

@Injectable()
export class LongTermInvitationService {
    private readonly _baseUrl: string = '';
    
    constructor(@Inject('BASE_URL') baseUrl: string,
                private _httpClient: HttpClient) {
        this._baseUrl = `${baseUrl}api`;
    }

    getAllLongTermInvitations(): Observable<HttpResponse<Array<LongTermInvitationDto>>> {
        return this._httpClient.get<Array<LongTermInvitationDto>>(`${this._baseUrl}/LongTermInvitation/GetAllLongTermInvitations`, { observe: 'response' });
    }
    
    getListParkingsByUserCompany(): Observable<HttpResponse<{[id: string]: string}>> {
        return this._httpClient.get<{[id: string]: string}>(`${this._baseUrl}/Parking/UserCompany`, { observe: 'response' });
    }

    getParkingActiveAndMaxInvitations(parkingId: string, companyId?: string): Observable<HttpResponse<{ maxNumberOfLongTermInvitations: number; numberOfActiveLongTermInvitations: number; }>> {
        let params: HttpParams = new HttpParams().set('parkingId', parkingId);
        if (companyId)
            params = params.set('companyId', companyId);
        
        return this._httpClient.get<{ maxNumberOfLongTermInvitations: number; numberOfActiveLongTermInvitations: number; }>(
            `${this._baseUrl}/LongTermInvitation/ActiveAndMaxCount`,
            { params: params, observe: 'response' }
        );
    }
    
    getUsers(): Observable<HttpResponse<Array<UserDto>>> {
        return this._httpClient.get<Array<UserDto>>(`${this._baseUrl}/User/BasicInfosPerUserCompany`, { observe: 'response' });
    }
    
    createLongTermInvitation(body: LongTermInvitationCreateDto): Observable<HttpResponse<LongTermInvitationDto>> {
        return this._httpClient.post<LongTermInvitationDto>(`${this._baseUrl}/LongTermInvitation/Create`, body, { observe: 'response' });
    }

    cancelLongTermInvitation(id: string): Observable<HttpResponse<null>> {
        const params: HttpParams = new HttpParams().set('invitationId', id);
        
        return this._httpClient.patch<null>(`${this._baseUrl}/LongTermInvitation/Cancel`, null, { params: params, observe: 'response' });
    }

    cancelTransitInvitation(value: any) {
        return this._httpClient.patch(`${this._baseUrl}/SecurityOfficerAccess/CancelTransitInvitation?invitationId=${value}`, { headers: headers }, { observe: 'response' });
    }

    updateLongTermInvitation(body: LongTermInvitationUpdateDto): Observable<HttpResponse<LongTermInvitationDto>> {
        return this._httpClient.patch<LongTermInvitationDto>(`${this._baseUrl}/LongTermInvitation/Update`, body, { observe: 'response' });
    }
}