import { Inject, Injectable } from "@angular/core";
import { jsPDF } from "jspdf";
import autoTable, { CellHookData } from "jspdf-autotable";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { IdNamePipe } from "./idname.pipe";
import { LocalizedDatePipe } from "./localizeddate.pipe";
import { IEventLogs, IReportLogs } from "../interfaces/shipmentreportlogs.interface";
import { IAnswer, IFormAnswers, IShipmentInformation } from "../interfaces/shipment.interface";
import { IIdNamePair } from "../interfaces/idname.interface";
import { font } from "../../../assets/pdf-fonts/Nunito-Regular-normal";
import { IndefiniteDatePipe } from "./indefiniteDate.pipe";

type JsPdfAutoTable = Array<Array<{ content: string | number, styles?: object, colspan?: number }>>;

interface IAvailableIdNamePairs {
    availableBuildings: IIdNamePair[],
    availableReceptions: IIdNamePair[],
    availableParkings: IIdNamePair[]
}

interface IPdfImages {
    row: number,
    image: string
}

interface IPdfLoadedImage {
    image: HTMLImageElement,
    pageNumber: number
}

interface IPdfFormAnswers {
    row: number,
    answers: IAnswer[],
    numberOfDeletedImages?: number
}

@Injectable()
export class PdfGenerationService {
    pdfLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false)
    private baseUrl: string;
    onlyOneAttempt: boolean = true;
    smartGateDefaultLogo = 'https://stlayoutsicco001.blob.core.windows.net/logos/smartGATE_3dts_mini.png';
    eventLogHeaders: JsPdfAutoTable = [];
    formAnswersHeaders: JsPdfAutoTable = [];
    smartInDefaultLogo = 'https://stlayoutsicco001.blob.core.windows.net/logos/smartIN_3dts_mini.png';

    constructor(
        private translate: TranslateService,
        private datePipe: LocalizedDatePipe,
        private idNamePipe: IdNamePipe,
        private indefiniteDatePipe: IndefiniteDatePipe,
        private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getCompanyLogo(): Promise<string | null> {
        return new Promise(resolve => {
            this.httpClient.get(this.baseUrl + 'api/Company/GetCompanyLogoUrl',
                {responseType: "text"}).subscribe((response: string) => {
                resolve(response)
            })
        })
    }

    loadImageForPdf(url: string, pageNumber: number): Promise<IPdfLoadedImage> {
        return new Promise<IPdfLoadedImage>(resolve => {
            let img: HTMLImageElement = new Image();
            img.onload = () => {
                let resolveData: IPdfLoadedImage = {image: img, pageNumber: pageNumber}
                resolve(resolveData);
            }
            img.onerror = () => {
                resolve(null)
            }
            img.src = url;
        })
    }

    exportSmartInPdf(eventLogs: IEventLogs[], data, AvailableIdNamePairs: IAvailableIdNamePairs) {
        this.pdfLoading$.next(true);
        let arrayOfPromises: Array<Promise<void>> = [];
        this.eventLogHeaders = [[
            {content: this.translate.instant('smart-gate.view.eventType')},
            {content: this.translate.instant('smart-gate.view.eventTime')},
            {content: this.translate.instant('smart-gate.view.eventInitiator')},
            {content: this.translate.instant('shared.details')},
        ]];
        const doc: jsPDF = new jsPDF();
        doc.addFileToVFS('Nunito-Regular.ttf', font);
        doc.addFont("Nunito-Regular.ttf", "NunitoRegular", "normal");
        doc.setFont('NunitoRegular');
        doc.setFontSize(30);
        doc.setTextColor(0, 70, 149);
        doc.text(this.translate.instant('report-details-smartin.view.report'), 15, 20);
        doc.setFontSize(19);
        let headerY: number = 40
        if (data.invitedPersonFirstName) {
            let firstNameWidth: number = doc.getTextDimensions(`${ this.translate.instant('home.invitation-dialog.view.firstName') }: `).w
            doc.text(`${ this.translate.instant('home.invitation-dialog.view.firstName') }: `, 16, headerY);
            doc.setTextColor('#000000')
            doc.text(data.invitedPersonFirstName, 16 + firstNameWidth, headerY)
            doc.setTextColor(0, 70, 149);
            headerY += 10;
        }
        if (data.invitedPersonLastName) {
            let lastNameWidth: number = doc.getTextDimensions(`${ this.translate.instant('home.invitation-dialog.view.surname') }: `).w
            doc.text(`${ this.translate.instant('home.invitation-dialog.view.surname') }: `, 16, headerY);
            doc.setTextColor('#000000')
            doc.text(data.invitedPersonLastName, 16 + lastNameWidth, headerY)
            doc.setTextColor(0, 70, 149);
            headerY += 10;
        }
        if (data.plateNumber) {
            let plateNumberWidth = doc.getTextDimensions(`${ this.translate.instant('home.invitation-dialog.view.registrationPlate') }: `).w
            doc.text(`${ this.translate.instant('home.invitation-dialog.view.registrationPlate') }: `, 16, headerY);
            doc.setTextColor('#000000')
            doc.text(data.plateNumber, 16 + plateNumberWidth, headerY);
            headerY += 10;
        }
        doc.setTextColor(0, 70, 149);
        let eventLogsBody: JsPdfAutoTable = [];
        let pdfImages: IPdfImages[] = [];
        eventLogs.forEach((eventLog: IEventLogs) => {
            if (eventLog.eventValue) {
                let details: string = '';
                let hasImages: boolean = false;
                if (eventLog.newValue && eventLog.newValue !== eventLog.oldValue) {
                    if (eventLog.typeName === 'EventType') {
                        if (eventLog.eventValue === 'ChangeStartDate' || eventLog.eventValue === 'InvitationResend') {
                            details = `${ this.datePipe.transform(eventLog.oldValue) } ${ this.translate.instant('report-details-smartin.view.to') } ${ this.datePipe.transform(eventLog.newValue) }`
                        }
                        else if (eventLog.eventValue === 'ChangeEndDate') {
                            if (this.indefiniteDatePipe.transform(eventLog.oldValue)) {
                                details += `${ this.translate.instant('longterminvitation.view.indefinitely') }`;
                            }
                            else {
                                details += `${ this.datePipe.transform(eventLog.oldValue) }`;
                            }
                            details += ` ${ this.translate.instant('report-details-smartin.view.to') } `;
                            if (this.indefiniteDatePipe.transform(eventLog.newValue)) {
                                details += `${ this.translate.instant('longterminvitation.view.indefinitely') }`;
                            }
                            else {
                                details += `${ this.datePipe.transform(eventLog.newValue) }`;
                            }
                        }
                        else if (eventLog.eventValue === 'ChangeBuilding' || eventLog.eventValue === 'ChangeReception' || eventLog.eventValue === 'ChangeParking') {
                            let neededIdNamePair: IIdNamePair[] = [];
                            switch (eventLog.eventValue) {
                                case 'ChangeBuilding':
                                    neededIdNamePair = AvailableIdNamePairs.availableBuildings;
                                    break;
                                case 'ChangeReception':
                                    neededIdNamePair = AvailableIdNamePairs.availableReceptions;
                                    break;
                                case 'ChangeParking':
                                    neededIdNamePair = AvailableIdNamePairs.availableParkings
                            }
                            details = `${ this.idNamePipe.transform(eventLog.oldValue, neededIdNamePair) } ${ this.translate.instant('report-details-smartin.view.to') } ${ this.idNamePipe.transform(eventLog.newValue, neededIdNamePair) }`
                        }
                        else {
                            details = `${ eventLog.oldValue ? eventLog.oldValue : "" } ${ this.translate.instant('report-details-smartin.view.to') } ${ eventLog.newValue }`
                        }
                    }
                    else if (eventLog.typeName === 'ParkingReservationStatus') {

                        details = `${ eventLog.oldValue ? eventLog.oldValue : "" } ${ this.translate.instant('report-details-smartin.view.to') } ${ eventLog.newValue }`
                    }
                    else if (eventLog.typeName === 'InvitationLifeCycleStatus') {
                        if (eventLog.eventValue === 'Rejected') {
                            details = `${ this.translate.instant('report-details-smartin.view.rejectReason')}: ${data.comment}`
                        }
                    }
                }
                if (eventLog.eventImagesUrl.length !== 0) {
                    hasImages = true;
                }
                eventLogsBody.push([
                        {
                            content: this.translate.instant('enums.invitationStatus.' + eventLog.eventValue),
                            styles: {fillColor: hasImages ? ['#f2f2f2'] : ['#FCFCFC']}
                        },
                        {
                            content: this.datePipe.transform(eventLog.occurrenceDateTimeUtc),
                            styles: {fillColor: hasImages ? ['#f2f2f2'] : ['#FCFCFC']}
                        },
                        {
                            content: eventLog.initiatingUserFirstLastName ? eventLog.initiatingUserFirstLastName : "",
                            styles: {fillColor: hasImages ? ['#f2f2f2'] : ['#FCFCFC']}
                        },
                        {content: details, styles: {fillColor: hasImages ? ['#f2f2f2'] : ['#FCFCFC']}},
                    ]
                );
                if (hasImages) {
                    eventLog.eventImagesUrl.forEach(imageUrl => {
                        eventLogsBody.push([
                            {content: 'image', styles: {minCellHeight: 85}}
                        ])
                        pdfImages.push(
                            {row: eventLogsBody.length - 1, image: imageUrl}
                        )
                    })
                }
            }
        })
        autoTable(doc, {
            theme: "grid",
            head: this.eventLogHeaders,
            body: eventLogsBody,
            rowPageBreak: "avoid",
            startY: headerY,
            styles: {
                font: "NunitoRegular",
            },
            columnStyles: {
                0: {cellWidth: 35},
                1: {cellWidth: 35},
                2: {cellWidth: 35},
                3: {cellWidth: "auto"},
            },
            headStyles: {
                halign: "center",
                fillColor: [0, 70, 149]
            },
            showHead: "firstPage",
            didParseCell: (data: CellHookData) => {
                if (data.cell.text[0] === "image" || data.cell.text[0] === "formAnswers") {
                    data.cell.colSpan = 4;
                    data.cell.styles.fontSize = 0.01;
                }
            },
            didDrawCell: (data: CellHookData) => {
                if (data.cell.text[0] === "image" && data.column.index === 0) {
                    let rowSpecificPdfImage: IPdfImages = pdfImages.find(x => x.row === data.row.index)
                    let imagePromise: Promise<void> = this.loadImageForPdf(rowSpecificPdfImage.image, doc.getCurrentPageInfo().pageNumber)
                        .then((resolveData: IPdfLoadedImage) => {
                            if (resolveData) {
                                let properWidth: number = (resolveData.image.width / resolveData.image.height) * data.cell.height - 4
                                let calculatedX: number = data.cell.x + (data.cell.width / 2 - properWidth / 2);
                                doc.setPage(resolveData.pageNumber)
                                doc.addImage(resolveData.image.src, calculatedX, data.cell.y + 2, properWidth, data.cell.height - 4)
                            }
                        });
                    arrayOfPromises.push(imagePromise);
                }
            },
        });
        let logoPromise: Promise<void> = this.getCompanyLogo().then(response => {
            let instanceLogo: string | null = response;
            if (!response) {
                instanceLogo = localStorage.getItem('instanceLogoUrl');
                if (!instanceLogo) {
                    instanceLogo = this.smartInDefaultLogo;
                }
            }
            return this.loadImageForPdf(instanceLogo, 1).then((resolveData: IPdfLoadedImage) => {
                if (resolveData) {
                    doc.setPage(resolveData.pageNumber)
                    let properWidth: number = (resolveData.image.width / resolveData.image.height) * 12
                    try {
                        doc.addImage(resolveData.image, 150, 10, properWidth, 12)
                    } catch (e) {
                        if (localStorage.getItem('instanceLogoUrl') && instanceLogo !== localStorage.getItem('instanceLogoUrl')) {
                            instanceLogo = localStorage.getItem('instanceLogoUrl')
                        }
                        else {
                            instanceLogo = this.smartInDefaultLogo;
                        }
                        return this.loadImageForPdf(instanceLogo, 1).then((resolveData: IPdfLoadedImage) => {
                            if (resolveData) {
                                doc.setPage(resolveData.pageNumber)
                                let properWidth: number = (resolveData.image.width / resolveData.image.height) * 12
                                try {
                                    doc.addImage(resolveData.image, 150, 10, properWidth, 12)
                                } catch (e) {
                                    console.error('An error occurred while trying to get logo')
                                }
                            }
                        })
                    }
                }
            })
        })
        arrayOfPromises.push(logoPromise)
        Promise.all(arrayOfPromises).then(() => {
            for (let i = 1; i <= doc.getNumberOfPages(); i++) {
                doc.setPage(i);
                doc.setFillColor(0, 70, 149);
                doc.rect(0, 0, 10, doc.internal.pageSize.getHeight(), 'FD');
            }
            let date = new Date(Date.now());
            let properDateFormat = `${ date.getDate() }-${ date.getMonth() + 1 }-${ date.getFullYear() }--${ date.getHours() }-${ date.getMinutes() }`;
            let fileName = "";
            if (data.type === 0) {
                if (data.invitedPersonFirstName) {
                    fileName += `${ data.invitedPersonFirstName }`;
                }
                if (data.invitedPersonLastName) {
                    if (data.invitedPersonFirstName) {
                        fileName += '_';
                    }
                    fileName += `${ data.invitedPersonLastName }`;
                }
            }
            else if (data.type === 5) {
                fileName += `Longterm_${ data.plateNumber }`;
            }
            fileName += `--${ properDateFormat }`
            doc.save(fileName, {returnPromise: true}).then(() => {
                this.pdfLoading$.next(false);
            });
        })
    }

    exportSmartGatePdf(reportLogs: IReportLogs[], data: IShipmentInformation, allFormAnswers: IFormAnswers[]) {
        this.pdfLoading$.next(true);
        let arrayOfPromises: Array<Promise<void>> = [];
        this.eventLogHeaders = [[
            {content: this.translate.instant('smart-gate.view.eventType')},
            {content: this.translate.instant('smart-gate.view.eventTime')},
            {content: this.translate.instant('smart-gate.view.eventInitiator')},
            {content: this.translate.instant('shared.details')},
        ]];
        this.formAnswersHeaders = [[
            {content: this.translate.instant('smart-gate.pdf-export.questions')},
            {content: this.translate.instant('smart-gate.pdf-export.answers')}
        ]];
        const doc: jsPDF = new jsPDF();
        doc.addFileToVFS('Nunito-Regular.ttf', font);
        doc.addFont("Nunito-Regular.ttf", "NunitoRegular", "normal");
        doc.setFont('NunitoRegular');
        doc.setFontSize(30);
        doc.setTextColor(0, 70, 149);
        if (data.shipmentType === 1) {
            doc.text(this.translate.instant('smart-gate.view.loadReport'), 15, 20);
        }
        else if (data.shipmentType === 6) {
            doc.text(this.translate.instant('smart-gate.view.unloadReport'), 15, 20);
        }
        doc.setFontSize(19);
        let shipmentIdWidth: number = doc.getTextDimensions(`${ this.translate.instant('smart-gate.view.shipmentId') }: `).w
        doc.text(`${ this.translate.instant('smart-gate.view.shipmentId') }: `, 16, 40);
        doc.setTextColor('#000000')
        shipmentIdWidth += 16;
        doc.text(data.shipmentId, shipmentIdWidth, 40)
        shipmentIdWidth += doc.getTextDimensions(`${ data.shipmentId }`).w;
        doc.setTextColor(0, 70, 149);
        let headerY: number = 50;
        if (data.shipmentType === 6 && data.shipmentSupplier) {
            doc.addImage('assets/img/boxes-packing-solid.png', 'png', shipmentIdWidth + 2, 34, 6, 6);
            let supplierWidth: number = doc.getTextDimensions(`${ this.translate.instant('smart-gate.view.supplier') }: `).w;
            doc.text(`${ this.translate.instant('smart-gate.view.supplier') }: `, 16, headerY);
            doc.setTextColor('#000000')
            doc.text(data.shipmentSupplier, 16 + supplierWidth, headerY);
            doc.setTextColor(0, 70, 149);
            headerY += 10;
        }
        else if (data.shipmentDirection) {
            let destinationWidth: number = doc.getTextDimensions(`${ this.translate.instant('smart-gate.view.destination') }: `).w;
            doc.text(`${ this.translate.instant('smart-gate.view.destination') }: `, 16, headerY);
            doc.setTextColor('#000000')
            doc.text(data.shipmentDirection, 16 + destinationWidth, headerY);
            doc.setTextColor(0, 70, 149);
            headerY += 10;
        }
        let plateNumbersWidth: number = doc.getTextDimensions(`${ this.translate.instant('smart-gate.view.plateNumbers') }: `).w;
        if (data.plateNumber) {
            let plateNumberWidth = doc.getTextDimensions(data.plateNumber).w;
            doc.text(`${ this.translate.instant('smart-gate.view.plateNumbers') }: `, 16, headerY);
            doc.addImage('assets/img/truck-moving-solid.png', 'png', 16 + plateNumbersWidth, headerY - 6, 8, 8);
            doc.setTextColor('#000000')
            doc.text(data.plateNumber, 26 + plateNumbersWidth, headerY);
            doc.addImage('assets/img/trailer-solid.png', 'png', 28 + plateNumberWidth + plateNumbersWidth, headerY - 6, 8, 8);
            doc.text(data.trailerPlateNumber, 38 + plateNumbersWidth + plateNumberWidth, headerY);
        }
        doc.setTextColor(0, 70, 149);
        if (reportLogs.length > 1) {
            this.onlyOneAttempt = false;
        }
        reportLogs.forEach((reportLogs: IReportLogs, attempt: number) => {
            let eventLogsBody: JsPdfAutoTable = [];
            let pdfImages: IPdfImages[] = [];
            let pdfFormAnswers: IPdfFormAnswers[] = [];
            reportLogs.eventLogs.forEach((eventLog: IEventLogs) => {
                let details: string = "";
                let hasImages: boolean = false;
                switch (eventLog.eventValue) {
                    case "AddNote": {
                        details = eventLog.newValue;
                        break;
                    }
                    case "Cancelled": {
                        details = eventLog.cancelReason;
                        break;
                    }
                    case "ChangeParkingSpot": {
                        details = `${ eventLog.oldValue ? eventLog.oldValue : "" } ${ this.translate.instant('smart-gate.view.toDock') } ${ eventLog.newValue }`;
                        break;
                    }
                    case "Completed": {
                        if (data.shipmentSeals) {
                            details = this.translate.instant('smart-gate.view.shipmentSeal') + ': ';
                            data.shipmentSeals.forEach((seal, index) => {
                                details += index === 0 ? seal : ',' + seal;
                            })
                        }
                        else {
                            details = '';
                        }
                        break;
                    }
                    default: {
                        details = "";
                        break
                    }
                }
                if (eventLog.eventImagesUrl.length !== 0) {
                    hasImages = true;
                }
                eventLogsBody.push([
                        {
                            content: this.translate.instant('enums.shipmentEventLogs.' + eventLog.eventValue),
                            styles: {fillColor: hasImages ? ['#f2f2f2'] : ['#FCFCFC']}
                        },
                        {
                            content: this.datePipe.transform(eventLog.occurrenceDateTimeUtc),
                            styles: {fillColor: hasImages ? ['#f2f2f2'] : ['#FCFCFC'], halign: "center"}
                        },
                        {
                            content: eventLog.initiatingUserFirstLastName ? eventLog.initiatingUserFirstLastName : "",
                            styles: {fillColor: hasImages ? ['#f2f2f2'] : ['#FCFCFC']}
                        },
                        {content: details, styles: {fillColor: hasImages ? ['#f2f2f2'] : ['#FCFCFC']}},
                    ]
                );
                if (eventLog.eventValue === "AddFormAnswers") {
                    let filteredFormAnswers = allFormAnswers.filter(fa => {
                        return fa.shipmentIdFromDb === eventLog.shipmentIdFromDb
                    });
                    if (filteredFormAnswers.length !== 0) {
                        filteredFormAnswers.forEach((formAnswers: IFormAnswers) => {
                            if (formAnswers.id === eventLog.newValue) {
                                eventLogsBody.push([
                                    {
                                        content: 'formAnswers',
                                        styles: {minCellHeight: 12 * formAnswers.answers.length + 15}
                                    }
                                ]);

                                if (!formAnswers.answers.find(answer => {
                                    return answer.questionType === 8
                                })) {
                                    pdfFormAnswers.push({
                                        row: eventLogsBody.length - 1, answers: formAnswers.answers
                                    });
                                }
                                else {
                                    let numberOfImages = +formAnswers.answers.find(answer => {
                                        return answer.questionType === 8
                                    }).answer;
                                    if (numberOfImages !== eventLog.eventImagesUrl.length) {
                                        pdfFormAnswers.push({
                                            row: eventLogsBody.length - 1,
                                            answers: formAnswers.answers,
                                            numberOfDeletedImages: numberOfImages - eventLog.eventImagesUrl.length
                                        });
                                    }
                                    else {
                                        pdfFormAnswers.push({
                                            row: eventLogsBody.length - 1, answers: formAnswers.answers
                                        });
                                    }
                                }
                            }
                        })
                    }
                }
                if (hasImages) {
                    eventLog.eventImagesUrl.forEach(imageUrl => {
                        eventLogsBody.push([
                            {content: 'image', styles: {minCellHeight: 85}}
                        ])
                        pdfImages.push(
                            {row: eventLogsBody.length - 1, image: imageUrl}
                        )
                    })
                }
            })
            doc.setFontSize(25);
            doc.setFont('NunitoRegular');
            if (!this.onlyOneAttempt) {
                if (attempt === 0) {
                    doc.text(`${ this.translate.instant('smart-gate.view.eventLabel') }: ${ attempt + 1 }`, 15, 80);
                }
                if (attempt > 0) {
                    doc.addPage()
                    doc.text(`${ this.translate.instant('smart-gate.view.eventLabel') }: ${ attempt + 1 }`, 15, 15);
                }
            }
            autoTable(doc, {
                theme: "grid",
                head: this.eventLogHeaders,
                body: eventLogsBody,
                rowPageBreak: "avoid",
                startY: this.onlyOneAttempt ? 70 : attempt === 0 ? 90 : 25,
                styles: {
                    font: "NunitoRegular",
                },
                columnStyles: {
                    0: {cellWidth: 35},
                    1: {cellWidth: 35},
                    2: {cellWidth: 35},
                    3: {cellWidth: "auto"},
                },
                headStyles: {
                    halign: "center",
                    fillColor: [0, 70, 149]
                },
                showHead: "firstPage",
                didParseCell: (data: CellHookData) => {
                    if (data.cell.text[0] === 'image' || data.cell.text[0] === "formAnswers") {
                        data.cell.colSpan = 4;
                        data.cell.styles.fontSize = 0.01;
                    }
                },
                didDrawCell: (data: CellHookData) => {
                    if (data.cell.text[0] === 'image' && data.column.index === 0) {
                        let rowSpecificPdfImage: IPdfImages = pdfImages.find(x => x.row === data.row.index)
                        let imagePromise: Promise<void> = this.loadImageForPdf(rowSpecificPdfImage.image, doc.getCurrentPageInfo().pageNumber)
                            .then((resolveData: IPdfLoadedImage) => {
                                if (resolveData) {
                                    let properWidth: number = (resolveData.image.width / resolveData.image.height) * data.cell.height - 4
                                    let calculatedX: number = data.cell.x + (data.cell.width / 2 - properWidth / 2);
                                    doc.setPage(resolveData.pageNumber)
                                    doc.addImage(resolveData.image.src, calculatedX, data.cell.y + 2, properWidth, data.cell.height - 4)
                                }
                            });
                        arrayOfPromises.push(imagePromise);
                    }
                    if (data.cell.text[0] === "formAnswers" && data.column.index === 0) {
                        let rowsSpecificFormAnswers: IPdfFormAnswers = pdfFormAnswers.find(x => x.row === data.row.index);
                        let formAnswersBody: JsPdfAutoTable = [];
                        rowsSpecificFormAnswers.answers.forEach((filteredFormAnswer: IAnswer) => {
                            if (filteredFormAnswer.questionType === 7) {
                                if (filteredFormAnswer.answer !== '') {
                                    formAnswersBody.push([
                                        {content: filteredFormAnswer.question},
                                        {content: `${ this.translate.instant('form-answers.breakDate') }:\n ${ this.datePipe.transform(filteredFormAnswer.answer) }`},
                                    ])
                                }
                                else {
                                    formAnswersBody.push([
                                        {content: filteredFormAnswer.question},
                                        {content: 'NoBreak'},
                                    ])
                                }
                            }
                            else if (filteredFormAnswer.questionType === 5) {
                                if (filteredFormAnswer.isAnswerCorrect) {
                                    formAnswersBody.push([
                                        {content: filteredFormAnswer.question},
                                        {content: filteredFormAnswer.answer.replace(';', ' ')},
                                    ])
                                }
                            }
                            else if (filteredFormAnswer.questionType === 3 || filteredFormAnswer.questionType === 4) {
                                formAnswersBody.push([
                                    {content: filteredFormAnswer.question},
                                    {content: filteredFormAnswer.answer.toUpperCase()},
                                ])
                            }
                            else if (filteredFormAnswer.questionType === 9) {
                                formAnswersBody.push([
                                    {content: filteredFormAnswer.question},
                                    {content: filteredFormAnswer.answer.split(";")[1]},
                                ])
                            }
                            else if (filteredFormAnswer.questionType === 8 && rowsSpecificFormAnswers.numberOfDeletedImages) {
                                formAnswersBody.push([
                                    {content: filteredFormAnswer.question},
                                    {content: `${ this.translate.instant('form-answers.images') }: ${ filteredFormAnswer.answer } \n ${ 
                                        this.translate.instant('form-answers.deletedImages') }: ${ rowsSpecificFormAnswers.numberOfDeletedImages }`}
                                ])
                            }
                            else if (filteredFormAnswer.questionType === 12 && !filteredFormAnswer.answer) {
                                formAnswersBody.push([
                                    { content: filteredFormAnswer.question },
                                    { content: '-' }
                                ])
                            }
                            else {
                                formAnswersBody.push([
                                    {content: filteredFormAnswer.question},
                                    {content: filteredFormAnswer.answer}
                                ])
                            }
                        })
                        autoTable(doc, {
                            startY: data.cell.y,
                            head: this.formAnswersHeaders,
                            body: formAnswersBody,
                            foot: [[{content: '', colSpan: 2}]],
                            showHead: "firstPage",
                            showFoot: "lastPage",
                            headStyles: {
                                halign: "center",
                                minCellHeight: 7.5
                            },
                            bodyStyles: {
                                valign: "middle",
                                minCellHeight: 12
                            },
                            footStyles: {
                                fillColor: [0, 70, 149],
                                minCellHeight: 7.5
                            },
                            columnStyles: {
                                1: {halign: "center"}
                            },
                            styles: {
                                cellWidth: "auto",
                                font: "NunitoRegular",
                            },
                            didParseCell: (formAnswersCell: CellHookData) => {
                                if (formAnswersCell.section === "body" && (formAnswersCell.cell.text[0] === "True" || formAnswersCell.cell.text[0] === "False" || formAnswersCell.cell.text[0] === "NoBreak")) {
                                    formAnswersCell.cell.styles.fontSize = 0.01;
                                }
                            },
                            didDrawCell: (formAnswersCell: CellHookData) => {
                                let calculatedX: number = formAnswersCell.cell.x + (formAnswersCell.cell.width / 2 - 5 / 2);
                                let calculatedY: number = formAnswersCell.cell.y + (formAnswersCell.cell.height / 2 - 5 / 2);
                                if (formAnswersCell.section === "body" && formAnswersCell.cell.text[0] === "True") {
                                    doc.addImage('assets/img/check-solid.png', 'png', calculatedX, calculatedY, 5, 5);
                                }
                                if (formAnswersCell.section === "body" && (formAnswersCell.cell.text[0] === "False" || formAnswersCell.cell.text[0] === "NoBreak")) {
                                    doc.addImage('assets/img/times-solid.png', 'png', calculatedX, calculatedY, 5, 5);
                                }
                            }
                        })
                    }
                },
            })
        });
        let logoPromise: Promise<void> = this.getCompanyLogo().then((response) => {
            let instanceLogo: string | null = response;
            if (!response) {
                instanceLogo = localStorage.getItem('instanceLogoUrl');
                if (!instanceLogo) {
                    instanceLogo = this.smartGateDefaultLogo;
                }
            }
            return this.loadImageForPdf(instanceLogo, 1).then((resolveData: IPdfLoadedImage) => {
                if (resolveData) {
                    doc.setPage(resolveData.pageNumber)
                    let properWidth: number = (resolveData.image.width / resolveData.image.height) * 12
                    try {
                        doc.addImage(resolveData.image, 150, 10, properWidth, 12)
                    } catch (e) {
                        if (localStorage.getItem('instanceLogoUrl') && instanceLogo !== localStorage.getItem('instanceLogoUrl')) {
                            instanceLogo = localStorage.getItem('instanceLogoUrl')
                        }
                        else {
                            instanceLogo = this.smartGateDefaultLogo;
                        }
                        return this.loadImageForPdf(instanceLogo, 1).then((resolveData: IPdfLoadedImage) => {
                            if (resolveData) {
                                doc.setPage(resolveData.pageNumber)
                                let properWidth: number = (resolveData.image.width / resolveData.image.height) * 12
                                try {
                                    doc.addImage(resolveData.image, 150, 10, properWidth, 12)
                                } catch (e) {
                                    console.error('An error occurred while trying to get logo')
                                }
                            }
                        })
                    }
                }
            })
        })
        arrayOfPromises.push(logoPromise);
        Promise.all(arrayOfPromises).then(() => {
            for (let i = 1; i <= doc.getNumberOfPages(); i++) {
                doc.setPage(i);
                doc.setFillColor(0, 70, 149);
                doc.rect(0, 0, 10, doc.internal.pageSize.getHeight(), 'FD');
            }
            let date = new Date(Date.now());
            doc.setPage(doc.getNumberOfPages());
            doc.setFontSize(12);
            let generatedWidth: number = doc.getTextDimensions(`${ this.translate.instant('smart-gate.pdf-export.generated') }: `).w
            doc.text(`${ this.translate.instant('smart-gate.pdf-export.generated') }: `, 130, 290);
            doc.setTextColor('#000000')
            generatedWidth += 130;
            doc.text(this.datePipe.transform(date.toString()), generatedWidth, 290);
            let properDateFormat = `${ date.getDate() }-${ date.getMonth() + 1 }-${ date.getFullYear() }--${ date.getHours() }-${ date.getMinutes() }`
            let name: string = data.shipmentType !== 6 ? 'Shipment' : 'Generated';
            let fileName = `${ name }_${ data.shipmentId }--${ properDateFormat }`;
            doc.save(fileName, {returnPromise: true}).then(() => {
                this.pdfLoading$.next(false);
            });
        })
    }
}