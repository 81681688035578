import { Pipe, PipeTransform } from "@angular/core";
import { ITranslations } from "../interfaces/predefinedValues.interface";

@Pipe({
    name: 'translationText'
})

export class TranslationTextPipe implements PipeTransform {
    transform(translations: ITranslations[], currentLanguage: string): string {
        return translations.find(x => x.language === currentLanguage).text
    }
}