// @ts-ignore
import { AuthConfig } from "angular-oauth2-oidc";

export const authConfigBase: AuthConfig = <AuthConfig>(({
    redirectUri: window.location.origin + '/login',
    responseType: 'token id_token',
    issuer: 'https://siccocloud.b2clogin.com/f9161f41-d7be-46b9-93d2-cf6ceb6ba77f/v2.0/',
    strictDiscoveryDocumentValidation: false,
    tokenEndpoint: 'https://siccocloud.b2clogin.com/siccocloud.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1_SignIn',
    loginUrl: 'https://siccocloud.b2clogin.com/siccocloud.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1_SignIn',
    clientId: 'b61c57e8-09eb-419e-a489-e8a81ebcce1a',
    scope: 'https://siccocloud.onmicrosoft.com/Sicco-WebGui-Api/email https://siccocloud.onmicrosoft.com/Sicco-WebGui-Api/openid https://siccocloud.onmicrosoft.com/Sicco-WebGui-Api/profile https://siccocloud.onmicrosoft.com/Sicco-WebGui-Api/user_impersonation',
    skipIssuerCheck: true,
    clearHashAfterLogin: true,
    oidc: true,
    useSilentRefresh: true,
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    logoutUrl: 'https://siccocloud.b2clogin.com/siccocloud.onmicrosoft.com/B2C_1_SignIn/oauth2/v2.0/logout',
}) as any);

export const environmentBase = {
    production: true,
    type: "SmartGate",
    homePageRoute: "smart-gate",
    // @ts-ignore
    portalVersion: require('../../package.json').version,
    firebaseConfig: {
        apiKey: "AIzaSyBbbdewJkB2iF_DzSUZ1uPVbUhEuxun5E4",
        authDomain: "sicco-test.firebaseapp.com",
        projectId: "sicco-test",
        storageBucket: "sicco-test.appspot.com",
        messagingSenderId: "843523997770",
        appId: "1:843523997770:web:120097c296af7c6cc5e6b3",
        measurementId: "G-BGHF281H8G"
    },
    discoveryDocumentConfig: {
        url: "https://siccocloud.b2clogin.com/siccocloud.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1_SignIn",
    },
    passwordResetConfig: {
        passwordResetUrl: 'https://siccocloud.b2clogin.com/siccocloud.onmicrosoft.com/oauth2/v2.0/authorize?' +
            'p=B2C_1_PasswordRestart' +
            '&client_id=' + authConfigBase.clientId +
            '&nonce=defaultNonce' +
            '&redirect_uri=' + window.location.origin + '/login' +
            '&scope=openid' +
            '&response_type=id_token' +
            '&prompt=login'
    }
};