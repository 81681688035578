import { ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';
import { SidenavContentDirective } from "../helpers/sidenav-content.directive";
import { ISidenavComponent } from "../interfaces/sidenav-component.interface";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SideNavOpenResult } from "../interfaces/side-nav-open-result.interface";
import { take } from "rxjs/operators";

@Injectable()
export class SidenavService {
	public isSideNavOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isGalleryShown$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	rightPanel: MatSidenav;
	sidenavContainer: SidenavContentDirective;
	public isMobile$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(private breakpointObserver: BreakpointObserver) {
		this.breakpointObserver.observe([
			"(max-width: 900px)"
		]).subscribe((result: BreakpointState) => {
			if (result.matches) {
				this.isMobile$.next(true);
			}
			else {
				this.isMobile$.next(false);
			}
		});
	}

	openRightPanel<T extends ISidenavComponent>(component: Type<T>, data: any): SideNavOpenResult<T> {
		const viewContainerRef: ViewContainerRef = this.sidenavContainer.viewContainerRef;
		viewContainerRef.clear();
		
		const componentRef: ComponentRef<T> = viewContainerRef.createComponent<T>(component);
		componentRef.instance.data = data;
		
		const result: SideNavOpenResult<T> = {
			open: this.rightPanel.open(),
			componentRef: componentRef
		};

		this.rightPanel.closedStart.pipe(take(1)).subscribe({
			next: (): void => { this.sidenavContainer.viewContainerRef.clear(); }
		});
		
		return result;
	}

	toggleRightPanel() {
		return this.rightPanel.toggle();
	}

	closeRightPanel() {
		this.sidenavContainer.viewContainerRef.clear();
		return this.rightPanel.close();
	}

	ngOnDestroy() {
		this.isMobile$.complete();
	}
}
