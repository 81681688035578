import { Component, Inject } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { CookieLangChanger } from "../cookie/cookie.language.changer";

@Component({
    selector: 'language-dialog',
    templateUrl: 'language-dialog.component.html',
})

export class LanguageDialogComponent {
    public selected: string = localStorage.getItem("defaultLanguage");
    private baseUrl: string;

    constructor(private httpClient: HttpClient,
        private dialogRef: MatDialogRef<LanguageDialogComponent>,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        private cookieLangChanger: CookieLangChanger,
        private dateTimeAdapter: DateTimeAdapter<any>,
        @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    ngOnInit() {
    }

    onLangChange() {
        this.httpClient.patch(this.baseUrl + 'api/User/UpdateLanguage', this.selected, { observe: 'response' })
            .subscribe(resp => {
                if (resp.status === 204) {
                    this.dialogRef.close(true);
                    this.translate.use(this.selected);
                    this.dateTimeAdapter.setLocale(this.selected);
                    localStorage.setItem("defaultLanguage", this.selected);
                    this.cookieLangChanger.setCookieLanguage();
                    this.snackBar.open(this.translate.instant('language-dialog.component.changeLanguage.success'), this.translate.instant('shared.ok'), {
                        duration: 4000,
                        panelClass: ['sicco-snackbar'],
                    });
                }

            },
                err => {
                    this.snackBar.open(this.translate.instant('language-dialog.component.changeLanguage.failed'), this.translate.instant('shared.ok'), {
                        duration: 4000,
                        panelClass: ['sicco-snackbar'],
                    });
                });
    }
}