import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ParkingDto } from "../../../shared/interfaces/parking-dto.interface";

@Injectable()
export class ParkingsService {
    refreshNeededParking$: Subject<void> = new Subject<void>();
    refreshNeededParkingGate$: Subject<void> = new Subject<void>();
    areDetailsShown: BehaviorSubject<boolean> = new BehaviorSubject(true);
    areOptionsShown: BehaviorSubject<boolean> = new BehaviorSubject(true);
    openEdit: Subject<any> = new Subject();
    private readonly _baseUrl: string = '';
    
    constructor(@Inject('BASE_URL') baseUrl: string,
                private _httpClient: HttpClient) {
        this._baseUrl = `${baseUrl}api`;
    }

    getParkings(): Observable<HttpResponse<Array<ParkingDto>>> {
        return this._httpClient.get<Array<ParkingDto>>(`${this._baseUrl}/Parking/GetAll`, { observe: 'response' });
    }
    
    getParkingsList() {
        return this._httpClient.get(`${this._baseUrl}/Parking/List`);
    }
    
    getParkingForCompanies(companies: string[]) {
        const params = new HttpParams({
            fromObject: {'companyids': companies}
        })
        return this._httpClient.get(`${this._baseUrl}/Parking/ListParkingsByCompanies?` + params.toString())
    }
    
    getParkingsByCompany(id: string): Observable<HttpResponse<{[id: string]: string}>> {
        const params: HttpParams = new HttpParams().set('companyId', id);
        
        return this._httpClient.get<{[id: string]: string}>(`${this._baseUrl}/Parking/Company`, { params: params, observe: "response" })
    }

    getParkingGates() {
        return this._httpClient.get(`${this._baseUrl}/ParkingGate/GetAll`);
    }

    getBuildings() {
        return this._httpClient.get(`${this._baseUrl}/Building/List`);
    }

    getCompanies() {
        return this._httpClient.get(`${this._baseUrl}/Company/ListIdNamePairs`);
    }

    createParking(parkingForm: any) {
        return this._httpClient.post(`${this._baseUrl}/Parking/Create`,
                parkingForm, { observe: 'response' })
                .pipe(
                    tap(() => {
                        this.refreshNeededParking$.next();
                    })
                );
    }

    updateParking(parkingForm: any) {
        return this._httpClient.put(`${this._baseUrl}/Parking/Update`,
            parkingForm, { observe: 'response' })
                .pipe(
                    tap(() => {
                        this.refreshNeededParking$.next();
                        this.refreshNeededParkingGate$.next();
                    })
                );
    }

    removeParking(parkingId: string) {
        return this._httpClient.delete(`${this._baseUrl}/Parking/Remove?parkingId=${parkingId}`)
            .pipe(
                tap(() => {
                    this.refreshNeededParking$.next();
                    this.refreshNeededParkingGate$.next();
                }));
    }

    createParkingGate(parkingGateForm: any) {
        return this._httpClient.post(`${this._baseUrl}/ParkingGate/Create`,
                parkingGateForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededParkingGate$.next();
                })
            );
    }

    updateParkingGate(parkingGateForm: any) {
        return this._httpClient.put(`${this._baseUrl}/ParkingGate/Update`,
                parkingGateForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededParkingGate$.next();
                })
            );
    }

    removeParkingGate(parkingGateId: string) {
        return this._httpClient.delete(`${this._baseUrl}/ParkingGate/Remove?parkingGateId=${parkingGateId}`)
            .pipe(
                tap(() => {
                    this.refreshNeededParkingGate$.next();
                    this.refreshNeededParking$.next();
                }));
    }
}