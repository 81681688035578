import { Injectable, Inject } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Observable, Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { SignalrService } from "../signalr-service/signalr.service";
import { mergeMap } from 'rxjs/operators';
import { DataBaseService } from "../database-service/database-service";

@Injectable()
export class MessagingService {
    currentMessage = new Subject<any>();
    signalRConnectionId: string;
    private baseUrl: string;

    currentMessage$(): Observable<any> {
        return this.currentMessage.asObservable();
    }

    constructor(
        private angularFireMessaging: AngularFireMessaging, private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string,
        private signalRService: SignalrService,
        private dataBaseService: DataBaseService) {
        this.baseUrl = baseUrl;
        this.signalRService.signalRConnectionId$.subscribe(response => {
            this.signalRConnectionId = response;
        });

        this.dataBaseService.requestPermission$().subscribe(() => {
            this.requestPermission();
        });
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                if (!token) {
                    this.currentMessage.next(null);
                }
                else {
                    this.checkToken(token).subscribe(() => { },
                        (err) => {
                            if (err.status === 404) {
                                this.subscribeWebPushNotifications(token)
                                    .subscribe(responseSubscribe => {
                                        if (responseSubscribe.status !== 200) {
                                            this.currentMessage.next(null);
                                        } else {
                                            this.currentMessage.next(true);
                                        }
                                    });
                            } else {
                                this.currentMessage.next(true);
                            }
                        });
                }
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
                this.currentMessage.next(null);
            }
        );
    }

    deleteToken() {
        this.angularFireMessaging.getToken
            .pipe(mergeMap(token => this.angularFireMessaging.deleteToken(token)))
            .subscribe(
                (token) => {
                    console.log('Token deleted!');
                }
            );
    }

    doesDbExist(pushState) {
        return this.dataBaseService.doesDbForWebPushExist(pushState, "firebase-installations-database");
    }

    disableWebPushNotifications() {
        return this.httpClient.post(this.baseUrl + `api/WebPush/DisablePushNotifications?signalRConnectionId=${this.signalRConnectionId}`, null, { observe: 'response' });
    }

    subscribeWebPushNotifications(token) {
        return this.httpClient.post(this.baseUrl + `api/WebPush/Subscribe?token=${token}&signalRConnectionId=${this.signalRConnectionId}`, null, { observe: 'response' });
    }

    private checkToken(token): Observable<any> {
        return this.httpClient.get(this.baseUrl + `api/WebPush/CheckToken?token=${token}`, { observe: 'response' });
    }
}
