import { Component, OnInit, Inject, } from "@angular/core";
import { OAuthService, NullValidationHandler, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { OAuthErrorEventParams } from "../shared/interfaces/oautherroreventparams.interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, timer } from "rxjs";
import { take, map } from "rxjs/operators";
import { SignalrService } from "../shared/signalr-service/signalr.service";
import { CorrectlyLoggedService } from "../shared/correctlylogged-service/correctly-logged-service";
import { DataBaseService } from "../shared/database-service/database-service";
import { authConfig, environment } from "../../environments/environment.prod";
import { UserService } from "../shared/user-sidenav-service/user-sidenav.service";

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    })
};

export class UserLogin {
    userazureid: string;
    loginemail: string;
    providertype: number;
    pincode: string;
}

export class UserInfo {
    status: string;
    instance: InstanceInfo;
    userId: string;
    manageUser: any;
    email: string;
    userType: string;
    language: string;
    ownerName: string;
    firstName: string;
    lastName: string;
    systemType: string;
    isPushEnabled: boolean;
    isWebPushEnabled: boolean;
    isMobileApplicationInstalled: string;
}

export class InstanceInfo {
    id: string;
    name: string;
    isDefault: boolean;
    instanceLogoUrl: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
    counter$: Observable<number>;
    baseUrl: string;
    countDown = 10;

    constructor(private oauthService: OAuthService,
        private signalR: SignalrService,
        private router: Router,
        public translate: TranslateService,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private dataBaseService: DataBaseService,
        private correctlyLoggedService: CorrectlyLoggedService,
        private userService: UserService,
        @Inject('BASE_URL') baseUrl: string) {
        this.counter$ = timer(0, 1000).pipe(
            take(this.countDown),
            map(() => --this.countDown)
        );
        this.baseUrl = baseUrl;
    }

    ngOnInit() {
        this.configureAndLogin();
        this.oauthService.events.subscribe(e => {
            if (e instanceof OAuthErrorEvent) {
                const parm = e.params as OAuthErrorEventParams;
                const type = e.type;
                if (parm != null && parm.error === 'access_denied' && parm.error_description.includes('AADB2C90091')) {
                    this.oauthService.initLoginFlow();
                }
                else if (parm != null && parm.error === 'server_error' && (parm.error_description.includes('AADB2C99002') || parm.error_description.includes('AADB2C'))) {
                    setInterval(() => {
                        this.oauthService.initLoginFlow();
                    }, 6000);
                    this.snackBar.open("Access denied, user does not exist, redirect to login page after few second", "OK", {
                        verticalPosition: "top",
                        duration: 5000,
                        panelClass: ['sicco-snackbar'],
                    });
                }
                else if (parm != null && parm.error === 'access_denied' && parm.error_description.includes('AADB2C90118')) {
                    window.location.href = environment.passwordResetConfig.passwordResetUrl;
                }
                else if (type === "invalid_nonce_in_state" || this.oauthService.hasValidIdToken()) {
                    this.oauthService.initLoginFlow();
                }
            }
        });

        window.addEventListener('storage', (event) => {
            if (event.storageArea == localStorage) {
                let instanceId = localStorage.getItem('instanceId');
                if (instanceId == undefined) {
                    this.oauthService.logOut();
                }
            }
        });
    }

    private configureAndLogin() {
        this.oauthService.setStorage(localStorage);
        this.oauthService.configure(authConfig);
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService.tokenValidationHandler = new NullValidationHandler();
        this.oauthService.loadDiscoveryDocument(environment.discoveryDocumentConfig.url).then(() => {
            this.oauthService.tryLogin().then(_ => {
                if (!this.oauthService.hasValidIdToken()) {
                    this.oauthService.initImplicitFlow();
                } else {
                    let claims = this.oauthService.getIdentityClaims();
                    let userLogin = new UserLogin();
                    userLogin.userazureid = claims['oid'];
                    userLogin.pincode = claims['extension_PIN'];
                    if (claims['emails']) {
                        userLogin.loginemail = claims['emails'][0];
                    }
                    if (claims["idp"]) {
                        userLogin.providertype = this.checkProvider(claims["idp"]);
                    } else {
                        userLogin.providertype = 2;
                    }
                    this.http.post<UserInfo[]>(this.baseUrl + "api/user/UserSignIn", userLogin, httpOptions).subscribe(response => {
                        if (response.length > 0) {
                            let userInfo = response.find(x => x.instance.isDefault);
                            if (!userInfo) {
                                userInfo = response[0];
                            }

                            if (userInfo.status == '6') {
                                setInterval(() => {
                                    this.oauthService.logOut();
                                }, 6000);
                                this.snackBar.open("Activation code expired, redirect to login page after few second", "OK", {
                                    verticalPosition: "top",
                                    duration: 5000,
                                    panelClass: ['sicco-snackbar'],
                                });
                                return;
                            }

                            if (userInfo.status == '4') {
                                setInterval(() => {
                                    this.oauthService.logOut();
                                }, 6000);
                                this.snackBar.open("User does not exist in this environment", "OK", {
                                    verticalPosition: "top",
                                    duration: 5000,
                                    panelClass: ['sicco-snackbar'],
                                });
                                return;
                            }

                            localStorage.setItem("status", userInfo.status);
                            localStorage.setItem("manageUser", userInfo.manageUser);
                            localStorage.setItem("email", userInfo.email);
                            localStorage.setItem("userType", userInfo.userType);
                            localStorage.setItem("defaultLanguage", userInfo.language);
                            localStorage.setItem("ownerName", userInfo.ownerName);
                            localStorage.setItem("firstName", userInfo.firstName);
                            localStorage.setItem("lastName", userInfo.lastName);
                            localStorage.setItem("systemType", userInfo.systemType);
                            localStorage.setItem("instanceId", userInfo.instance.id);
                            localStorage.setItem("instanceLogoUrl", userInfo.instance.instanceLogoUrl);
                            localStorage.setItem("isPushEnabled", JSON.stringify(userInfo.isPushEnabled));
                            localStorage.setItem("isWebPushEnabled", JSON.stringify(userInfo.isWebPushEnabled));
                            localStorage.setItem("isMobileApplicationInstalled", "false");
                            localStorage.setItem("userAzureId", userLogin.userazureid);

                            let langToSet = localStorage.getItem("defaultLanguage");
                            this.translate.use(langToSet);
                            this.signalR.initCommonSignalR();
                            let navigateUrl = "/home";
                            if (localStorage.getItem("systemType") === "1" || localStorage.getItem("systemType") === "3") {
                                this.signalR.initSignalRForSmartGate();
                                navigateUrl = "/smart-gate";
                            }
                            else {
                                this.signalR.initSignalRForSmartIn();
                            }

                            document.getElementById("ownerName").innerHTML = 'Sicco: ' + userInfo.ownerName;
                            var body = document.getElementById("isLoggedUser");
                            if (body) {
                                body.classList.add("userIsLogged");
                            }

                            if (parseInt(localStorage.getItem('userType')) === 4 && parseInt(userInfo.systemType) === 0) {
                                this.router.navigateByUrl('/security');
                            } else if(parseInt(localStorage.getItem('userType')) === 3){
                                this.router.navigateByUrl('/longterm-smartsync');
                            }
                            else {
                                let redirectUrl = sessionStorage.getItem("redirectUrl");

                                if (redirectUrl !== null && redirectUrl !== "/") {
                                    sessionStorage.removeItem("redirectUrl");
                                    this.router.navigateByUrl(redirectUrl);
                                } else {
                                    this.router.navigateByUrl(navigateUrl);
                                }
                            }

                            this.http.get(this.baseUrl + "api/user/CompanyParkingStatusForUser", { observe: 'response' }).subscribe(response => {
                                if (response.status === 200) {
                                    localStorage.setItem("ignoreParking", "false");
                                } else {
                                    localStorage.setItem("ignoreParking", "true");
                                }
                            });

                            this.dataBaseService.setLanguageToBrowserDb(localStorage.getItem("defaultLanguage"), JSON.parse(localStorage.getItem("isWebPushEnabled")));
                            this.correctlyLoggedService.correctlyLogged.next(true);
                            this.userService.instanceReady();
                            this.userService.setUserInfo(response);
                        }
                        else {
                            this.oauthService.logOut();
                        }
                    });
                }
            });
        });
    }

    private checkProvider(idp) {
        if (idp === "google.com") {
            return 0;
        }
        if (idp === "facebook.com") {
            return 1;
        }
        if (idp === "live.com") {
            return 3;
        }
        if (idp.indexOf("login.microsoftonline.com") > 0) {
            return 4;
        }
        if (idp.indexOf("appleid.apple.com") > 0) {
            return 5;
        }
        return null;
    }
}