import { Injectable, Inject } from '@angular/core';
import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class AppService {
    allowedUrl: string;
    status: string = '';
    defaultLanguage: string = '';
    manageUser: string = '';
    email: string = '';
    userType: string = '';
    currentRoute: string = '';
    ownerName: string = '';
    userName: string = '';
    userLastName: string = '';
    private subject = new Subject<string>();

    constructor(@Inject('BASE_URL') baseUrl: string) {
        this.allowedUrl = baseUrl + 'api';
    }

    sendLangChangeEvent(lang: string) {
        this.subject.next(lang);
    }
    getLangChangeEvent(): Observable<any> {
        return this.subject.asObservable();
    }
}

export function authConfigFactory(service: AppService): OAuthModuleConfig {
    return {
        resourceServer: {
            allowedUrls: [service.allowedUrl],
            sendAccessToken: true,
        }
    };
}