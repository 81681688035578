import { Inject, Injectable } from '@angular/core';
import { OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
    public cancelBtnLabel: string;
    public setBtnLabel: string;
    public currentLanguage;
    constructor(@Inject(TranslateService) public translate: TranslateService) {
        super();
        translate.onLangChange.subscribe(lang => {
            this.setTranslatedTexts();
        })
        this.setTranslatedTexts();
    }
    setTranslatedTexts() {
        this.cancelBtnLabel = this.translate.instant('shared.cancel');
        this.setBtnLabel = this.translate.instant('shared.set');
    }
}