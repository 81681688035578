import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()

export class ReceptionMessagesService {
    private refreshNeededReception = new Subject<void>();
    private baseUrl: string;

    get refreshNeededReception$() {
        return this.refreshNeededReception;
    }

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getReceptionMessages(receptionId, languageCode) {
        return this.httpClient.get(this.baseUrl + 'api/ReceptionMessage/ListReceptionMessagesForReception?receptionId=' + receptionId +'&languageCode=' + languageCode);
    }

    getReceptionMessagesWithAllLanguages(receptionMessageId) {
        return this.httpClient.get(this.baseUrl + 'api/ReceptionMessage/GetReceptionMessageWithAllLanguages?receptionMessageId=' + receptionMessageId);
    }

    getReceptions() {
        return this.httpClient.get(this.baseUrl + 'api/Reception/List');
    }

    updateReceptionMessage(receptionMessageForm: any) {
        return this.httpClient.put(this.baseUrl + 'api/ReceptionMessage/Update',
                receptionMessageForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededReception.next();
                })
            );
    }

    createReceptionMessage(receptionMessageForm: any) {
        return this.httpClient.post(this.baseUrl + 'api/ReceptionMessage/Create',
                receptionMessageForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededReception.next();
                })
            );
    }

    removeReceptionMessage(receptionMessageId: string) {
        return this.httpClient.delete(this.baseUrl + 'api/ReceptionMessage/Delete?receptionMessageId=' + receptionMessageId)
            .pipe(
                tap(() => {
                    this.refreshNeededReception.next();
                })
            );
    }
}