<mat-drawer-container class="user-panel-drawer">
    <mat-drawer #drawer class="drawer-container" mode="over" position="end" [autoFocus]="false">
        <ng-container *ngIf="panelType === 'Alarm'">
            <app-security-alarm></app-security-alarm>
        </ng-container>
        <ng-container *ngIf="panelType === 'Settings'">
            <div class="drawer drawer-right" tabindex="-1">
                <div class="drawer-fixed-header">
                    <div class="drawer-header">
                        <div class="avatar">{{ this.userInitials }}</div>
                        <div class="drawer-header-content">
                            <div class="drawer-title-name">
                                {{ userName }} {{ userLastName }}
                            </div>
                            <div class="drawer-title-email">
                                {{ userEmail }}
                            </div>
                        </div>
                        <button class="c-dialog-button--close my-auto ml-auto mr-3" mat-icon-button (click)="closeSideNav()">
                            <fa-icon [icon]="['fal','times']" size="2x"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="drawer-content"
                 [ngClass]="(hideHeader)?'drawer-user-settings-open':'drawer-user-settings-close'">
                <app-user-settings (hideHeaderParent)="toggleHeader($event)"></app-user-settings>
            </div>
            <div class="drawer-footer">
                <button mat-button class="drawer-logout-button" type="button" (click)="logout()">
                    <fa-icon class="fa-icon" [icon]="['fas','sign-out-alt']" size="lg"></fa-icon>
                    <span class="drawer-logout-button-text text-uppercase">
                        {{ 'menu.logout' | translate }}
                    </span>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="panelType === 'Sms'">
            <app-license-info [smsInfo]="smsInfo"></app-license-info>
        </ng-container>
    </mat-drawer>
    <mat-sidenav-container>
        <mat-sidenav disableClose *ngIf="claims" #leftSidenav mode="side" opened>
            <app-left-menu></app-left-menu>
        </mat-sidenav>
        <mat-sidenav-content>
            <div class="c-content-container">
                <div @backDropAnimation *ngIf="isLeftMenuOpen" (click)="sineNavToggle()" class="c-content-container__backdrop"></div>
                <nav id="nav" class="nav-top" *ngIf="claims">
                    <div class="nav-item">
                        <div class="nav-buttons">
                            <ng-container *ngIf="alarmButton && (portalType === '0' || portalType === '2')">
                                <button class="button-alarm" mat-button (click)="alarm(); panelType = 'Alarm'">
                                    {{ 'menu.alarm' | translate }}
                                </button>
                            </ng-container>
                            <ng-container *ngIf="infoButton">
                                <button class="user-settings-circle" title="{{ 'menu.userAccount' | translate }}"
                                        (click)="getSmsLimits(); drawer.toggle(); panelType = 'Sms'">
                                    <div class="info-small">
                                        <fa-icon class="fa-icon info-icon" size="2x" [icon]="['fal','info-circle']"></fa-icon>
                                    </div>
                                </button>
                            </ng-container>
                            <button class="user-settings-circle" title="{{ 'menu.userAccount' | translate }}"
                                    (click)="drawer.toggle(); panelType = 'Settings'">
                                <span class="avatar-small">{{ this.userInitials }}</span>
                            </button>
                        </div>
                    </div>
                </nav>
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
        <mat-sidenav #rightPanel class="mat-drawer-body mat-elevation-z6 overflow-hidden h-baseDrawerWidth" position="end" [autoFocus]="false" [disableClose]="isGalleryShown">
            <ng-template sidenavContent></ng-template>
        </mat-sidenav>
    </mat-sidenav-container>
</mat-drawer-container>