import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeToDate',
})

export class TimeToDatePipe implements PipeTransform {
    transform(value: string): Date {
        let time: string = value;
        let minutes: string;
        if (value.length <= 5) {
            minutes = time.substring(time.length - 2);
        } else {
            time = value.slice(0, -3);
            minutes = time.substring(time.length - 2);
        }
        let hours: string = time.substring(0, 2);
        if (hours.endsWith(":")) {
            hours = hours.slice(0, hours.length - 1);
        }
        return new Date(new Date().setHours(Number(hours), Number(minutes), 0, 0));
    }
}