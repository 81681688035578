import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from "../../shared.module";
import { AppIconModule } from "../../../app-icon-module";
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { LicenseInfoComponent } from './license-info.component';

@NgModule({
    imports: [
        SharedModule,
        AppIconModule,
        MatTooltipModule,
        MatButtonModule,
        TranslateModule.forChild({}),
        MatDividerModule,
        MatSelectModule
    ],
    declarations: [
        LicenseInfoComponent
    ],
    exports: [
        LicenseInfoComponent
    ],
})

export class LicenseInfoSharedModule {
}