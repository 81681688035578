import { OnInit, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";

@Component({
    selector: 'app-not-found',
    styleUrls: ['./notfound.component.scss'],
    template: `
        <div id="notfound">
            <div class="notfound">
                <div class="notfound-404">
                    <h3>Oops! Page not found</h3>
                    <h1><span>4</span><span>0</span><span>4</span></h1>
                </div>
                <h2>we are sorry, but the page you requested was not found</h2>
            </div>
        </div>
  `
})
export class NotFoundComponent implements OnInit {
    path: string;

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.data.pipe(take(1))
            .subscribe((data: { path: string }) => {
                this.path = data.path;
            });
    }
}