import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

//#region Light icons import "fal"
import {
    faCalendarAlt,
    faBriefcase,
    faBuilding,
    faConciergeBell,
    faPollH,
    faCar,
    faArchive,
    faUserFriends,
    faCogs,
    faUserCog,
    faGlobeAmericas,
    faArrowCircleLeft,
    faChevronDown as faChevronDownLight,
    faTablet,
    faShieldAlt,
    faExclamationTriangle,
    faClock as faClockLight,
    faSync as faSyncLight,
    faCheckCircle as faCheckCircleLight,
    faThList,
    faTimes as faTimesLight,
    faInfoCircle,
    faChartLine,
    faMinusOctagon,
    faBell,
    faUserShield,
    faServer,
    faQrcode,
    faPhoneAlt,
    faHistory,
    faLockAlt,
    faLockOpenAlt,
    faUnlock,
    faSirenOn,
    faParking,
    faCars,
    faSms,
    faWrench,
    faMobile,
    faEdit as faEditLight,
    faUser as faUserLight,
    faComment,
    faAddressCard,
    faFileAlt as falFileAlt,
    faCameraSlash,
    faBallotCheck
} from '@fortawesome/pro-light-svg-icons';
//#endregion Light icons import

//#region Solid icons import "fas"
import {
    faSearch,
    faPlus,
    faChevronRight as faChevronRightSolid,
    faChevronLeft as fasChevronLeft,
    faUser,
    faChevronDown,
    faChevronUp as faChevronUpSolid,
    faSignOutAlt as faSignOutAltSolid,
    faSave,
    faArrowToTop,
    faCarBuilding,
    faBuilding as fasBuilding,
    faCar as fasCar,
    faHome,
    faCog,
    faArrowRightToArc,
    faPenToSquare,
    faHandPointer
} from '@fortawesome/pro-solid-svg-icons';
//#endregion Solid icons import

//#region Regular icons import "far"
import {
    faEdit,
    faTrashAlt,
    faCalendarCheck,
    faTimesCircle,
    faCheckCircle,
    faBan,
    faSync,
    faUserTimes,
    faUserCheck,
    faSignInAlt,
    faSignOutAlt,
    faTimes,
    faBallot,
    faFilter,
    faListAlt,
    faChevronUp,
    faCheck,
    faPlus as faPlusRegular,
    faChevronCircleDown,
    faChevronDown as faChevronDownRegular,
    faExternalLinkAlt,
    faFileAlt,
    faClipboardCheck,
    faTruckMoving,
    faTrailer,
    faClipboard,
    faShippingTimed,
    faPhone,
    faTabletAndroidAlt,
    faShippingFast,
    faBarcodeRead,
    faExclamationCircle,
    faClock,
    faFileExcel,
    faFileCsv,
    faChevronRight as faChevronRightRegular,
    faImages,
    faUserClock,
    faCommentAltLines,
    faCalendarAlt as faCalendarAltRegular,
    faCreditCard,
    faSyncAlt,
    faUserSlash,
    faFileUpload,
    faFileCheck,
    faQuestionCircle,
    faChevronLeft as farChevronLeft,
    faSquarePollHorizontal,
    faCalendarLines,
    faCar as farCar,
    faTableList,
    faChartLine as farChartLine,
    faBoxArchive,
    faUserGroup,
    faBriefcase as farBriefcase,
    faGears,
    faBuilding as farBuilding,
    faConciergeBell as farConciergeBell, 
    faUserShield as farUserShield,
    faParking as farParking,
    faComment as farComment,
    faTablet as farTablet,
    faFileLines,
    faMessageArrowUpRight,
    faMemoCircleInfo, 
    faInfoCircle as farInfoCircle,
    faIdBadge,
    faFilePdf,
    faBoxesPacking,
    faBarcode,
    faCameraCctv,
    faTabletScreen,
    faMobileSignalOut,
    faLocationDot,
    faBallotCheck as farBallotCheck,
    faArrowDownArrowUp,
    faEllipsis,
    faDownload,
    faTruck,
    faRotateLeft,
    faRotateRight,
    faArrowsRotate,
    faContainerStorage
} from '@fortawesome/pro-regular-svg-icons'
//#endregion Regular icons import

@NgModule({
    imports: [
        FontAwesomeModule
    ],
    exports: [
        FontAwesomeModule
    ]
})
export class AppIconModule {
    constructor(private library: FaIconLibrary) {
        library.addIcons(
            faCalendarAlt,
            faCalendarAltRegular,
            faSearch,
            faPlus,
            faEdit,
            faTrashAlt,
            faChevronRightSolid,
            fasChevronLeft,
            faCalendarCheck,
            faTimesCircle,
            faBan,
            faSync,
            faCheckCircle,
            faUserTimes,
            faUserCheck,
            faSignInAlt,
            faSignOutAlt,
            faTimes,
            faBallot,
            faUser,
            faChevronDown,
            faFilter,
            faBriefcase,
            faBuilding,
            faConciergeBell,
            faPollH,
            faCar,
            faArchive,
            faUserFriends,
            faUserShield,
            faCogs,
            faSignOutAltSolid,
            faUserCog,
            faGlobeAmericas,
            faArrowCircleLeft,
            faSave,
            faChevronDownLight,
            faChevronUp,
            faListAlt,
            faArrowToTop,
            faTablet,
            faCheck,
            faShieldAlt,
            faExclamationTriangle,
            faPlusRegular,
            faCheckCircleLight,
            faClockLight,
            faSyncLight,
            faInfoCircle,
            faExternalLinkAlt,
            faFileAlt,
            faTimesLight,
            faChevronDownRegular,
            faChevronCircleDown,
            faTruckMoving,
            faTrailer,
            faClipboardCheck,
            faClipboard,
            faShippingTimed,
            faPhone,
            faTabletAndroidAlt,
            faShippingFast,
            faBarcodeRead,
            faExclamationCircle,
            faClock,
            faThList,
            faChartLine,
            faChevronRightRegular,
            faMinusOctagon,
            faFileExcel,
            faFileCsv,
            faImages,
            faBell,
            faUserClock,
            faCommentAltLines,
            faServer,
            faQrcode,
            faPhoneAlt,
            faSirenOn,
            faParking,
            faCars,
            faCarBuilding,
            fasCar,
            fasBuilding,
            faSms,
            faHome,
            faCreditCard,
            faSyncAlt,
            faUserSlash,
            fasBuilding,
            faSirenOn,
            faHistory,
            faLockAlt,
            faLockOpenAlt,
            faUnlock,
            faCog,
            faWrench,
            faMobile,
            faChevronUpSolid,
            faEditLight,
            faUserLight,
            faComment,
            faAddressCard,
            faFileUpload,
            faFileCheck,
            falFileAlt,
            faQuestionCircle,
            faCameraSlash,
            farChevronLeft,
            faSquarePollHorizontal,
            faCalendarLines,
            farCar,
            faTableList,
            farChartLine,
            faBoxArchive,
            faUserGroup,
            farBriefcase,
            faGears,
            farBuilding,
            farConciergeBell,
            farUserShield,
            farParking,
            farComment,
            farTablet,
            faFileLines,
            faMessageArrowUpRight,
            faMemoCircleInfo,
            farInfoCircle,
            faIdBadge,
            faFilePdf,
            faBoxesPacking,
            faBallotCheck,
            faBarcode,
            faCameraCctv,
            faTabletScreen,
            faMobileSignalOut,
            faLocationDot,
            faArrowDownArrowUp,
            farBallotCheck,
            faEllipsis,
            faDownload,
            faTruck,
            faDownload,
            faRotateLeft,
            faRotateRight,
            faArrowsRotate,
            faContainerStorage,
            faArrowRightToArc,
            faPenToSquare,
            faHandPointer
        );
    }
}