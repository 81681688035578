<div class="c-left-menu">
    <div class="c-left-menu__container" [ngClass]="(sideNavState)?'c-left-menu__container--open':'c-left-menu__container--close'">
        <div class="c-left-menu__header">
            <div *ngIf="isCustomInstanceLogoSet()" class="c-left-menu__logo c-left-menu__logo--custom" [style.background]="'url(' + instanceLogoUrl + ')'" [ngClass]="(sideNavState)?'c-left-menu__logo--open':'c-left-menu__logo--close'" alt="Custom logo"></div>
            <div *ngIf="!isCustomInstanceLogoSet() && (systemType === '0' || systemType === '2')" class="c-left-menu__logo c-left-menu__logo--smart-in" [ngClass]="(sideNavState)?'c-left-menu__logo--open':'c-left-menu__logo--close'" alt="SmartIn Securitas"></div>
            <div *ngIf="!isCustomInstanceLogoSet() && (systemType === '1' || systemType === '3')" class="c-left-menu__logo c-left-menu__logo--smart-gate" [ngClass]="(sideNavState)?'c-left-menu__logo--open':'c-left-menu__logo--close'" alt="SmartGate Securitas"></div>
            <span class="c-left-menu__owner" *ngIf="sideNavState" @animateText>{{ ownerName }}</span>
        </div>
        <div class="c-left-menu__content mb-auto mt-5">
            <a class="c-left-menu__link c-left-menu__link--normal-link" [routerLink]="getHomeLink()" *ngIf="userType !=='3' && userType !=='4'">
                <fa-icon class="mat-icon-button" [icon]="['far','square-poll-horizontal']" size="xl"></fa-icon>
                <span *ngIf="sideNavState" @animateText>{{ 'menu.dashboard' | translate }}</span>
                <div *ngIf="router.url === '/home' || router.url === '/smart-gate' || router.url === '/security'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
            </a>
            <a class="c-left-menu__link c-left-menu__link--normal-link" [routerLink]="getHomeLink()" *ngIf="userType ==='4'">
                <fa-icon class="mat-icon-button" [icon]="['far','square-poll-horizontal']" size="lg"></fa-icon>
                <span *ngIf="sideNavState" @animateText>{{ 'menu.securityDashboard' | translate }}</span>
                <div *ngIf="router.url === '/security' || router.url === '/smart-gate'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
            </a>
            <a class="c-left-menu__link c-left-menu__link--normal-link" [routerLink]="['/longterm']" *ngIf="(systemType === '0' || systemType === '2') && !ignoreParking && (userType === '1' || userType ==='2')">
                <fa-icon class="mat-icon-button" [icon]="['far','calendar-lines']" size="lg"></fa-icon>
                <span *ngIf="sideNavState" @animateText>{{ 'menu.longterm' | translate }}</span>
                <div *ngIf="router.url === '/longterm'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
            </a>
            <a class="c-left-menu__link c-left-menu__link--normal-link" [routerLink]="['/longterm-smartsync']" *ngIf="(systemType === '0' || systemType === '2') && !ignoreParking && (userType ==='3')">
                <fa-icon class="mat-icon-button" [icon]="['far','calendar-lines']" size="lg"></fa-icon>
                <span *ngIf="sideNavState" @animateText>{{ 'menu.longterm' | translate }}</span>
                <div *ngIf="router.url === '/longterm-smartsync'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
            </a>
            <a class="c-left-menu__link c-left-menu__link--normal-link c-left-menu__link--nowrap" [routerLink]="['/vehicles']" *ngIf="(systemType === '0' || systemType === '2') && !ignoreParking && userType === '2'">
                <fa-icon class="mat-icon-button" [icon]="['far','car']" size="lg"></fa-icon>
                <span *ngIf="sideNavState" @animateText>{{ 'menu.vehicles' | translate }}</span>
                <div *ngIf="router.url === '/vehicles'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
            </a>
            <a class="c-left-menu__link c-left-menu__link--normal-link" [routerLink]="['/kpi']" *ngIf="(systemType === '1' || systemType === '3') && (userType !=='3' && userType !=='4')">
                <fa-icon class="mat-icon-button" [icon]="['far','table-list']" size="lg"></fa-icon>
                <span *ngIf="sideNavState" @animateText>{{ 'menu.kpi' | translate }}</span>
                <div *ngIf="router.url === '/kpi'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
            </a>
            <a class="c-left-menu__link c-left-menu__link--normal-link" [routerLink]="['/reports']" *ngIf="(systemType === '1' || systemType === '3') && (userType === '1' || userType ==='2' || userType === '0')">
                <fa-icon class="mat-icon-button" [icon]="['far','chart-line']" size="lg"></fa-icon>
                <span *ngIf="sideNavState" @animateText>{{ 'menu.reports' | translate }}</span>
                <div *ngIf="router.url === '/reports' || router.url === '/reports/handled-vehicles'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
            </a>
            <a class="c-left-menu__link c-left-menu__link--normal-link" [routerLink]="getArchiveLink()" *ngIf="userType !=='3' && userType !=='4'">
                <fa-icon class="mat-icon-button" [icon]="['far','box-archive']" size="lg"></fa-icon>
                <span *ngIf="sideNavState" @animateText>{{ 'menu.archive' | translate }}</span>
                <div *ngIf="router.url === '/archive' || router.url === '/smart-gate-archive'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
            </a>
            <a class="c-left-menu__link c-left-menu__link--normal-link" [routerLink]="['/users']" *ngIf="userType === '1' || userType ==='2'">
                <fa-icon class="mat-icon-button" [icon]="['far','user-group']" size="lg"></fa-icon>
                <span *ngIf="sideNavState" @animateText>{{ 'menu.users' | translate }}</span>
                <div *ngIf="router.url === '/users'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
            </a>
            <a class="c-left-menu__link c-left-menu__link--normal-link c-left-menu__link--nowrap" [routerLink]="['/company-details']" *ngIf="userType === '1' || userType ==='2'">
                <fa-icon class="mat-icon-button" [icon]="['far','briefcase']" size="lg"></fa-icon>
                <span *ngIf="sideNavState" @animateText>{{ 'menu.company-details' | translate }}</span>
                <div *ngIf="router.url === '/company-details'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
            </a>
            <a class="c-left-menu__link c-left-menu__link--normal-link" [routerLink]="[]" (click)="showSubMenu()" *ngIf="userType ==='2'">
                <fa-icon class="mat-icon-button" [icon]="['far','cogs']" size="lg"></fa-icon>
                <span *ngIf="sideNavState" class="c-left-menu__link c-left-menu__link--no-gap" @animateText>
                    {{ 'menu.administration' | translate }}
                    <fa-icon class="mat-icon-button" [ngClass]="state === 'default' ? 'h-rotateX--0deg' : 'h-rotateX--180deg'" [icon]="['fal','chevron-down']" size="sm"></fa-icon>
                </span>
            </a>
            <div *ngIf="subMenuCollapse && userType === '2'" @toggleSubMenu>
                <a class="c-left-menu__link c-left-menu__link--sub-menu-link" [routerLink]="['/companies']">
                    <fa-icon class="mat-icon-button" [icon]="['fal','briefcase']" size="lg"></fa-icon>
                    <span @animateText *ngIf="sideNavState && (systemType === '0'  || systemType === '2')">{{ 'menu.companies' | translate }}</span>
                    <span @animateText *ngIf="sideNavState && (systemType === '1'  || systemType === '3')">{{ 'menu.administrators' | translate }}</span>
                    <div *ngIf="router.url === '/companies'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
                </a>
                <a class="c-left-menu__link c-left-menu__link--sub-menu-link" [routerLink]="['/buildings']" [routerLinkActive]="['link-active']" *ngIf="systemType === '0' || systemType === '2'">
                    <fa-icon class="mat-icon-button" [icon]="['far','building']" size="lg"></fa-icon>
                    <span *ngIf="sideNavState" @animateText>{{ 'menu.buildings' | translate }}</span>
                    <div *ngIf="router.url === '/buildings'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
                </a>
                <a class="c-left-menu__link c-left-menu__link--sub-menu-link" [routerLink]="getReceptionsLink()" [routerLinkActive]="['link-active']">
                    <fa-icon class="mat-icon-button" [icon]="['far','concierge-bell']" size="lg" *ngIf="systemType === '0'  || systemType === '2'"></fa-icon>
                    <fa-icon class="mat-icon-button" [icon]="['far','user-shield']" size="lg" *ngIf="systemType === '1'  || systemType === '3'"></fa-icon>
                    <span @animateText *ngIf="sideNavState && (systemType === '0' || systemType === '2')">{{ 'menu.reception' | translate }}</span>
                    <span @animateText *ngIf="sideNavState && (systemType === '1' || systemType === '3')">{{ 'menu.security-posts' | translate }}</span>
                    <div *ngIf="router.url === '/receptions' || router.url === '/security-post'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
                </a>
                <a class="c-left-menu__link c-left-menu__link--sub-menu-link" [routerLink]="['/parkings']" *ngIf="systemType === '0' || systemType === '2'">
                    <fa-icon class="mat-icon-button" [icon]="['far','parking']" size="lg"></fa-icon>
                    <span *ngIf="sideNavState" @animateText>{{ 'menu.parkings' | translate }}</span>
                    <div *ngIf="router.url === '/parkings'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
                </a>
                <a class="c-left-menu__link c-left-menu__link--sub-menu-link" [routerLink]="['/reception-messages']" *ngIf="systemType === '0' || systemType === '2'">
                    <fa-icon class="mat-icon-button" [icon]="['far','comment']" size="lg"></fa-icon>
                    <span *ngIf="sideNavState" @animateText>{{ 'menu.reception-messages' | translate }}</span>
                    <div *ngIf="router.url === '/reception-messages'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
                </a>
                <a class="c-left-menu__link c-left-menu__link--sub-menu-link" [routerLink]="['/devices']">
                    <fa-icon class="mat-icon-button" [icon]="['far','tablet']" size="lg"></fa-icon>
                    <span *ngIf="sideNavState" @animateText>{{ 'menu.devices' | translate }}</span>
                    <div *ngIf="router.url === '/devices'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
                </a>
                <a class="c-left-menu__link c-left-menu__link--sub-menu-link" [routerLink]="['/predefined-values']" *ngIf="systemType === '1' || systemType === '3'">
                    <fa-icon class="mat-icon-button" [icon]="['far','file-lines']" size="lg"></fa-icon>
                    <span *ngIf="sideNavState" @animateText>{{ 'menu.predefined-values' | translate }}</span>
                    <div *ngIf="router.url === '/predefined-values'" class="c-left-menu__sicco-active-dot" [ngClass]="sideNavState ? 'c-left-menu__sicco-active-dot--navbar-opened' : 'c-left-menu__sicco-active-dot--navbar-closed'"></div>
                </a>
            </div>
        </div>
        <div class="c-left-menu__footer mt-5 mb-3">
            <span @animateVersionText *ngIf="sideNavState" class="c-left-menu__version">v.{{portalVersion}}</span>
            <button mat-mini-fab matRipple matRippleColor="#00000033" class="c-left-menu__toggle-button ml-auto" [ngClass]="sideNavState ? 'c-left-menu__toggle-button--navbar-opened' : 'c-left-menu__toggle-button--navbar-closed'" (click)="sideNavToggle()">
                <mat-icon class="h-color-000000" [ngClass]="sideNavState ? 'h-rotateY--180deg' : 'h-rotateY--0deg'">chevron_right</mat-icon>
            </button>
        </div>
    </div>
    <div class="c-left-menu__dot-container"></div>
</div>