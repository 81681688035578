export enum InvitationStatus {
    Scheduled = 0,
    Accepted = 1,
    Awaiting = 111,
    Approved = 112,
    Inside = 121,
    ReadyForDocumentPickup = 122,
    Outside = 201,
    Completed = 301,
    Cancelled = 302,
    Rejected = 303,
    Expired = 304
}