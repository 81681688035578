import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ICancelReasons } from "../../../shared/interfaces/predefinedValues.interface";

@Injectable()
export class CancelReasonsService {

    private refreshNeeded = new Subject<void>();
    private baseUrl: string;

    get refreshNeeded$() {
        return this.refreshNeeded;
    }

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getCancelReasons(): Observable<ICancelReasons[]> {
        return this.httpClient.get<Array<ICancelReasons>>(this.baseUrl + 'api/CancelReason/ListCancelReasonsForCompany');
    }

    updateCancelReason(cancelReasonForm: any) {
        return this.httpClient.put(this.baseUrl + 'api/CancelReason/Update',
                cancelReasonForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeeded$.next();
                })
            );
    }

    removeCancelReason(cancelReasonId: string) {
        return this.httpClient.delete(this.baseUrl + 'api/CancelReason/Remove?cancelReasonId=' + cancelReasonId)
            .pipe(
                tap(() => {
                    this.refreshNeeded$.next();
                })
            );
    }

    createCancelReason(cancelReasonForm: any) {
        return this.httpClient.post(this.baseUrl + 'api/CancelReason/Create',
                cancelReasonForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeeded$.next();
                })
            );
    }
}