import { TranslateService } from "@ngx-translate/core";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { Injectable } from "@angular/core";

@Injectable()
export class CookieLangChanger {
    constructor(private translate: TranslateService, private ccService: NgcCookieConsentService) {
    }

    setCookieLanguage() {
        this.translate
            .get([
                'cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy', 'cookie.href'
            ])
            .subscribe(data => {

                this.ccService.getConfig().content = this.ccService.getConfig().content || {};
                this.ccService.getConfig().content.header = data['cookie.header'];
                this.ccService.getConfig().content.message = data['cookie.message'];
                this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
                this.ccService.getConfig().content.allow = data['cookie.allow'];
                this.ccService.getConfig().content.deny = data['cookie.deny'];
                this.ccService.getConfig().content.link = data['cookie.link'];
                this.ccService.getConfig().content.policy = data['cookie.policy'];
                this.ccService.getConfig().content.href = data['cookie.href'];

                this.ccService.destroy(); 
                this.ccService.init(this.ccService.getConfig());
            });
    }
}