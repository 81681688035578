<div class="sms-info-container">
    <div class="sms-info-header">
        <fa-icon class="fa-icon" [icon]="['fal','info-circle']" size="2x"></fa-icon>
        <span>
            {{ 'sms-info.view.systemInfo' | translate }}
        </span>
        <button id="close-button" mat-icon-button (click)="close()">
            <fa-icon [icon]="['fal','times']" size="2x"></fa-icon>
        </button>
    </div>
    <mat-divider></mat-divider>
    <h4>
        {{ 'sms-info.view.sms' | translate }}
    </h4>
    <div class="sms-counter">
        <fa-icon class="fa-icon" [icon]="['fal','sms']" size="lg"></fa-icon>
        <div class="sms-counter-container" [ngClass]="(!name && !type)?'without-name':''">
            <p><span class="ml-0 text-black" *ngIf="name">{{name}}</span> <span class="ml-0 text-black" *ngIf="type"> {{ 'sms-info.view.type' | translate }}: {{type}}</span></p>
            <p>
                <fa-icon class="fa-icon" [icon]="['far','sync-alt']" matTooltip="{{ 'sms-info.view.renewableSms' | translate }}"></fa-icon>
                <span class="float-right text-black">{{internationalUsage}}/{{internationalLimit}}</span>
            </p>
            <p>
                <fa-icon class="fa-icon" [icon]="['far','credit-card']" matTooltip="{{ 'sms-info.view.extraSms' | translate }}"></fa-icon>
                <fa-icon class="fa-icon ml-1" [icon]="['fal','globe-americas']" matTooltip="{{ 'sms-info.view.extraSms' | translate }}"></fa-icon>
                <span class="float-right text-black">{{internationalExtraUsage}}</span>
            </p>
            <p>
                <fa-icon class="fa-icon" [icon]="['far','credit-card']" matTooltip="{{ 'sms-info.view.extraSms' | translate }}"></fa-icon>
                <span class="ml-1" matTooltip="{{ 'sms-info.view.extraLocalSms' | translate }}">PL</span>
                <span class="float-right text-black">{{localExtraUsage}}</span>
            </p>
        </div>
    </div>
</div>