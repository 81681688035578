import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class SecurityPostService {
    private refreshNeeded = new Subject<void>();
    private baseUrl: string;
    areDetailsShown: BehaviorSubject<boolean> = new BehaviorSubject(true);
    areContactsShown: BehaviorSubject<boolean> = new BehaviorSubject(true);

    get refreshNeeded$() {
        return this.refreshNeeded;
    }

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getReceptions() {
        return this.httpClient.get(this.baseUrl + 'api/Reception/GetAll');
    }

    getBuildings() {
        return this.httpClient.get(this.baseUrl + 'api/Building/List');
    }

    getCompanies() {
        return this.httpClient.get(this.baseUrl + 'api/Company/ListIdNamePairs');
    }

    updateSecurityPost(securityPostForm: any) {
        return this.httpClient.put(this.baseUrl + 'api/Reception/UpdateBasicData',
                securityPostForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    removeSecurityPost(receptionId: string) {
        return this.httpClient.delete(this.baseUrl + 'api/Reception/Remove?receptionId=' + receptionId)
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    createSecurityPost(receptionForm: any) {
        return this.httpClient.post(this.baseUrl + 'api/Reception/Create',
                receptionForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }
}