import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: 'localhost',
    },
    "position": "bottom-right",
    "theme": "block",
    "palette": {
        "popup": {
            "background": "#ffffff",
            "text": "#000000",
            "link": "#000000"
        },
        "button": {
            "background": "#ffcd36",
            "text": "#000000",
            "border": "transparent"
        }
    },
    "type": "info"
};