import { Pipe, PipeTransform } from "@angular/core";
import { IIdNamePair } from "../interfaces/idname.interface";

@Pipe({
    name: 'responseToIdNamePairArray'
})
export class ResponseToIdNameArrayPipe implements PipeTransform{
    transform(response: object): IIdNamePair[] {
        let idNamePairArray: IIdNamePair[] = [];
        if (response) {
            for (const [key, value] of Object.entries(response)) {
                idNamePairArray.push({id: key as string, name: value as string});
            }
        }
        return idNamePairArray;
    }
}