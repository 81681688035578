import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToTime',
})
export class DateToTimePipe implements PipeTransform {
    transform(value: Date): string {
        const time = new Date(value);
        return time.getHours().toString() + ":" + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes();
    }
}