import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from "../../shared.module";
import { ErrorControlComponent } from "./error-control.component";

@NgModule({
    imports: [
        SharedModule,
        TranslateModule.forChild({})
    ],
    declarations: [
        ErrorControlComponent
    ],
    exports: [
        ErrorControlComponent
    ]
})

export class ErrorControlSharedModule {
}