import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tap } from "rxjs/operators";
import { IAdditionalDocumentPickupMessages } from "../../../shared/interfaces/predefinedValues.interface";

@Injectable()
export class DocumentsPickupMessagesService {
    private refreshNeeded = new Subject<void>();
    
    constructor(private httpClient: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

    get refreshNeeded$() {
        return this.refreshNeeded;
    }

    getAdditionalDocumentsPickupMessages(): Observable<IAdditionalDocumentPickupMessages[]> {
        return this.httpClient.get<Array<IAdditionalDocumentPickupMessages>>(this.baseUrl + "api/AdditionalDocumentPickupMessage/ListAdditionalDocumentPickUpMessagesForCompany");
    }

    createDocumentPickupMessage(documentPickupMessage: IAdditionalDocumentPickupMessages): Observable<HttpResponse<null>> {
        return this.httpClient.post<null>(this.baseUrl + 'api/AdditionalDocumentPickupMessage/Create', documentPickupMessage, { observe: 'response' })
            .pipe(tap({
                next: () => this.refreshNeeded.next()
            }));
    }

    updateDocumentPickupMessage(documentPickupMessage: IAdditionalDocumentPickupMessages): Observable<HttpResponse<null>> {
        return this.httpClient.put<null>(this.baseUrl + 'api/AdditionalDocumentPickupMessage/Update', documentPickupMessage, { observe: 'response' })
            .pipe(tap({
                next: () => this.refreshNeeded.next()
            }));
    }

    removeDocumentPickupMessage(documentPickupMessageId: string): Observable<HttpResponse<null>> {
        return this.httpClient.delete<null>(this.baseUrl + 'api/AdditionalDocumentPickupMessage/Remove?documentPickupMessageId=' + documentPickupMessageId, { observe: 'response' })
            .pipe(tap({
                next: () => this.refreshNeeded.next()
            }));
    }
}