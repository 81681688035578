
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs'

@Injectable()
export class DataBaseService {
    requestPermission = new Subject<any>();

    requestPermission$(): Observable<any> {
        return this.requestPermission.asObservable();
    }

    constructor() { }

    doesDbForWebPushExist(pushState, databaseName) {
        var request = window.indexedDB.open(databaseName);
        request.onupgradeneeded = () => {
            request.transaction.abort();

            if (pushState === true) {
                this.requestPermission.next(true);
            }
        }
        request.onsuccess = () => {
            if (pushState === true) {
                this.requestPermission.next(true);
            }
        }
    }

    setLanguageToBrowserDb(userLanguage: string, currentPushState: boolean) {
        let request = window.indexedDB.open("SiccoLanguage", 1),
            db,
            tx,
            store,
            index;

        request.onupgradeneeded = () => {
            const db = request.result;
            store = db.createObjectStore("LanguageStore", { keyPath: "language" });
            index = store.createIndex("languageText", "languageText");
        };

        request.onsuccess = () => {
            db = request.result;
            tx = db.transaction("LanguageStore", "readwrite");
            store = tx.objectStore("LanguageStore");
            index = store.clear("languageText");

            db.onerror = (e) => {
                console.log("ERROR " + e.target.errorCode);
            }

            store.put({ language: userLanguage });

            tx.oncomplete = () => {
                db.close();
                if (currentPushState) {
                    const broadcast = new BroadcastChannel('updateLang-channel');
                    broadcast.postMessage({
                        type: 'UPDATE_LANGUAGE',
                    });
                }
            }
        };

        request.onerror = () => {
            console.log("Error loading database");
        }
    }
}