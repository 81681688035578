import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class BuildingsService {
    openEdit: Subject<any> = new Subject();
    private refreshNeededBuilding = new Subject<void>();
    private baseUrl: string;

    get refreshNeededBuilding$() {
        return this.refreshNeededBuilding;
    }

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getBuildings() {
        return this.httpClient.get(this.baseUrl + 'api/Building/GetAll');
    }

    updateBuilding(buildingForm: any) {
        return this.httpClient.put(this.baseUrl + 'api/Building/Update',
            buildingForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededBuilding.next();
                })
            );
    }

    createBuilding(buildingForm: any) {
        return this.httpClient.post(this.baseUrl + 'api/Building/Create',
            buildingForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededBuilding.next();
                })
            );
    }

    removeBuilding(buildingId: string) {
        return this.httpClient.delete(this.baseUrl + 'api/Building/Remove?BuildingId=' + buildingId)
            .pipe(
                tap(() => {
                    this.refreshNeededBuilding.next();
                })
            );
    }

}