import { Injectable, Inject } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { HttpHeaders } from "@angular/common/http";
import { IInvitation } from "../../shared/interfaces/invitation.interface";
import { IUserDefaults } from "../../shared/interfaces/IUserDefaults.interface";

const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

@Injectable()
export class HomeService {
    private refreshNeeded = new Subject<void>();
    private baseUrl: string;
    userDefaults = new BehaviorSubject<IUserDefaults>(null);

    get refreshNeeded$() {
        return this.refreshNeeded;
    }

    get getUserDefaults$() {
        return this.userDefaults;
    }

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getAllInvitations() {
        return this.httpClient.get(this.baseUrl + 'api/invitation/GetForUser');
    }

    createSimpleInvitation(invitation) {
        return this.httpClient
            .post(this.baseUrl + 'api/invitation/CreateSimple',
                JSON.stringify(invitation),
                { headers: headers, observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    createInvitation(invitation) {
        return this.httpClient
            .post(this.baseUrl + 'api/invitation/Create',
                JSON.stringify(invitation),
                { headers: headers, observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    updateInvitations(value: any) {
        return this.httpClient.put(this.baseUrl + 'api/invitation/UpdateInvitation',
            JSON.stringify(value),
            {
                headers: headers,
                observe: 'response'
            })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    cancelInvitation(value: any) {
        return this.httpClient.patch(this.baseUrl + 'api/invitation/CancelInvitation', value, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    sendNotification(invitationId) {
        return this.httpClient.get(this.baseUrl + 'api/invitation/SendNotification?invitationId=' + invitationId,
            { observe: 'response' });
    }

    setUserDefaults(userDefaults) {
        this.userDefaults.next(userDefaults);
    }

    getParkingSpots() {
        return this.httpClient.get(this.baseUrl + 'api/ParkingSpot/ListParkingSpotsDefaults');
    }

    getParkingSpotsByParking(parkingId: any) {
        return this.httpClient.get(this.baseUrl + 'api/ParkingSpot/ListParkingSpotsByParking?parkingId=' + parkingId);
    }

    getUserDefaults() {
        return this.httpClient.get(this.baseUrl + 'api/User/UserDefaultSettings');
    }

    getParkingsByBuilding(value) {
        return this.httpClient.get(this.baseUrl + 'api/Parking/Building?buildingId=' + value);
    }

    getReceptionsByBuilding(value) {
        return this.httpClient.get(this.baseUrl + 'api/Reception/Building?buildingId=' + value);
    }

    getReceptionMessages(receptionId: string, languageCode: string) {
        return this.httpClient.get(this.baseUrl + 'api/ReceptionMessage/ListReceptionMessagesForReception?receptionId=' + receptionId + '&languageCode=' + languageCode);
    }
}