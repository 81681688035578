<div class="confirmation-box">
    <h2 mat-dialog-title>{{headerMessage}}</h2>
    <div class="col-sm-12">
        <div mat-dialog-content class="dialog-delete-content">
            {{message}}
        </div>
    </div>
        <mat-dialog-actions align="end" >
            <button mat-button [mat-dialog-close]="true" tabindex="1" class="dialog-confirm text-uppercase">{{confirmButtonText}}</button>
            <button mat-button [mat-dialog-close]="false" tabindex="-1" class="dialog-confirm text-uppercase">{{cancelButtonText}}</button>
        </mat-dialog-actions>
</div>
