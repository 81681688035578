import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";
import { IFormAnswers } from "../../interfaces/shipment.interface";

@Injectable()
export class ReportDetailsService {
    private baseUrl: string;
    currentImageIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    currentFormAnswers$: BehaviorSubject<IFormAnswers[]> = new BehaviorSubject<IFormAnswers[]>([]);

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getReport(shipmentId) {
        return this.httpClient.get(this.baseUrl + 'api/Report/GetShipmentEventLog?shipmentId=' + shipmentId);
    }

    getFormAnswers(shipmentId) {
        return this.httpClient.get(this.baseUrl + 'api/Report/GetShipmentFormAnswers?shipmentId=' + shipmentId);
    }

    removeImage(shipmentId, eventLogId, imageUrl) {
        return this.httpClient.delete(this.baseUrl + 'api/Invitation/DeleteImage?invitationId=' + shipmentId + '&eventLogId=' + eventLogId + '&imageUrl=' + imageUrl);
    }
}