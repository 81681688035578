import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../user-sidenav-service/user-sidenav.service';

@Component({
    selector: 'app-license-info',
    templateUrl: './license-info.component.html',
    styleUrls: ['./license-info.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LicenseInfoComponent {
    @Input() smsInfo;
    localExtraUsage: number;
    internationalLimit: number;
    internationalUsage: number;
    internationalExtraUsage: number;
    type: string;
    name: string;

    constructor(private userService: UserService, private focusMonitor: FocusMonitor) { }

    ngAfterContentChecked() {
        if (this.smsInfo) {
            this.internationalLimit = this.smsInfo.license.smsStandardLimit.international.credits;
            this.localExtraUsage = this.smsInfo.license.smsPackets.find(x => x.type === 1 && x.creditZone.zone === 0)?.creditZone.credits ?? 0;
            this.internationalUsage = this.smsInfo.license.smsPackets.find(x => x.type === 0 && x.creditZone.zone === 1)?.creditZone.credits ?? 0;
            this.internationalExtraUsage = this.smsInfo.license.smsPackets.find(x => x.type === 1 && x.creditZone.zone === 1)?.creditZone.credits ?? 0;
            this.type = this.smsInfo.license.type;
            this.name = this.smsInfo.license.name;
        }
    }

    ngAfterViewInit() {
        this.focusMonitor.stopMonitoring(document.getElementById('close-button'));
    }

    close() {
        this.userService.closeSideNav();
    }

}