import {
    trigger,
    state,
    style,
    transition,
    animate,
    query,
    group,
    stagger,
    animateChild
} from "@angular/animations";

export const gateTableAnimation =
    trigger("gateTableAnimation", [
        transition("desktopMidWidth => desktopFullWidth", [
            group([
                query('.h-full-table-width', [
                    style({
                        'width': '*'
                    }),
                    animate('0.5s cubic-bezier(0.4, 0.0, 0.2, 1)',
                        style({
                            'width': '100%'
                        })
                    )
                ]),
                query('@smartGateShipmentsSummaryAnimation', [
                    animateChild()
                ])
            ])
        ]),
        transition("desktopFullWidth => desktopMidWidth", [
            group([
                query('.h-full-table-width', [
                    style({
                        'width': '100%'
                    }),
                    animate('0.5s cubic-bezier(0.4, 0.0, 0.2, 1)',
                        style({
                            'width': '*'
                        })
                    )
                ]),
                query('@smartGateShipmentsSummaryAnimation', [
                    animateChild()
                ])
            ])
        ]),
        transition("mobileFullWidth => mobileMidWidth", [
            group([
                query('.h-full-table-width', [
                    style({
                        'position': 'absolute',
                        'top': '0'
                    }),
                    animate('0.5s cubic-bezier(0.4, 0.0, 0.2, 1)',
                        style({
                            'position': 'absolute',
                            'top': '269px'
                        })
                    )
                ]),
                query('@smartGateShipmentsSummaryAnimation', [
                    animateChild()
                ])
            ])
        ]),
        transition("mobileMidWidth => mobileFullWidth", [
            group([
                query('.h-full-table-width', [
                    style({
                        'position': 'absolute',
                        'top': '269px'
                    }),
                    animate('0.5s cubic-bezier(0.4, 0.0, 0.2, 1)',
                        style({
                            'position': 'absolute',
                            'top': '0'
                        })
                    )
                ]),
                query('@smartGateShipmentsSummaryAnimation', [
                    animateChild()
                ])
            ])
        ])
    ]);
export const smartGateShipmentsSummaryAnimation =
    trigger("smartGateShipmentsSummaryAnimation", [,
        transition(":enter", [
            style({
                'position': 'absolute',
                'top': '0',
                'right': '500px',
                'opacity': '0'
            }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)",
                style({
                    'opacity': '1',
                    'right': '0'
                }))
        ]),
        transition(":leave", [
            style({
                'position': 'absolute',
                'top': '0',
                'opacity': '1'
            }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)",
                style({
                    'position': 'absolute',
                    'right': '500px',
                    'opacity': '0'
                }))
        ])
    ]);
export const smartGateViewMenuAnimation =
    trigger("smartGateViewMenuAnimation", [
        transition(":enter", [
            style({ 'opacity': '0' }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)", style({ 'opacity': '1' }))
        ]),
        transition(":leave", [
            style({ 'opacity': '1' }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)", style({ 'opacity': '0' }))
        ])
    ]
    );

export const cardsExpandedBodyAnimation =
    trigger('cardsExpandedBodyAnimation', [
        state('collapsed',
            style({
                'height': '0',
                'opacity': '0',
                'padding': '0 20px',
                'overflow': 'hidden'
            })
        ),
        state('expanded',
            style({
                'height': '*',
                'opacity': '1',
                'border-top-left-radius': '0',
                'border-top-right-radius': '0',
                'overflow': 'hidden'
            })
        ),
        transition('expanded <=> collapsed',
            animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')
        )
    ]);
export const backDropAnimation =
    trigger("backDropAnimation", [
        transition(":enter", [
            style({ 'opacity': '0' }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)", style({ 'opacity': '1' }))
        ]),
        transition(":leave", [
            style({ 'opacity': '1' }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)", style({ 'opacity': '0' }))
        ])
    ]
    );
export const animateText =
    trigger("animateText", [
        transition(":enter", [
            style({ 'opacity': '0', 'overflow': 'hidden' }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)", style({ 'opacity': '1' }))
        ]),
        transition(":leave", [
            style({ 'opacity': '1', 'overflow': 'hidden' }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)", style({ 'opacity': '0' }))
        ])
    ]);
export const animateVersionText =
    trigger("animateVersionText", [
        transition(":enter", [
            style({
                'position': 'absolute',
                'top': '50%',
                'left': '-50px',
                'transform': 'translateY(-50%)',
                'opacity': '0'
            }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)", style({
                'opacity': '1',
                'left': '0'
            }))
        ]),
        transition(":leave", [
            style({
                'opacity': '1',
                'position': 'absolute',
                'left': '0',
            }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)", style({
                'opacity': '0',
                'left': '-50px'
            }))
        ])
    ]
    );

export const animateSlideDown =
    trigger("animateSlideDown",
        [
            transition(":enter",
                [
                    style({
                        'height': '0',
                        'overflow': 'hidden',
                        'opacity': '0'
                    }),
                    animate("1s cubic-bezier(0.4, 0.0, 0.2, 1)",
                        style({
                            'height': '*',
                            'overflow': 'hidden',
                            'opacity': '1'
                        })
                    )
                ]),
            transition(":leave",
                [
                    style({
                        'height': '*',
                        'overflow': 'hidden',
                        'opacity': '1'
                    }),
                    animate("1s cubic-bezier(0.4, 0.0, 0.2, 1)",
                        style({
                            'height': '0',
                            'overflow': 'hidden',
                            'opacity': '0'
                        }))
                ])
        ]
    );

export const animateSlideDownWithMargin =
    trigger("animateSlideDownWithMargin",
        [
            transition(":enter",
                [
                    style({
                        'height': '0',
                        'overflow': 'hidden',
                        'opacity': '0',
                        'margin-bottom': '0'
                    }),
                    animate("1s cubic-bezier(0.4, 0.0, 0.2, 1)",
                        style({
                            'height': '*',
                            'overflow': 'hidden',
                            'opacity': '1',
                            'margin-bottom': '*'
                        })
                    )
                ]),
            transition(":leave",
                [
                    style({
                        'height': '*',
                        'overflow': 'hidden',
                        'opacity': '1',
                        'margin-bottom': '*'
                    }),
                    animate("1s cubic-bezier(0.4, 0.0, 0.2, 1)",
                        style({
                            'height': '0',
                            'overflow': 'hidden',
                            'opacity': '0',
                            'margin-bottom': '0'
                        }))
                ])
        ]
    );

export const animateSlideDownWithMarginAndScroll =
    trigger("animateSlideDownWithMarginAndScroll",
        [
            transition(":enter",
                [
                    style({
                        'max-height': '0',
                        'overflow-x': 'hidden',
                        'opacity': '0',
                        'margin-bottom': '0'
                    }),
                    animate("1s cubic-bezier(0.4, 0.0, 0.2, 1)",
                        style({
                            'max-height': '*',
                            'overflow-x': 'hidden',
                            'opacity': '1',
                            'margin-bottom': '*'
                        })
                    )
                ]),
            transition(":leave",
                [
                    style({
                        'max-height': '*',
                        'overflow-x': 'hidden',
                        'opacity': '1',
                        'margin-bottom': '*'
                    }),
                    animate("1s cubic-bezier(0.4, 0.0, 0.2, 1)",
                        style({
                            'max-height': '0',
                            'overflow-x': 'hidden',
                            'opacity': '0',
                            'margin-bottom': '0'
                        }))
                ])
        ]
    );

export const slideDownAnimation = [
    trigger("slideDownAnimation", [
        state(
            "in",
            style({
                "max-height": "600px",
                opacity: "1",
                visibility: "visible",
            })
        ),
        state(
            "out",
            style({
                "max-height": "0px",
                opacity: "0",
                visibility: "hidden",
            })
        ),
        transition("in => out", [
            group([
                animate(
                    "400ms ease-in-out",
                    style({
                        opacity: "0",
                    })
                ),
                animate(
                    "600ms ease-in-out",
                    style({
                        "max-height": "0px",
                    })
                ),
                animate(
                    "700ms ease-in-out",
                    style({
                        visibility: "hidden",
                    })
                ),
            ]),
        ]),
        transition("out => in", [
            group([
                animate(
                    "1ms ease-in-out",
                    style({
                        visibility: "visible",
                    })
                ),
                animate(
                    "600ms ease-in-out",
                    style({
                        "max-height": "600px",
                    })
                ),
                animate(
                    "800ms ease-in-out",
                    style({
                        opacity: "1",
                    })
                ),
            ]),
        ]),
    ]),
];

export const fadeInOut = [
    trigger("fadeInOut", [
        transition(":enter", [
            style({
                'opacity': '0'
            }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)",
                style({
                    'opacity': '1',
                })
            ),
        ]),
        transition(":leave", [
            style({
                'opacity': '1'
            }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)",
                style({
                    'opacity': '0',
                })
            ),
        ]),
    ]),
];

export const slideRightCogAnimation = [
    trigger("slideRightCogAnimation", [
        state(
            "in",
            style({
                "min-height": "70px",
                opacity: "1",
                visibility: "visible",
                background: "#FAFAFA",
            })
        ),
        state(
            "out",
            style({
                "min-height": "70px",
                opacity: "0",
                visibility: "hidden",
            })
        ),
        transition("in => out", [
            group([
                animate(
                    "400ms ease-in-out",
                    style({
                        opacity: "0",
                    })
                ),
                animate(
                    "400ms ease-in-out",
                    style({
                        "max-height": "0px",
                    })
                ),
                animate(
                    "700ms ease-in-out",
                    style({
                        visibility: "hidden",
                    })
                ),
            ]),
        ]),
        transition("out => in", [
            group([
                animate(
                    "1ms ease-in-out",
                    style({
                        visibility: "visible",
                    })
                ),
                animate(
                    "400ms ease-in-out",
                    style({
                        "max-height": "500px",
                    })
                ),
                animate(
                    "400ms ease-in-out",
                    style({
                        opacity: "1",
                    })
                ),
            ]),
        ]),
    ]),
];

export const listStagger = [
    trigger("listStagger", [
        transition("* <=> *", [
            query(
                ":enter",
                [
                    style({ opacity: 0, transform: "translateY(-15px)" }),
                    stagger(
                        "50ms",
                        animate(
                            "550ms ease-out",
                            style({ opacity: 1, transform: "translateY(0px)" })
                        )
                    ),
                ],
                { optional: true }
            ),
            query(":leave", animate("50ms", style({ opacity: 0 })), {
                optional: true,
            }),
        ]),
    ]),
];

export const fadeInStagger = trigger("fadeIn", [
    transition(":enter", [
        query(".item-class", [style({ opacity: "0" })]),
        query(".item-class", [stagger(100, animate(400, style({ opacity: 1 })))]),
    ]),
]);

export const slideInOutAnimation = trigger("slideInOutAnimation", [
    state(
        "*",
        style({
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
        })
    ),

    transition(":enter", [
        style({
            right: "-400%",
            backgroundColor: "rgba(0, 0, 0, 0)",
        }),
        animate(
            ".5s ease-in-out",
            style({
                right: 0,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
            })
        ),
    ]),

    transition(":leave", [
        animate(
            ".5s ease-in-out",
            style({
                right: "-400%",
                backgroundColor: "rgba(0, 0, 0, 0)",
            })
        ),
    ]),
]);

export const toggle = trigger("toggle", [
    state("true", style({ opacity: 1 })),
    state("void", style({ opacity: 0 })),
    transition(":enter", animate("250ms ease-in-out")),
]);
export const toggleSubMenu =
    trigger("toggleSubMenu", [
        transition(":enter", [
            style({
                'opacity': '0',
                'height': '0',
                'overflow': 'hidden'
            }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)",
                style({
                    'opacity': '1',
                    'height': '*',
                })
            )
        ]),
        transition(":leave", [
            style({
                'opacity': '1',
                'height': '*',
                'overflow': 'hidden'
            }),
            animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)",
                style({
                    'opacity': '0',
                    'height': '0px',
                })
            )
        ])
    ]
    );

export const rotatedState = trigger("rotatedState", [
    state("default", style({ transform: "rotate(0)" })),
    state("rotated", style({ transform: "rotate(180deg)" })),
    transition("rotated => default", animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)")),
    transition("default => rotated", animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)")),
]);

export const fadeTableRow = trigger("fade", [
    state("in", style({ opacity: 1 })),
    transition(":enter", [style({ opacity: 0 }), animate(600)]),
    transition(":leave", animate(600, style({ opacity: 0 }))),
]);

export const rowExpansionTrigger = trigger("rowExpansionTrigger", [
    state(
        "void",
        style({
            transform: "translateX(-10%)",
            opacity: 0,
        })
    ),
    state(
        "active",
        style({
            transform: "translateX(0)",
            opacity: 1,
        })
    ),
    transition("* <=> *", animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")),
]);

export const slideAnimation = trigger('slideAnimation',
    [
        transition(':increment', group([
            query(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('300ms ease-out', style({ transform: 'translateX(0%)', visibility: 'hidden' })),
            ])
        ]))
        ,
        transition(':decrement', group([
            query(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('300ms ease-out', style({ transform: 'translateX(0%)', visibility: 'hidden' })),
            ])
        ]))
    ]);

export const animateTextCentering =
    trigger("animateTextCentering",
        [
            state('collapsed',
                style({
                    'transform': 'translateY(0)'
                })
            ),
            state('expanded',
                style({
                    'transform': 'translateY(100%)'
                })
            ),
            transition('expanded <=> collapsed',
                animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            )
        ]
    );

export const cameIn = trigger('cameIn', [
    transition(':enter', [
        style({
            'transform': 'scale(0)'
        }),
        animate('250ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
            'transform': 'scale(1)'
        }))
    ])
]);

export const cameOut = trigger('cameOut', [
    transition(':leave', [
        animate('250ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
            'transform': 'scale(0)'
        }))
    ])
]);


// export const rotatedState =
//     trigger('rotatedState', [
//         state('default', style({ transform: 'rotate(0)' })),
//         state('rotated', style({ transform: 'rotate(-180deg)' })),
//         transition('rotated => default', animate('250ms ease-out')),
//         transition('default => rotated', animate('250ms ease-in'))
//     ]);

// export const fadeTableRow =
//     trigger('fade', [
//         state('in', style({ opacity: 1 })),
//         transition(':enter', [style({ opacity: 0 }), animate(600)]),
//         transition(':leave', animate(600, style({ opacity: 0 })))
//     ]);