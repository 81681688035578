import { Component, Input} from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';

@Component({
    selector: 'error-control',
    templateUrl: './error-control.component.html',
    styleUrls: ['./error-control.component.scss'],
})
export class ErrorControlComponent {

    @Input() control: string;

    @Input() visible: any;

    constructor(private controlContainer: ControlContainer) { }

    get form(): FormGroup {
        return this.controlContainer.control as FormGroup;
    }

    get formControl(): AbstractControl {
        return this.form.get(this.control) as AbstractControl;
    }

    get isNotValid() {
        return this.formControl.invalid && (this.formControl.touched || this.formControl.dirty);
    }
}