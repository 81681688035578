<mat-divider *ngIf="userType !== '4' && !userDefaults && !userProfile"></mat-divider>
<div *ngIf="userType !== '4' && !userProfile" class="drawer-content-buttons">
    <button mat-button class="drawer-list-button" type="button" (click)="openOption('UserSettings')">
        <fa-icon class="fa-icon" [icon]="['fal','user-cog']"></fa-icon>
        <span class="ml-2 drawer-list-button-text text-uppercase">
            {{ 'user-profile.view.defaultSettings' | translate }}
        </span>
        <fa-icon *ngIf="userDefaults" class="fa-icon back-icon float-right" [icon]="['fal','arrow-circle-left']"
                 size="2x"></fa-icon>
    </button>
</div>
<mat-divider *ngIf="!userDefaults && !userProfile"></mat-divider>
<div class="drawer-content-buttons" *ngIf="!userDefaults">
    <button mat-button class="drawer-list-button" type="button" (click)="openOption('UserProfile')">
        <fa-icon class="fa-icon" [icon]="['fal','user']"></fa-icon>
        <span class="ml-2 drawer-list-button-text text-uppercase">
            {{ 'menu.myProfile' | translate }}
        </span>
        <fa-icon *ngIf="userProfile" class="fa-icon back-icon float-right" [icon]="['fal','arrow-circle-left']"
                 size="2x"></fa-icon>
    </button>
</div>
<mat-divider *ngIf="!userDefaults && !userProfile && userType !== '4'"></mat-divider>
<div class="drawer-user-settings-content" *ngIf="userDefaults && userDefaultsForm" @animateSlideDownWithMargin>
    <form [formGroup]="userDefaultsForm">
        <div class="row">
            <div class="col-md-12" *ngIf="systemType === '0' || systemType === '2'">
                <mat-form-field>
                    <mat-label>{{ 'user-profile.view.building' | translate }}</mat-label>
                    <mat-select formControlName="DefaultBuilding" (selectionChange)="onBuildingChange($event)" required
                                [compareWith]="compareIdNamePairs">
                        <mat-option [value]="building" *ngFor="let building of availableBuildings">
                            {{ building.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <error-control control="DefaultBuilding"></error-control>
            </div>
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label *ngIf="systemType === '0' || systemType === '2'">
                        {{ 'user-profile.view.reception' | translate }}
                    </mat-label>
                    <mat-label *ngIf="systemType === '1' || systemType === '3'">
                        {{ 'user-profile.view.securityPost' | translate }}
                    </mat-label>
                    <mat-select formControlName="DefaultReception" required [compareWith]="compareIdNamePairs">
                        <mat-option [value]="reception" *ngFor="let reception of availableReceptions">
                            {{ reception.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <error-control control="DefaultReception"></error-control>
            </div>
            <div class="col-md-12" *ngIf="!ignoreParking && (systemType === '0' || systemType === '2')">
                <mat-form-field>
                    <mat-label>{{ 'user-profile.view.parking' | translate }}</mat-label>
                    <mat-select formControlName="DefaultParking" [compareWith]="compareIdNamePairs">
                        <mat-option [value]="parking" *ngFor="let parking of availableParkings">
                            {{ parking.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <div class="dialog-bottom-content float-right">
                    <button class="c-submit-button loading-btn" type="submit" (click)="onSubmit()">
                        <fa-icon class="c-submit-button__fa-icon" [icon]="['fas','save']"></fa-icon>
                        <span class="c-submit-button__text">
                            {{ 'shared.save' | translate }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="drawer-user-settings-content" *ngIf="userProfile" @animateSlideDownWithMargin>
    <form [formGroup]="userProfileForm">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput placeholder="{{ 'user-profile.dialog.view.firstName' | translate }}" type="text"
                           formControlName="FirstName" required>
                </mat-form-field>
                <error-control control="FirstName"></error-control>
            </div>
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput placeholder="{{ 'user-profile.dialog.view.lastName' | translate }}" type="text"
                           formControlName="LastName" required>
                </mat-form-field>
                <error-control control="LastName"></error-control>
            </div>
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label
                            class="invitation-phone-input">{{ 'user-profile.dialog.view.phone' | translate }}</mat-label>
                    <input appCustomIntlTelInput matInput formControlName="Phone" min="0" maxlength="20"
                           [initialisationOptions]="{ initialCountry: initialCountry }"
                           (countryChange)="countryChange($event)" class="custom-intl-tel-input"/>
                </mat-form-field>
                <error-control control="Phone"></error-control>
            </div>
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput placeholder="{{ 'user-profile.dialog.view.email' | translate }}" type="email"
                           formControlName="Email" required>
                </mat-form-field>
                <error-control control="Email"></error-control>
            </div>
            <div class="col-md-12">
                <app-countries #Countries controlName="Country" [controlValue]="userCountryName"
                               label="{{ 'user-profile.dialog.view.country' | translate }}"
                               [control]="userProfileForm.controls.Country" required></app-countries>
                <error-control control="Country"></error-control>
            </div>
            <div class="col-12">
                <div class="dialog-bottom-content float-right">
                    <button class="c-submit-button loading-btn" [class.spinner]="loading" [disabled]="loading"
                            type="submit" (click)="submitUserProfile()">
                        <fa-icon class="c-submit-button__fa-icon" [icon]="['fas','plus']"></fa-icon>
                        <span class="c-submit-button__text">
                            {{ 'shared.save' | translate }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="drawer-content-buttons" *ngIf="!userDefaults && userType !== '4' && !userProfile">
    <button mat-button class="drawer-list-button" type="button" (click)="openOption('UserNotifications')">
        <fa-icon class="fa-icon" [icon]="['fal','bell']"></fa-icon>
        <span class="ml-2 drawer-list-button-text text-uppercase">
            {{ 'notifications.view.header' | translate }}
        </span>
    </button>
    <br/>
    <ul *ngIf="userNotifications" @animateSlideDownWithMargin>
        <li>
            <span class="notifications-content">
                {{ 'notifications.view.notificationsType.web' | translate }}
            </span>
            <mat-slide-toggle (change)="setWebPush($event)" [disabled]="loadingWebPush" [checked]="currentWebPushState"
                              class="toggle-content" #webNotifications>
                {{webNotifications.checked ? ('notifications.view.pushOn' | translate) : ('notifications.view.pushOff' | translate)}}
            </mat-slide-toggle>
            <span [ngClass]="{'spinner': loadingWebPush}"></span>
            <span class="blocked-notifications" *ngIf="blockedNotificationsByUser">
                {{ 'notifications.view.notificationsBlocked' | translate }}
            </span>
        </li>
        <li *ngIf="systemType === '0' || systemType === '2'">
            <span class="notifications-content">
                {{ 'notifications.view.notificationsType.sms' | translate }}
            </span>
            <mat-slide-toggle (change)="setSmsOrMobileAppNotifications($event)"
                              [disabled]="!isMobileApplicationInstalled" [checked]="!isPushEnabled"
                              matTooltip="{{!isMobileApplicationInstalled ? ('notifications.view.siccoAppWasNotFound' | translate) : ''}}"
                              class="toggle-content" #smsNotifications>
                {{smsNotifications.checked ? ('notifications.view.pushOn' | translate) : ('notifications.view.pushOff' | translate)}}
            </mat-slide-toggle>
        </li>
        <li *ngIf="systemType === '0' || systemType === '2'">
            <button mat-button class="notifications-content" (click)="getUserMobileDevices()"
                    matTooltip="{{ 'notifications.view.showDevices' | translate }}"
                    [ngStyle]="!isMobileApplicationInstalled && {'pointer-events': 'none'}">
                {{ 'notifications.view.notificationsType.siccoApp' | translate }}
                <fa-icon class="ml-2 fa-icon" *ngIf="isMobileApplicationInstalled && !userDevices"
                         [icon]="['fas','chevron-down']" size="sm"></fa-icon>
                <fa-icon class="ml-2 fa-icon" *ngIf="userDevices" [icon]="['fas','chevron-up']" size="sm"></fa-icon>
            </button>
            <mat-slide-toggle class="toggle-content" (change)="setSmsOrMobileAppNotifications($event)"
                              [disabled]="!isMobileApplicationInstalled" [checked]="isPushEnabled"
                              matTooltip="{{!isMobileApplicationInstalled ? ('notifications.view.siccoAppWasNotFound' | translate) : ''}}"
                              #mobileNotifications>
                {{mobileNotifications.checked ? ('notifications.view.pushOn' | translate) : ('notifications.view.pushOff' | translate)}}
            </mat-slide-toggle>
            <ng-container *ngIf="userDevices">
                <label>
                    <fa-icon [icon]="['fal','mobile']" class="mr-2"></fa-icon>
                    {{ 'notifications.view.myDevices' | translate }}
                </label>
                <ul>
                    <li *ngFor="let device of userDevices" class="mb-1">
                        <app-user-settings-device-info [device]="device"></app-user-settings-device-info>
                    </li>
                </ul>
            </ng-container>
        </li>
    </ul>
</div>
<ng-container *ngIf="!userDefaults && !userProfile">
    <mat-divider></mat-divider>
    <div class="drawer-content-buttons">
        <button mat-button class="drawer-list-button" type="button" (click)="openOption('SwitchLanguage')">
            <fa-icon class="fa-icon" [icon]="['fal','globe-americas']"></fa-icon>
            <span class="ml-2 drawer-list-button-text text-uppercase">
                {{ 'menu.langChange' | translate }}:
            </span>
            <span class="drawer-list-button-text text-uppercase">
                {{ selectedSystemLanguage }}
            </span>
            <fa-icon class="ml-2 fa-icon d-inline-flex" [icon]="['fas','chevron-down']" size="sm"
                     [ngClass]="availableLanguages? 'h-rotateX--180deg': 'h-rotateX--0deg'"></fa-icon>
        </button>
        <div class="drawer-language-content" *ngIf="availableLanguages" @animateSlideDownWithMargin>
            <a (click)="onSelectLanguage('pl-PL')">
                {{ 'shared.polish' | translate }}
            </a>
            <a (click)="onSelectLanguage('en-GB')">
                {{ 'shared.english' | translate }}
            </a>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="availableInstances && availableInstances.length > 1 && !userDefaults && !userProfile">
    <mat-divider *ngIf="!userDefaults"></mat-divider>
    <div class="drawer-content-buttons" *ngIf="!userDefaults">
        <button mat-button class="drawer-list-button" type="button" (click)="openOption('SwitchInstance')">
            <fa-icon class="fa-icon" [icon]="['fal','server']"></fa-icon>
            <span class="ml-2 drawer-list-button-text text-uppercase">
                {{ 'menu.instance' | translate }}:
            </span>
            <span class="drawer-list-button-text text-uppercase">
                {{ selectedInstance }}
            </span>
            <fa-icon class="ml-2 fa-icon d-inline-flex" [icon]="['fas','chevron-down']" size="sm" [ngClass]="instanceSettings? 'h-rotateX--180deg': 'h-rotateX--0deg'"></fa-icon>
        </button>
        <div class="drawer-language-content" *ngIf="instanceSettings" @animateSlideDownWithMargin>
            <a *ngFor="let instance of availableInstances" (click)="onInstanceChange(instance.id)">
                {{instance.name}}
            </a>
        </div>
    </div>
</ng-container>
<mat-divider></mat-divider>
<ng-container *ngIf="!userDefaults && !userProfile && userType !== '4'">
    <app-mobile-info [showQrCode]="showQrCode"></app-mobile-info>
</ng-container>