import { Component, OnDestroy, Input, ViewChild, SkipSelf, Output, EventEmitter, OnInit } from '@angular/core';
import { CountryService } from "./countries.service";
import { ControlContainer, FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { ICountry } from "../../interfaces/country.interface";
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-countries',
    templateUrl: './countries.component.html',
    styleUrls: ['./countries.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]]
        }
    ],
    providers: [CountryService]
})

export class CountriesComponent implements OnInit, OnDestroy {
    @Input() controlName: string;
    @Input() controlValue: string;
    @Input() label: string;
    @Input() required: boolean;
    @Input() control: FormControl<any>;
    @Output() onCountrySelected = new EventEmitter<any>();
    @ViewChild('countrySelect', { static: true }) countrySelect: MatSelect;
    countries: ICountry[];
    selectedCountry: ICountry;
    countryFilterCtrl: FormControl<any> = new FormControl<any>(null);
    filteredCountries: ReplaySubject<ICountry[]> = new ReplaySubject<ICountry[]>(1);
    protected _onDestroy = new Subject<void>();

    constructor(private countryService: CountryService) { }

    ngOnInit() {
        this.countryService.getCountries().then(countries => {
            this.countries = countries;
            this.handleValueChange(this.controlValue);
            this.filteredCountries.next(this.countries.slice());
            this.countryFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterCountries();
                });

            this.setInitialValue();
        });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onOptionsSelected($event: any) {
        this.handleValueChange($event.value.name);
    }

    handleValueChange(value) {
        if (value) {
            let valueToChange: ICountry = this.countries.find(x => x.name === value);
            this.onCountrySelected.emit(valueToChange);
            this.control.setValue(valueToChange);
            this.selectedCountry = valueToChange;
        }
    }

    protected setInitialValue() {
        this.filteredCountries
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.countrySelect.compareWith = (a: ICountry, b: ICountry) => a && b && a.code === b.code;
            });
    }

    protected filterCountries() {
        if (!this.countries) {
            return;
        }
        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountries.next(this.countries.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredCountries.next(
            this.countries.filter(country => country.name.toLowerCase().indexOf(search) > -1)
        );
    }
}