import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { HttpHeaders } from "@angular/common/http";
import { IInvitationSecuritOfficer } from 'src/app/shared/interfaces/invitation-security-officer.interface';
import { SortOrder } from "../../shared/enums/sort-order.enum";

const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

@Injectable()
export class SecurityOfficerService {
    editTransitInvitation: Subject<any> = new Subject();
    createTransitFromUnknown: Subject<any> = new Subject();
    showLastParkingPhotos: Subject<any> = new Subject();
    submitNewInvitation: Subject<any> = new Subject();
    createTransitInvitation: Subject<any> = new Subject();
    public editLongTermInvitation: Subject<any> = new Subject();
    private refreshNeeded = new Subject<void>();
    private baseUrl: string;
    private elementCount: number = 10;

    get refreshNeeded$() {
        return this.refreshNeeded;
    }

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getElementCount(): number {
        return this.elementCount;
    }

    setElementCount(value: number) {
        this.elementCount = value;
    }

    getBuildingsForUser() {
        return this.httpClient.get(this.baseUrl + 'api/notification/GetBuildings');
    }

    getAlarmsList() {
        return this.httpClient.get(this.baseUrl + 'api/notification/GetAlarmsList?language=' + localStorage.getItem("defaultLanguage"));
    }

    getParkingInvitations(parkingIds, companyIds, parkingReservationStatuses, invitationTypes) {
        let filterLink = this.prepareLinkForFilters(parkingIds, companyIds, parkingReservationStatuses, invitationTypes);
        return this.httpClient.get(this.baseUrl + 'api/securityOfficerAccess/GetAllParkingInvitations?' + filterLink);
    }

    getLiveParkingInvitations(parkingIds, companyIds, parkingReservationStatuses, invitationTypes, pageSize, sortName, sortOrder: SortOrder) {
        let filterLink = this.prepareLinkForFilters(parkingIds, companyIds, parkingReservationStatuses, invitationTypes);
        return this.httpClient.get(this.baseUrl + 'api/securityOfficerAccess/LiveParkingInvitations?pageSize=' + pageSize + '&sortName=' + sortName + '&sortOrder=' + sortOrder + filterLink);
    }

    getAlarmInfo(buildingId: string, purpose: string, languageCode: string) {
        return this.httpClient.get(this.baseUrl + 'api/notification/AlarmInfo?buildingId=' + buildingId + '&purpose=' + purpose + '&languageCode=' + languageCode);
    }

    sendAlarm(notificationPurpose: string, buildingId: string) {
        return this.httpClient.get(
            this.baseUrl +
            'api/notification/SendSmsNotificationsForOnGoingInvitations?notificationPurpose=' +
            notificationPurpose +
            '&buildingId=' +
            buildingId,
            { headers: headers, observe: "response" });
    }

    getAllInvitations() {
        return this.httpClient.get(this.baseUrl + 'api/securityOfficerAccess/Invitations');
    }

    verifyPin(pinCode: string): Observable<IInvitationSecuritOfficer[]> {
        return this.httpClient.get<IInvitationSecuritOfficer[]>(this.baseUrl + 'api/securityOfficerAccess/VerifyPin?pinCode=' + pinCode);
    }

    updateInvitations(invitations: any) {
        return this.httpClient.post(this.baseUrl + 'api/securityOfficerAccess/Invitation',
            JSON.stringify(invitations),
            {
                headers: headers,
                observe: 'response'
            })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    getAllCompanies() {
        return this.httpClient.get(this.baseUrl + 'api/securityOfficerAccess/Companies');
    }

    getAllParkings() {
        return this.httpClient.get(this.baseUrl + 'api/securityOfficerAccess/Parkings');
    }

    getLatestParkingPhotos(id, isUnknownVehicleActivity) {
        return this.httpClient.get(this.baseUrl + 'api/securityOfficerAccess/LatestParkingPhotos?activityId=' + id + '&isUnknownVehicleActivity=' + isUnknownVehicleActivity);
    }

    private prepareLinkForFilters(parkingIds, companyIds, parkingReservationStatuses, invitationTypes) {
        let parkingReservationStatusesUrl = "";
        let invitationTypesUrl = "";
        let parkingsUrl = "";
        let companyIdsUrl = "";
        parkingReservationStatuses.forEach(x => {
            parkingReservationStatusesUrl += '&parkingReservationStatuses=' + x;
        });
        invitationTypes.forEach(x => {
            invitationTypesUrl += '&invitationTypes=' + x;
        });
        companyIds.forEach(x => {
            companyIdsUrl += '&companyIds=' + x;
        });
        parkingIds.forEach(x => {
            parkingsUrl += '&parkingIds=' + x;
        });

        return parkingReservationStatusesUrl + invitationTypesUrl + parkingsUrl + companyIdsUrl;
    }
}