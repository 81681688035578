import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ReceptionsService {
    openEdit: Subject<any> = new Subject();
    areDetailsShown: BehaviorSubject<boolean> = new BehaviorSubject(true);
    areHoursShown: BehaviorSubject<boolean> = new BehaviorSubject(true);
    areContactsShown: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private refreshNeededReception = new Subject<void>();
    private baseUrl: string;

    get refreshNeededReception$() {
        return this.refreshNeededReception;
    }

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getReceptions() {
        return this.httpClient.get(this.baseUrl + 'api/Reception/GetAll');
    }

    getBuildings() {
        return this.httpClient.get(this.baseUrl + 'api/Building/List');
    }

    getCompanies() {
        return this.httpClient.get(this.baseUrl + 'api/Company/ListIdNamePairs');
    }

    updateReception(receptionForm: any) {
        return this.httpClient.put(this.baseUrl + 'api/Reception/Update',
            receptionForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededReception.next();
                })
            );
    }

    createReception(receptionForm: any): Observable<HttpResponse<any>> {
        return this.httpClient.post(this.baseUrl + 'api/Reception/Create',
            receptionForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededReception.next();
                })
            );
    }

    removeReception(receptionId: string) {
        return this.httpClient.delete(this.baseUrl + 'api/Reception/Remove?receptionId=' + receptionId)
            .pipe(
                tap(() => {
                    this.refreshNeededReception.next();
                })
            );
    }
}