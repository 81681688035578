import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class ServiceInvitationService {
    private baseUrl: string;

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getListParkingsByInstance() {
        return this.httpClient.get(this.baseUrl + 'api/SecurityOfficerAccess/Parkings');
    }

    createTransitInvitation(serviceInvitationCreate) {
        return this.httpClient.post(this.baseUrl +
            'api/Invitation/CreateTransitInvitation', serviceInvitationCreate, { observe: 'response' });
    }

    editTransitInvitation(transitInvitation) {
        return this.httpClient.post(this.baseUrl +
            'api/SecurityOfficerAccess/TransitInvitation', transitInvitation, { observe: 'response' });
    }

    getParkingDefaultTransitDays(parkingId) {
        return this.httpClient.get<number>(this.baseUrl + 'api/Parking/ParkingTransitDays?parkingId=' + parkingId);
    }
}
