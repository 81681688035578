import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalizedDatePipe } from "./helpers/localizeddate.pipe";
import { QuicklinkModule } from 'ngx-quicklink';
import { DisableControlDirective } from "./helpers/disablecontrol.directive";
import { EnumToArrayPipe } from "./helpers/enumarray.pipe";
import {
    OwlDateTimeModule,
    OwlDateTimeIntl,
    DateTimeAdapter,
    OWL_DATE_TIME_FORMATS
} from '@danielmoncada/angular-datetime-picker';
import { AppService } from '../app.service';
import { DefaultIntl } from './calendar-internalization/calendar-internalization';
import { AutomaticScroll } from "./automatic-scroll/automatic-scroll";
import { CustomAlphaNumericSort, InvitationStatusSort } from "./helpers/custom-sorts";
import { CustomIntlTelInputDirective } from './directives/custom-intl-tel-input.directive';
import { GalleryCustomDescriptionDirective } from './components/gallery/gallery-custom-description.directive';
import { AppIconModule } from "../app-icon-module";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { OwlMomentDateTimeModule } from "@danielmoncada/angular-datetime-picker-moment-adapter";
import { IdNamePipe } from "./helpers/idname.pipe";
import { DateToTimePipe } from "./helpers/dateToTime.pipe";
import { TimeToDatePipe } from "./helpers/timeToDate.pipe";
import { ResponseToIdNameArrayPipe } from "./helpers/responseToIdNameArray.pipe";
import { TranslationTextPipe } from "./helpers/translationText.pipe";
import { IndefiniteDatePipe } from "./helpers/indefiniteDate.pipe";

export const MY_MOMENT_FORMATS = {
    parseInput: 'l LT',
    fullPickerInput: 'l LT',
    datePickerInput: 'l',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        QuicklinkModule,
        OwlDateTimeModule,
        OwlMomentDateTimeModule,
        AppIconModule
    ],
    providers: [
        {provide: OwlDateTimeIntl, useClass: DefaultIntl},
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
        AutomaticScroll,
        CustomAlphaNumericSort,
        InvitationStatusSort
    ],

    declarations: [
        LocalizedDatePipe,
        DisableControlDirective,
        EnumToArrayPipe,
        CustomIntlTelInputDirective,
        GalleryCustomDescriptionDirective,
        GalleryComponent,
        IdNamePipe,
        DateToTimePipe,
        TimeToDatePipe,
        ResponseToIdNameArrayPipe,
        TranslationTextPipe,
        IndefiniteDatePipe
    ],
    exports: [
        CommonModule,
        TranslateModule,
        LocalizedDatePipe,
        QuicklinkModule,
        DisableControlDirective,
        EnumToArrayPipe,
        OwlDateTimeModule,
        CustomIntlTelInputDirective,
        GalleryComponent,
        IdNamePipe,
        DateToTimePipe,
        TimeToDatePipe,
        ResponseToIdNameArrayPipe,
        TranslationTextPipe,
        IndefiniteDatePipe
    ]
})
export class SharedModule {
    constructor(private dateAdapter: DateTimeAdapter<any>, private appService: AppService) {
        this.dateAdapter.setLocale(localStorage.getItem("defaultLanguage"));
        this.appService.getLangChangeEvent().subscribe(lang => {
            this.setDateAdapterLocale(lang);
        });
    }

    setDateAdapterLocale(localeString: string): void {
        this.dateAdapter.setLocale(localeString);
    }
}

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
