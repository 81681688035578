import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirmation-dialog',
    templateUrl: 'confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
    public message: string;
    public confirmButtonText: string;
    public cancelButtonText: string;
    public headerMessage: string;
    
    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private _dialogRef: MatDialogRef<ConfirmationDialogComponent, boolean | undefined>) {
        if (data) {
            this.message = data.message || this.message;
            this.headerMessage = data.headerMessage || this.headerMessage;
            if (data.buttonText) {
                this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
                this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
            }
        }
    }
}