<div class="mobile-info" *ngIf="portalType !== '1' && portalType !== '3' && !isMobileApplicationInstalled">
    <ng-container *ngIf="imageBlobUrl && showQrCode">
        <p class="qr-info">
            {{ 'mobile-app-info.view.qr-info' | translate }}
        </p>
        <img class="mb-2" height="220" [src]="imageBlobUrl">
    </ng-container>
    <p class="mobile-info-header">
        {{ 'mobile-app-info.view.download-info' | translate }}
    </p>
    <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/pl/app/sicco-mobile/id1535526770">
        <img width="115" height="35" src="/assets/img/app-store-badge.svg" alt="app-store bagde" />
    </a>
    <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=pl.sicco.siccomobileclient">
        <img width="115" height="36" src="/assets/img/google-play-badge.png" alt="google-play bagde" />
    </a>
    <p *ngIf="!imageBlobUrl" class="mobile-info-footer" (click)="generateQrCode()">
        {{ 'mobile-app-info.view.qr-generate' | translate }}
    </p>
</div>