import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { DateTimeAdapter } from "@danielmoncada/angular-datetime-picker";
import { SignalrService } from "../../signalr-service/signalr.service";
import { MessagingService } from "../../messaging/messaging.service";
import { DataBaseService } from "../../database-service/database-service";
import { CorrectlyLoggedService } from "../../correctlylogged-service/correctly-logged-service";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { AppService } from "../../../app.service";
import { CookieLangChanger } from "../../cookie/cookie.language.changer";
import { MatSnackBar } from '@angular/material/snack-bar';
import { animateSlideDownWithMargin } from "../../animations/animations";
import { Router } from '@angular/router';
import { UserService } from '../../user-sidenav-service/user-sidenav.service';
import { UsersService } from "../../../users/components/users.service";
import { CustomValidators } from "../../validator/custom.validator";
import { PhoneValidator } from "../../helpers/phone-validator";
import { IUserProfile } from "../../interfaces/userprofile.interface";
import { ICountry } from "../../interfaces/country.interface";
import { IIdNamePair } from "../../interfaces/idname.interface";
import { CountryData } from "intl-tel-input";
import { MatSelectChange } from "@angular/material/select";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { IUserInfo } from "../../interfaces/userinfo.interface";
import { IUserDefaults } from "../../interfaces/IUserDefaults.interface";
import { ResponseToIdNameArrayPipe } from "../../helpers/responseToIdNameArray.pipe";

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss', '../../../nav-menu/nav-menu.component.scss'],
    animations: [animateSlideDownWithMargin]
})
export class UserSettingsComponent extends PhoneValidator implements OnInit {
    @Output() hideHeaderParent: EventEmitter<boolean> = new EventEmitter();
    userProfileForm: FormGroup<IUserProfileForm>;
    userDefaultsForm: FormGroup<IUserDefaultsForm>;
    userType: string = localStorage.getItem("userType");
    selectedSystemLanguage: string;
    availableLanguages: boolean = false;
    userDefaults: boolean = false;
    userNotifications: boolean = false;
    userProfile: boolean = false;
    instanceSettings: boolean = false;
    systemType = localStorage.getItem("systemType");
    oldSystemType = localStorage.getItem("systemType");
    availableReceptions: IIdNamePair[] = [];
    availableParkings: IIdNamePair[] = [];
    availableBuildings: IIdNamePair[] = [];
    availableInstances: any;
    selectedInstance: string;
    ignoreParking: boolean;
    blockedNotificationsByUser: boolean;
    webPushSubscribed: boolean;
    currentWebPushState: boolean;
    loadingWebPush: boolean;
    signalRConnectionId: string;
    isMobileApplicationInstalled: boolean;
    isPushEnabled: boolean;
    userDevices: object;
    countryCode: string;
    initialCountry: string = 'pl';
    loading: boolean = false;
    showQrCode: boolean = true;
    userCountryName: string;
    private baseUrl: string;

    constructor(
        private router: Router,
        private appService: AppService,
        private cookieLangChanger: CookieLangChanger,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        public dateTimeAdapter: DateTimeAdapter<any>,
        private dataBaseService: DataBaseService,
        private correctlyLoggedService: CorrectlyLoggedService,
        private signalR: SignalrService,
        private userSidenavService: UserService,
        private usersService: UsersService,
        private messagingService: MessagingService,
        private responseToIdNameArrayPipe: ResponseToIdNameArrayPipe,
        @Inject('BASE_URL') baseUrl: string) {
        super();
        this.baseUrl = baseUrl;
    }

    ngOnInit() {
        if (this.userProfileForm) {
            this.userProfileForm.controls.Country.valueChanges.subscribe((value: ICountry) => {
                this.userCountryName = value.name;
            });
        }
        this.userSidenavService.openUserDefaults$.pipe(
            tap(() => {
                this.userDefaults = true;
                this.hideHeaderParent.emit(this.userDefaults);
            })).subscribe();
        this.userSidenavService.getDefaultsSettings$.pipe(
            tap(() => {
                this.getUserDefaults();
            })).subscribe();
        this.userSidenavService.getInstanceSettings$.pipe(
            tap(() => {
                this.getUserInstances();
            })).subscribe();
        this.messagingService.currentMessage$().subscribe(response => {
            if (response) {
                this.dataBaseService.setLanguageToBrowserDb(localStorage.getItem("defaultLanguage"), this.currentWebPushState);
            }
            else {
                this.setDisableWebPushMessages();
                this.blockedNotificationsByUser = true;
            }
        });
        this.correctlyLoggedService.correctlyLogged$().subscribe(response => {
            if (response) {
                this.checkAndInitWebPush();
                this.checkMobileStatuses();
            }
        });
        this.signalR.webPushResponse.subscribe(webPushResponse => {
            this.loadingWebPush = false;
            let webPushRequestResponse = webPushResponse['webPushRequestStatus'];
            if (webPushRequestResponse.instanceId !== localStorage.getItem('instanceId')) {
                return;
            }
            if (webPushRequestResponse.requestSuccessful) {
                if (webPushRequestResponse.action === 0) {
                    this.webPushSubscribed = true;
                }
                else {
                    this.setDisableWebPushMessages();
                    this.messagingService.deleteToken();
                }
            }
            else {
                if (webPushRequestResponse.action === 0) {
                    this.setDisableWebPushMessages();
                }
                else {
                    this.webPushSubscribed = true;
                }
            }
            this.modifyUserInfos('isWebPushEnabled', this.webPushSubscribed);
        });
        this.userSidenavService.aggregationTableSettings$.subscribe(response => {
            this.signalRConnectionId = response;
        });
    }

    ngAfterContentChecked() {
        this.selectedSystemLanguage = localStorage.getItem("defaultLanguage");
        this.systemType = localStorage.getItem("systemType");
    }

    ngAfterContentInit() {
        if (this.userSidenavService.availableInstances.value) {
            this.setAvailableInstances(this.userSidenavService.availableInstance$.value);
        }
    }

    setWebPush(e: MatSlideToggleChange) {
        if (e.checked) {
            this.messagingService.requestPermission();
            this.loadingWebPush = true;
        }
        else {
            this.messagingService.disableWebPushNotifications().subscribe(response => {
                if (response.status !== 200) {
                    e.source.checked = true;
                }
                else {
                    this.loadingWebPush = true;
                }
            });
        }
    }

    setSmsOrMobileAppNotifications(event: MatSlideToggleChange) {
        this.userSidenavService.setPushNotifications(event.checked).subscribe(response => {
                if (response.status === 200) {
                    this.isPushEnabled = !this.isPushEnabled;
                }
            },
            err => {
                this.snackBar.open(this.translate.instant('notifications.component.failedChangePushNotification'), this.translate.instant('shared.ok'), {
                    duration: 4000,
                    panelClass: ['sicco-snackbar'],
                });
            }
        );
    }

    handleChangeLanguage() {
        this.translate.use(this.selectedSystemLanguage);
        this.dateTimeAdapter.setLocale(this.selectedSystemLanguage);
        this.appService.sendLangChangeEvent(this.selectedSystemLanguage);
        localStorage.setItem("defaultLanguage", this.selectedSystemLanguage);
        this.cookieLangChanger.setCookieLanguage();
        this.userSidenavService.updateLanguage(this.selectedSystemLanguage).subscribe(resp => {
                if (resp.status === 204) {
                    this.snackBar.open(this.translate.instant('language-dialog.component.changeLanguage.success'), this.translate.instant('shared.ok'), {
                        duration: 4000,
                        panelClass: ['sicco-snackbar'],
                    });
                    this.userSidenavService.defaultUserLanguageChange.next(this.selectedSystemLanguage);
                    this.dataBaseService.setLanguageToBrowserDb(this.selectedSystemLanguage, this.currentWebPushState);
                    this.modifyUserInfos('language', this.selectedSystemLanguage);
                }
            },
            err => {
                this.snackBar.open(this.translate.instant('language-dialog.component.changeLanguage.failed'), this.translate.instant('shared.ok'), {
                    duration: 4000,
                    panelClass: ['sicco-snackbar'],
                });
            });
    }

    onSubmit() {
        this.userDefaultsForm.markAllAsTouched();
        if (this.userDefaultsForm.invalid) {
            return;
        }
        else {
            this.userSidenavService.createOrUpdateUserDefaults(this.userDefaultsForm.value).subscribe(result => {
                if (result) {
                    this.snackBar.open(this.translate.instant('user-profile.component.saveDefaults'), this.translate.instant('shared.ok'), {
                        duration: 4000,
                        panelClass: ['sicco-snackbar'],
                    });
                    this.userDefaults = false;
                    this.hideHeaderParent.emit(this.userDefaults);
                }
            });
        }
    }

    getUserMobileDevices() {
        this.userSidenavService.getUserMobileDevices().subscribe(result => {
            if (result) {
                this.userDevices = result
            }
        })
    }

    onSelectLanguage(language: string) {
        this.selectedSystemLanguage = language;
        this.handleChangeLanguage();
    }

    openOption(option: string) {
        switch (option) {
            case "UserSettings": {
                if (this.userDefaults === false) {
                    this.getUserDefaults();
                    this.userDefaults = this.userDefaults === false;
                    this.hideHeaderParent.emit(this.userDefaults);
                    this.showQrCode = false;
                }
                else {
                    this.userDefaults = false;
                    this.hideHeaderParent.emit(this.userDefaults);
                }
                break;
            }
            case "SwitchLanguage": {
                this.availableLanguages = this.availableLanguages === false;
                break;
            }
            case "UserNotifications": {
                this.userNotifications = this.userNotifications === false;
                break;
            }
            case "SwitchInstance": {
                this.instanceSettings = this.instanceSettings === false;
                break;
            }
            case "UserProfile": {
                if (!this.userProfile) {
                    this.usersService.getUserProfile().subscribe((response: IUserProfile) => {
                        this.prepareUserProfileForm(response);
                        this.userProfile = true;
                        this.hideHeaderParent.emit(this.userProfile);
                    });
                }
                else {
                    this.userProfile = false;
                    this.hideHeaderParent.emit(this.userProfile);
                }
                break;
            }
        }
        this.showQrCode = !(this.userProfile || this.userDefaults || this.availableLanguages || this.userNotifications || this.instanceSettings);
    }

    getUserDefaults() {
        this.userSidenavService.getUserDefaults(Number(this.systemType)).subscribe((response: IUserDefaults) => {
            this.availableReceptions = this.responseToIdNameArrayPipe.transform(response.availableReceptions);
            if (this.systemType === "0" || this.systemType === "2") {
                this.availableParkings = this.responseToIdNameArrayPipe.transform(response.availableParkings);
                this.availableBuildings = this.responseToIdNameArrayPipe.transform(response.availableBuildings);
                this.userDefaultsForm = new FormGroup<IUserDefaultsForm>({
                    DefaultBuilding: new FormControl<IIdNamePair>(response.defaultBuilding, {validators: Validators.required}),
                    DefaultParking: new FormControl<IIdNamePair>(response.defaultParking),
                    DefaultReception: new FormControl<IIdNamePair>(response.defaultReception, {validators: Validators.required})
                })
            }
            else if (this.systemType === "1" || this.systemType === "3") {
                this.userDefaultsForm = new FormGroup<IUserDefaultsForm>({
                    DefaultReception: new FormControl<IIdNamePair>(response.defaultReception, {validators: Validators.required})
                })
            }
            this.ignoreParking = JSON.parse(localStorage.getItem("ignoreParking"));
        })
    }

    compareIdNamePairs(a: IIdNamePair, b: IIdNamePair) {
        return a && b && a.id === b.id;
    }

    getUserInstances() {
        this.userSidenavService.getUserInstances(localStorage.getItem("userAzureId")).subscribe(result => {
            this.userSidenavService.setAvailableInstances(result);
            this.setAvailableInstances(result);
        })
    }

    onInstanceChange(instanceId: string) {
        if (instanceId !== localStorage.getItem("instanceId")) {
            let userInfos: IUserInfo[] = this.userSidenavService.getUserInfo$.getValue();
            let chosenUserInfo: IUserInfo = userInfos.find(x => x.instance.id === instanceId);
            if (chosenUserInfo) {
                this.signalR.removeFromGroupSignalr().subscribe(responseFromRemove => {
                    if (responseFromRemove) {
                        this.userSidenavService.updateInstanceId(localStorage.getItem("userAzureId"), instanceId)
                            .subscribe(response => {
                                if (response.status === 204) {
                                    localStorage.setItem("status", chosenUserInfo.status);
                                    localStorage.setItem("manageUser", chosenUserInfo.manageUser);
                                    localStorage.setItem("email", chosenUserInfo.email);
                                    localStorage.setItem("userType", chosenUserInfo.userType);
                                    localStorage.setItem("defaultLanguage", chosenUserInfo.language);
                                    localStorage.setItem("ownerName", chosenUserInfo.ownerName);
                                    localStorage.setItem("firstName", chosenUserInfo.firstName);
                                    localStorage.setItem("lastName", chosenUserInfo.lastName);
                                    localStorage.setItem("systemType", chosenUserInfo.systemType);
                                    localStorage.setItem("instanceId", chosenUserInfo.instance.id);
                                    localStorage.setItem("instanceLogoUrl", chosenUserInfo.instance.instanceLogoUrl);
                                    localStorage.setItem("isWebPushEnabled", JSON.stringify(chosenUserInfo.isWebPushEnabled));
                                    localStorage.setItem("isMobileApplicationInstalled", chosenUserInfo.isMobileApplicationInstalled);
                                    this.checkAndInitWebPush();
                                    this.userSidenavService.getCompanyParkingStatusForUser()
                                        .subscribe(response => {
                                            if (response.status === 200) {
                                                localStorage.setItem("ignoreParking", "false");
                                            }
                                            else {
                                                localStorage.setItem("ignoreParking", "true");
                                            }
                                            this.selectedInstance = chosenUserInfo.instance.name;
                                            this.userSidenavService.instanceChanged();
                                            let currentUrl = this.router.url;
                                            let newPortalType = localStorage.getItem("systemType");
                                            if (this.oldSystemType !== newPortalType) {
                                                if (newPortalType === "0" || newPortalType === "2") {
                                                    this.router.navigateByUrl('/reload', {skipLocationChange: true}).then(() => {
                                                        this.router.navigateByUrl('/home');
                                                    });
                                                }
                                                else {
                                                    this.router.navigateByUrl('/reload', {skipLocationChange: true}).then(() => {
                                                        this.router.navigateByUrl('/smart-gate');
                                                    });
                                                }
                                            }
                                            else {
                                                this.router.navigateByUrl('/reload', {skipLocationChange: true}).then(() => {
                                                    this.router.navigate([currentUrl]);
                                                });
                                            }
                                            this.oldSystemType = newPortalType;
                                            this.snackBar.open(this.translate.instant('menu.changeIstanceSuccess'),
                                                this.translate.instant('shared.ok'),
                                                {
                                                    duration: 4000,
                                                    panelClass: ['sicco-snackbar'],
                                                });
                                        });
                                }
                                else {
                                    this.snackBar.open(this.translate.instant('menu.changeIstanceFailed'),
                                        this.translate.instant('shared.ok'),
                                        {
                                            duration: 4000,
                                            panelClass: ['sicco-snackbar'],
                                        });
                                }
                                this.signalR.addToGroupSignalR();
                            });
                    }
                });
            }
        }
        else {
            this.snackBar.open(this.translate.instant('menu.instanceAlreadyChoosen'),
                this.translate.instant('shared.ok'),
                {
                    duration: 4000,
                    panelClass: ['sicco-snackbar'],
                });
        }
    }

    onBuildingChange(event: MatSelectChange) {
        this.userDefaultsForm.controls.DefaultReception.reset();
        this.userDefaultsForm.controls.DefaultParking.reset();
        this.userSidenavService.getParkingsByBuilding(event.value.id).subscribe(response => {
            this.availableParkings = this.responseToIdNameArrayPipe.transform(response);
            if (this.availableParkings.length === 0) {
                this.userDefaultsForm.controls.DefaultParking.disable();
            }
            else {
                this.userDefaultsForm.controls.DefaultParking.enable();
            }
        });
        this.userSidenavService.getReceptionByBuilding(event.value.id).subscribe(response => {
            this.availableReceptions = this.responseToIdNameArrayPipe.transform(response)
            if (this.availableReceptions.length === 0) {
                this.userDefaultsForm.controls.DefaultReception.disable();
            }
            else {
                this.userDefaultsForm.controls.DefaultReception.enable();
            }
        });
    }

    prepareUserProfileForm(data: IUserProfile) {
        this.initialCountry = data.contactDetails.phonePrefixCountry ?? 'pl';
        this.countryCode = data.contactDetails.phonePrefix.slice(1);
        this.userCountryName = data.country;
        this.userProfileForm = new FormGroup<IUserProfileForm>({
            FirstName: new FormControl<string>(data.firstName, {validators: [Validators.required, Validators.maxLength(50), CustomValidators.specialCharactersValidator]}),
            LastName: new FormControl<string>(data.lastName, {validators: [Validators.required, Validators.maxLength(50), CustomValidators.lastNameValidator]}),
            Country: new FormControl<ICountry | string>('', {validators: Validators.required}),
            Email: new FormControl<string>(data.contactDetails.email, {validators: [Validators.required, CustomValidators.emailValidator]}),
            Phone: new FormControl<string>(data.contactDetails.phone, {validators: [Validators.maxLength(15), CustomValidators.phoneNumberValidator]}),
            PhonePrefix: new FormControl<string>(data.contactDetails.phonePrefix),
            PhonePrefixCountry: new FormControl<string>(data.contactDetails.phonePrefixCountry)
        });
    }

    submitUserProfile() {
        this.userProfileForm.markAllAsTouched();
        if (this.userProfileForm.invalid) {
            return;
        }
        else {
            this.userProfileForm.patchValue({
                Country: this.userProfileForm.controls.Country.value['name'],
                PhonePrefixCountry: this.initialCountry
            });
            if (this.userProfileForm.value.Phone) {
                this.userProfileForm.patchValue({
                    Phone: this.removeWhiteSpaces(this.userProfileForm.value.Phone)
                });
                if (this.countryCode) {
                    this.userProfileForm.patchValue({
                        PhonePrefix: '+' + this.countryCode
                    });
                }
                else if (this.userProfileForm.value.Phone.startsWith("+") || this.userProfileForm.value.Phone.startsWith("00")) {
                    this.userProfileForm.patchValue({
                        PhonePrefix: ''
                    });
                }
                else {
                    this.userProfileForm.patchValue({
                        PhonePrefix: '+48'
                    });
                }
            }
            this.usersService.updateUserProfile(this.userProfileForm.value).subscribe(
                response => {
                    this.snackBar.open(this.translate.instant('user-profile.dialog.component.updateProfile.success'), this.translate.instant('shared.ok'), {
                        duration: 4000,
                        panelClass: ['sicco-snackbar'],
                    });
                    localStorage.setItem("firstName", this.userProfileForm.value.FirstName);
                    localStorage.setItem("lastName", this.userProfileForm.value.LastName);
                    localStorage.setItem("email", this.userProfileForm.value.Email);
                    this.loading = false;
                    this.userProfile = false;
                    this.hideHeaderParent.emit(this.userProfile);
                },
                error => {
                    this.snackBar.open(this.translate.instant('user-profile.dialog.component.updateProfile.failed'), this.translate.instant('shared.ok'), {
                        duration: 4000,
                        panelClass: ['sicco-snackbar'],
                    });
                    this.loading = false;
                });
        }
    }

    countryChange(country: CountryData) {
        this.countryCode = country.dialCode;
        this.initialCountry = country.iso2;
    }

    private setDisableWebPushMessages() {
        this.currentWebPushState = false;
        this.webPushSubscribed = false;
    }

    private checkAndInitWebPush() {
        this.currentWebPushState = JSON.parse(localStorage.getItem("isWebPushEnabled"));
        if (this.currentWebPushState) {
            this.webPushSubscribed = true;
            this.messagingService.doesDbExist(this.currentWebPushState);
        }
        else {
            this.setDisableWebPushMessages();
        }
        this.modifyUserInfos('isWebPushEnabled', this.webPushSubscribed);
    }

    private checkMobileStatuses() {
        this.isMobileApplicationInstalled = JSON.parse(localStorage.getItem('isMobileApplicationInstalled'));
        this.isPushEnabled = JSON.parse(localStorage.getItem('isPushEnabled'));
    }

    private modifyUserInfos(arrayKey: string, value: any) {
        let userInfos = this.userSidenavService.getUserInfo$.getValue();
        userInfos?.map(i => {
            if (i.instance.id === localStorage.getItem("instanceId")) {
                i[arrayKey] = value;
            }
        });
        this.userSidenavService.setUserInfo(userInfos);
    }

    private setAvailableInstances(availableInstances) {
        this.availableInstances = availableInstances;
        let defaultInstance = this.availableInstances.find(x => x.isDefault);
        if (defaultInstance)
            this.selectedInstance = defaultInstance.name;
        else
            this.selectedInstance = this.availableInstances[0].name;
    }
}

interface IUserProfileForm {
    FirstName: FormControl<string>,
    LastName: FormControl<string>,
    Email: FormControl<string>,
    Phone: FormControl<string>,
    PhonePrefix: FormControl<string>,
    PhonePrefixCountry: FormControl<string>,
    Country: FormControl<ICountry | string>,
}

interface IUserDefaultsForm {
    DefaultBuilding?: FormControl<IIdNamePair>,
    DefaultReception?: FormControl<IIdNamePair>,
    DefaultParking?: FormControl<IIdNamePair>
}