import { AbstractControl, ValidatorFn } from "@angular/forms";

export class CustomValidators {

    static specialCharactersValidator(specialcharacters): any {
        if (specialcharacters.pristine) {
            return null;
        }
        const specialCharactersRegex = /^[^\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\-|\s]*$/;
        specialcharacters.markAsTouched();
        if (specialCharactersRegex.test(specialcharacters.value)) {
            return null;
        }
        return {
            specialCharacters: true
        }
    }

    static lastNameValidator(lastName): any {
        if (lastName.pristine) {
            return null;
        }
        const lastNameRegex = /^[^\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s]*$/;
        lastName.markAsTouched();
        if (lastNameRegex.test(lastName.value)) {
            return null;
        }
        return {
            lastName: true
        }
    }

    static locationValidator(location): any {
        if (location.pristine) {
            return null;
        }
        const lastNameRegex = /^[^\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:]*$/;
        location.markAsTouched();
        if (lastNameRegex.test(location.value)) {
            return null;
        }
        return {
            specialCharacters: true
        }
    }

    static spotValidator(spot): any {
        if (spot.pristine) {
            return null;
        }
        const spotRegex = /^[^\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\""|\;|\:]*$/;
        spot.markAsTouched();
        if (spotRegex.test(spot.value)) {
            return null;
        }
        return{
            specialCharacters: true
        }
    }

    static phoneNumberValidator(phoneNumber): any {
        if (phoneNumber.pristine) {
            return null;
        }
        const phoneNumberRegex = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
        phoneNumber.markAsTouched();
        if (phoneNumberRegex.test(phoneNumber.value)) {
            return null;
        }
        return {
            phoneNumber: true
        }
    }

    static emailValidator(email): any {
        if (email.pristine || email.value.length === 0) {
            return null;
        }
        const emailRegex = /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        email.markAsTouched();
        if (emailRegex.test(email.value)) {
            return null;
        }
        return {
            email: true
        }
    }

    static nipValidator(nip): any {
        if (nip.pristine) {
            return null;
        }
        const nipRegex = /^[^\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s]*$/;
        nip.markAsTouched();
        if (nipRegex.test(nip.value)) {
            return null;
        }
        return {
            nip: true
        }
    }
    
    static sameNameValidator(name: string): ValidatorFn {
        return (control: AbstractControl): { sameName: boolean } => {
            if (control.value === name) {
                return { sameName: true}
            }
            else {
                return null;
            }
        }
    }
}

