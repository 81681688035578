import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from "../../shared.module";
import { AppIconModule } from "../../../app-icon-module";
import { CountriesComponent } from "./countries.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        SharedModule,
        TranslateModule.forChild({}),
        ReactiveFormsModule, 
        MatFormFieldModule,
        MatSelectModule,
        NgxMatSelectSearchModule
    ],
    declarations: [
        CountriesComponent
    ],
    exports: [
        CountriesComponent
    ]
})

export class CountriesSharedModule { }