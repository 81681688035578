import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReportDetailsSmartinService {

    private baseUrl: string;

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getReport(invitationId) {
        return this.httpClient.get(this.baseUrl + 'api/Report/GetInvitationEventLog?invitationId=' + invitationId);
    }

    getReportForSecurity(invitationId) {
        return this.httpClient.get(this.baseUrl + 'api/SecurityOfficerAccess/GetParkingInvitationEventLog?invitationId=' + invitationId);
    }
    
    getBuildings() {
        return this.httpClient.get(this.baseUrl + 'api/Building/List');
    }
    
    getReceptions() {
        return this.httpClient.get(this.baseUrl + 'api/Reception/List');
    }
    
    getParkings() {
        return this.httpClient.get(this.baseUrl + 'api/Parking/List')
    }
}