import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { UserRole } from "../../shared/enums/user-role.enum";
import { UserDto } from "../../shared/interfaces/user-dto.interface";
import { IIdNamePair } from "../../shared/interfaces/idname.interface";

const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

@Injectable()
export class UsersService {
    private refreshNeeded = new Subject<void>();
    private baseUrl: string;

    get refreshNeeded$() {
        return this.refreshNeeded;
    }

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getUsers() {
        return this.httpClient.get(this.baseUrl +
            'api/User/UsersRegistrationStatus');
    }

    newActivation(value: any) {
        return this.httpClient.post(this.baseUrl + 'api/User/CreateNewActivationForUser?userId=' + value,
            { headers: headers })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    createUser(userData) {
        return this.httpClient.post(this.baseUrl + 'api/User/CreateUser', 
			userData, {observe: 'response'})
			.pipe(tap(()=>{
            	this.refreshNeeded.next();
        	}));
    }
    
    deleteUser(value: any) {
        var cancelInvitation = true;
        return this.httpClient.delete(this.baseUrl + 'api/User/Remove?userId=' + value + '&cancelInvitations=' + cancelInvitation)
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    updateUserPermission(userRole: UserRole, userId: string) {
        return this.httpClient.patch(this.baseUrl + 'api/User/UpdatePermission?userRole=' + userRole + '&userId=' + userId,
            { headers: headers })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    getUserProfile() {
        return this.httpClient.get(this.baseUrl +
            'api/User/GetUserProfile');
    }

    updateUserProfile(userProfile: any) {
        return this.httpClient.put(this.baseUrl + 'api/User/UpdateUserProfile',
            userProfile, { observe: 'response' });
    }
    
    getCompanies() {
        return this.httpClient.get(this.baseUrl + 'api/Company/ListIdNamePairs');
    }
    
    getSmartSyncUsers(companyId: string){
        return this.httpClient.get(this.baseUrl + 'api/User/SmartSyncUsers?companyId=' + companyId);
    }

    generateUserInfoQrCode(firstName: string, lastName: string): Observable<Blob> {
        return this.httpClient.get(
            this.baseUrl + 'api/OnPremisesDevices/UserInfoQrCode?FirstName=' + firstName + '&LastName=' + lastName,
            { responseType: 'blob' });
    }

    getCompany(): Observable<HttpResponse<IIdNamePair>> {
        return this.httpClient.get<IIdNamePair>(`${this.baseUrl}api/User/GetCompany`, { observe: 'response'});
    }

    get(companyId: string): Observable<HttpResponse<Array<UserDto>>> {
        const params: HttpParams = new HttpParams().set('companyId', companyId);
        
        return this.httpClient.get<Array<UserDto>>(`${this.baseUrl}api/User`, { params: params, observe: 'response' });
    }
}