import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { OAuthModuleConfig, OAuthModule, AuthConfig } from 'angular-oauth2-oidc';
import { AuthGuard } from './shared/auth/auth.guard';
import { MatMenuModule } from '@angular/material/menu';
import { AppComponent } from './app.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundComponent } from './notfound/notfound.component';
import { LoginComponent } from "./login/login.component";
import { LanguageDialogComponent } from "./shared/language-dialog/language-dialog.component";
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { authConfigFactory } from './app.service';
import { LeftMenuComponent } from './left-menu/left-menu.component'

import { AppService } from './app.service';
import { SidenavService } from './shared/sidenav-service/sidenav.service';
import { SignalrService } from './shared/signalr-service/signalr.service';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { registerLocaleData, LOCATION_INITIALIZED } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeUk from '@angular/common/locales/en-GB';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from './shared/cookie/cookie.config';
import { CookieLangChanger } from './shared/cookie/cookie.language.changer';
import { DataProcessingComponent } from "./data-processing/data-processing.component";
import { AppRoutingModule } from "./app-routing-module";
import { AppIconModule } from "./app-icon-module";
import { LoadingScreenInterceptor } from "./shared/helpers/loading.interceptor";
import { LoadingScreenComponent } from './loading-screen/components/loading-screen.component';
import { MessagingService } from "./shared/messaging/messaging.service";
import { CorrectlyLoggedService } from "./shared/correctlylogged-service/correctly-logged-service";
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from "./shared/shared.module";
import { FireBaseModule } from "./shared/firebase.module";
import { DataBaseService } from "./shared/database-service/database-service";
import { UserSettingsComponent } from "./shared/components/user-settings/user-settings.component";
import { MobileAppInfoComponent } from "./shared/components/mobile-app-info/mobile-app-info.component";
import { InstanceIdInterceptor } from "./shared/interceptors/instance.inteceptor";
import { SecurityAlarmSharedModule } from "./shared/components/security-alarm/security-alarm.module";
import { UserService } from './shared/user-sidenav-service/user-sidenav.service';
import { LicenseInfoSharedModule } from './shared/components/license-info/license-info.module';
import { UserSettingsDevicesInfoComponentModule } from './shared/components/user-settings-device-info/user-settings-device-info.module';
import { RedirectGuard } from "./shared/auth/redirect.guard";
import { UserSettingsComponentModule } from "./shared/components/user-settings/user-settings.module";
import { SidenavContentDirective } from "./shared/helpers/sidenav-content.directive";
import { BuildingsService } from "./administration/buildings/components/buildings.service";
import { ReceptionsService } from "./administration/receptions/components/receptions.service";
import { CompaniesService } from "./administration/companies/components/companies.service";
import { UsersService } from "./users/components/users.service";
import { ReportDetailsService } from "./shared/components/report-details/report-details.service";
import { ReportDetailsSmartinService } from "./shared/components/report-details-smartin/report-details-smartin.service";
import { ConverterReportLogsService } from "./shared/helpers/converter-reportlogs";
import { SecurityOfficerService } from "./security-officer/security-officer-components/security-officer.service";
import { LongTermInvitationService } from "./long-term-invitation/components/long-term-invitation.service";
import { SecurityOfficerStatisticsService } from "./shared/components/security-statistics/security-statistics.component.service";
import { HomeService } from "./home/components/home.service";
import { CancelReasonsService } from "./administration/predefined-values/components/cancel-reasons.service";
import { DocumentsPickupMessagesService } from "./administration/predefined-values/components/documents-pickup-messages.service";
import { FilterTableService } from './shared/filter-table-service/filter-table.service';
import { ServiceInvitationService } from "./shared/components/service-invitation/service-invitation.service";
import { LocalizedDatePipe } from "./shared/helpers/localizeddate.pipe";
import { DevicesService } from "./administration/devices/components/devices.service";
import { ErrorControlSharedModule } from "./shared/components/error-control/error-control.module";
import { CountriesSharedModule } from "./shared/components/countries/countries.module";
import { SecurityPostService } from "./administration/security-post/components/securitypost.service";
import { ParkingsService } from "./administration/parkings/components/parkings.service";
import { ReceptionMessagesService } from "./administration/reception-messages/components/reception-messages.service";
import { PdfGenerationService } from "./shared/helpers/pdf-generation";
import { IdNamePipe } from "./shared/helpers/idname.pipe";
import { DateToTimePipe } from "./shared/helpers/dateToTime.pipe";
import { TimeToDatePipe } from "./shared/helpers/timeToDate.pipe";
import { ResponseToIdNameArrayPipe } from "./shared/helpers/responseToIdNameArray.pipe";
import { TranslationTextPipe } from "./shared/helpers/translationText.pipe";
import { IndefiniteDatePipe } from "./shared/helpers/indefiniteDate.pipe";

@NgModule({
    declarations: [
        AppComponent,
        LeftMenuComponent,
        ConfirmationDialogComponent,
        NotFoundComponent,
        LanguageDialogComponent,
        DataProcessingComponent,
        LoginComponent,
        LoadingScreenComponent,
        NavMenuComponent,
        UserSettingsComponent,
        MobileAppInfoComponent,
        SidenavContentDirective
    ],
    imports: [
        OAuthModule.forRoot(),
        MatMenuModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatIconModule,
        MatDialogModule,
        MatTooltipModule,
        MatSelectModule,
        MatDividerModule,
        MatRippleModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatChipsModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSlideToggleModule,
        SecurityAlarmSharedModule,
        LicenseInfoSharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgcCookieConsentModule.forRoot(cookieConfig),
        AppIconModule,
        SharedModule,
        FireBaseModule,
        UserSettingsDevicesInfoComponentModule,
        UserSettingsComponentModule,
        ErrorControlSharedModule,
        CountriesSharedModule
    ],
    providers: [
        AuthGuard,
        RedirectGuard,
        AppService,
        SidenavService,
        SignalrService,
        MessagingService,
        UserService,
        CorrectlyLoggedService,
        DataBaseService,
        {
            provide: OAuthModuleConfig,
            useFactory: authConfigFactory,
            deps: [AppService]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        CookieLangChanger,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InstanceIdInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingScreenInterceptor,
            multi: true
        },
        BuildingsService,
        ReceptionsService,
        CompaniesService,
        SecurityPostService,
        UsersService,
        CancelReasonsService,
        ReportDetailsService,
        ReportDetailsSmartinService,
        ConverterReportLogsService,
        PdfGenerationService,
        SecurityOfficerService,
        LongTermInvitationService,
        SecurityOfficerStatisticsService,
        HomeService,
        DocumentsPickupMessagesService,
        DevicesService,
        ServiceInvitationService,
        FilterTableService,
        ParkingsService,
        LocalizedDatePipe,
        ReceptionMessagesService,
        IdNamePipe,
        DateToTimePipe,
        TimeToDatePipe,
        ResponseToIdNameArrayPipe,
        TranslationTextPipe,
        IndefiniteDatePipe
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () => new Promise<any>((resolve: any) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            let langToSet = "pl-PL";
            translate.setDefaultLang(langToSet);
            translate.use(langToSet).subscribe(() => {
            }, err => {
                console.error(`Problem with '${langToSet}' language initialization.'`);
            }, () => {
                resolve(null);
            });
        });
    });
}

registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeUk, 'en-GB');