import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ICameraDetails, IDevice } from "../../../shared/interfaces/device.interface";
import { IDeviceQrData } from "../../../shared/interfaces/deviceQrData.interface";

@Injectable()
export class DevicesService {
    isBasicDataShown = new BehaviorSubject<boolean>(true);
    isSelfCheckoutKioskShown = new BehaviorSubject<boolean>(true);
    isSealReaderShown = new BehaviorSubject<boolean>(true);
    isCameraShown = new BehaviorSubject<boolean>(true);
    areEventsShown = new BehaviorSubject<boolean>(true);
    isSmartSyncShown = new BehaviorSubject<boolean>(true)
    private refreshNeeded = new Subject<void>();
    private newActivationDataDevice = new BehaviorSubject<IDevice>(null);
    private baseUrl: string;

    get refreshNeeded$() {
        return this.refreshNeeded;
    }

    get newActivationDataDevice$() {
        return this.newActivationDataDevice;
    }

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getAllDevices() {
        return this.httpClient.get(this.baseUrl + 'api/OnPremisesDevices/GetAll');
    }

    getDeviceDetails(deviceId: string) {
        return this.httpClient.get(this.baseUrl + 'api/OnPremisesDevices/GetDeviceDetails?Id=' + deviceId);
    }

    getGooglePlayApplicationUrls() {
        return this.httpClient.get(this.baseUrl + 'api/OnPremisesDevices/GooglePlayApplicationUrls');
    }

    updateDevice(device: any) {
        return this.httpClient.post(this.baseUrl + 'api/OnPremisesDevices/Edit',
            device, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    createDevice(device: any) {
        return this.httpClient.post(this.baseUrl + 'api/OnPremisesDevices/Create',
            device, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    removeDevice(deviceId: string) {
        return this.httpClient.delete(this.baseUrl + 'api/OnPremisesDevices/Remove?Id=' + deviceId)
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    resetDevice(deviceId: string, resetDeviceId: boolean) {
        return this.httpClient.get(this.baseUrl + 'api/OnPremisesDevices/Reset?Id=' + deviceId + '&resetDeviceId=' + resetDeviceId)
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    generateQrCode(device: IDevice) {
        let deviceQr = <IDeviceQrData>{};
        deviceQr.baseUrl = device.deviceHandlerUrl;
        deviceQr.activationCode = device.activationCode;
        deviceQr.instanceId = localStorage.getItem('instanceId');
        return this.httpClient.post(this.baseUrl + 'api/Qr/GetOnPremiseDeviceQr', deviceQr, { responseType: 'blob' });
    }

    getAllPowerBiReportsForDevices(language) {
        return this.httpClient.get(this.baseUrl + 'api/PowerBiReport/GetAllPbiReportsByTypeTranslated?reportType=1&languageCode=' + language);
    }

    getReceptions() {
        return this.httpClient.get(this.baseUrl + 'api/Reception/List');
    }

    generateNewActivationDataDevice(device) {
        this.newActivationDataDevice.next(device);
    }

    getHealthMonitorConfig() {
        return this.httpClient.get(this.baseUrl + 'api/OnPremisesDevices/HealthMonitorConfig');
    }
    
    updateHealthMonitorConfig(config) {
        return this.httpClient.post(this.baseUrl + 'api/OnPremisesDevices/UpdateHealthMonitorConfig',
            config, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeeded.next();
                })
            );
    }

    createCamera(camera: IDevice & ICameraDetails) {
        return this.httpClient.post(this.baseUrl + 'api/Camera/Add', 
			camera, { observe: 'response' })
        	.pipe(
            	tap(() => {
                	this.refreshNeeded.next();
            	})
        	);
    }
	
	updateCamera(camera: IDevice & ICameraDetails) {
		return this.httpClient.post(this.baseUrl + 'api/Camera/Edit', 
			camera, {observe: 'response'})
			.pipe(
				tap(() => {
					this.refreshNeeded.next();
				})
			);
	}
    
    removeCamera(cameraId: string) {
        return this.httpClient.delete(this.baseUrl + 'api/Camera/Remove?cameraId=' + cameraId)
        .pipe(
            tap(() => {
                this.refreshNeeded.next();
            })
        );
    }

    createSmartSyncCamera(camera: any) {
        return this.httpClient.post(this.baseUrl + 'api/SmartSyncCamera/Add',
            camera, { observe: 'response' })
        .pipe(
            tap(() => {
                this.refreshNeeded.next();
            })
        );
    }

    updateSmartSyncCamera(camera: any) {
        return this.httpClient.post(this.baseUrl + 'api/SmartSyncCamera/Edit',
            camera, {observe: 'response'})
        .pipe(
            tap(() => {
                this.refreshNeeded.next();
            })
        );
    }
    
    getCompanies() {
        return this.httpClient.get(this.baseUrl + 'api/Company/ListIdNamePairs')
    }
    
    getParkingGates() {
        return this.httpClient.get(this.baseUrl + 'api/ParkingGate/ListIdNamePairs')
    }
    
    getParkingGateDirection(parkingGateId: string){
        return this.httpClient.get(this.baseUrl + 'api/ParkingGate/ParkingGateDirection?parkingGateId=' + parkingGateId);
    }

    prepareSupportedEvents(portalType) {
        if (portalType === '0' || portalType === '2') {
            return [
                { eventType: 'ParkingReservationStatus', eventValue: 'Arrived' },
                { eventType: 'ParkingReservationStatus', eventValue: 'Left' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Awaiting' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Inside' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Outside' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Completed' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Cancelled' },
            ]
        } else {
           return [
                { eventType: 'InvitationAlertType', eventValue: 'VehicleNoInside' },
                { eventType: 'ParkingReservationStatus', eventValue: 'Arrived' },
                { eventType: 'ParkingReservationStatus', eventValue: 'Left' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Awaiting' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Approved' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Inside' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Outside' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Completed' },
                { eventType: 'InvitationLifeCycleStatus', eventValue: 'Cancelled' },
            ]
        }
    }
}