import { Component, OnInit, OnDestroy } from "@angular/core";
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcInitializeEvent, NgcStatusChangeEvent, } from "ngx-cookieconsent";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;
    private noCookieLawSubscription: Subscription;

    constructor(private oauthService: OAuthService,
        public router: Router,
        private translate: TranslateService,
        private ccService: NgcCookieConsentService) {
        if (!window.location.href.includes("#state") && !this.oauthService.hasValidIdToken() && !window.location.href.includes("/login")) {
            sessionStorage.setItem("redirectUrl", window.location.pathname + window.location.search);
        }

        this.translate.addLangs(["en-GB", "pl-PL"]);
    }

    ngOnInit() {
        this.ccService.getConfig().cookie.domain = window.location.hostname;

        // subscribe to cookieconsent observables to react to main events
        this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
        });

        this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
        });

        this.initializeSubscription = this.ccService.initialize$.subscribe(
            (event: NgcInitializeEvent) => {
            }
        );

        this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
            (event: NgcStatusChangeEvent) => {
            }
        );

        this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
            () => {
            }
        );

        this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
            (event: NgcNoCookieLawEvent) => {
            }
        );
    }

    ngOnDestroy() {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializeSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
    }
}