import { Pipe, PipeTransform } from "@angular/core";
import { IIdNamePair } from "../interfaces/idname.interface";

@Pipe({
	name: 'idName'
})
export class IdNamePipe implements PipeTransform {
	transform(id: string, idNamePairs: IIdNamePair[]): string {
		const pair: IIdNamePair | undefined = idNamePairs.find((x: IIdNamePair): boolean => x.id === id);
		return pair ? pair.name : '';
	}
}