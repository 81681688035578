import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SecurityOfficerService } from 'src/app/security-officer/security-officer-components/security-officer.service';
import { UserService } from '../../user-sidenav-service/user-sidenav.service';

@Component({
    selector: 'app-security-alarm',
    templateUrl: './security-alarm.component.html',
    styleUrls: ['./security-alarm.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SecurityAlarmComponent {
    commonAlarmList = [];
    alarmList: any;
    buildingsList: any;
    form: FormGroup<ISecurityAlarmForm>;
    send: boolean = false;
    sendConfirm: boolean = false;
    loadingAlarmInfo: boolean = false;
    alarmInfo: string = null;
    numberOfRecipients: number = null;

    constructor(public securityService: SecurityOfficerService,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        private userService: UserService) { }

    ngOnInit() {
        this.getBuildingsForUser();
        this.getAlarmsList();
        
        this.form = new FormGroup<ISecurityAlarmForm>({
            buildingId: new FormControl('', { validators: [Validators.required], nonNullable: false}),
            notificationPurpose: new FormControl('', { validators: [Validators.required], nonNullable: false})
        })

        this.commonAlarmList =
            [
                { 'number': '112', 'label': this.translate.instant('security-officer.view.alarmNumbers.emergencyNumber') },
                { 'number': '999', 'label': this.translate.instant('security-officer.view.alarmNumbers.ambulanceNumber') },
                { 'number': '998', 'label': this.translate.instant('security-officer.view.alarmNumbers.fireBrigadeNumer') },
                { 'number': '997', 'label': this.translate.instant('security-officer.view.alarmNumbers.policeNumber') }
            ]
    }

    onSubmit() {
        if (!this.form.valid)
            return;
        
        if (!this.send) {
            this.loadingAlarmInfo = true;
            this.send = true;
            let languageCode = localStorage.getItem("defaultLanguage");
            this.securityService.getAlarmInfo(this.form.controls.buildingId.value, this.form.controls.notificationPurpose.value, languageCode).subscribe(response => {
                const data = response;
                this.alarmInfo = data['messageTemplate'];
                this.numberOfRecipients = data['numberOfRecipients']
                this.loadingAlarmInfo = false;
            });
            
            return;
        }

        this.securityService.sendAlarm(this.form.value.notificationPurpose, this.form.value.buildingId).subscribe({
            next: response => {
                if (response.status === 200) {
                    this.snackBar.open(this.translate.instant('security-officer.component.alarmSended'), this.translate.instant('shared.ok'), {
                        duration: 3000,
                        panelClass: ['sicco-snackbar'],
                    });
                    this.send = false;
                    this.clearForm();
                    this.close();
                }
            },
            error: err => {
                this.snackBar.open(this.translate.instant('security-officer.component.sendingError'), this.translate.instant('shared.ok'), {
                    duration: 3000,
                    panelClass: ['sicco-snackbar'],
                });
            }
        });
    }

    cancelAlarm() {
        this.send = false;
        this.alarmInfo = null;
        this.numberOfRecipients = null;
        this.clearForm();
    }

    clearForm() {
        this.form.reset();
        this.form.controls.buildingId.setErrors(null)
        this.form.controls.notificationPurpose.setErrors(null)
    }

    close() {
        this.userService.closeSideNav()
    }

    private getBuildingsForUser() {
        this.securityService.getBuildingsForUser().subscribe(response => {
            const data = response;
            this.buildingsList = data;
        });
    }

    private getAlarmsList() {
        this.securityService.getAlarmsList().subscribe(response => {
            const data = response;
            this.alarmList = data;
        });
    }
}

interface ISecurityAlarmForm {
    buildingId: FormControl<any>,
    notificationPurpose: FormControl<any>
}