import { Inject, Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserInfo } from "../../login/login.component";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class UserService {
    private baseUrl: string;
    drawer: MatDrawer;
    openUserDefaults = new Subject<void>();
    refreshUserDefaults = new Subject<void>();
    getDefaultsSettings = new Subject<void>();
    getInstanceSettings = new Subject<void>();
    defaultUserLanguageChange = new Subject<any>();
    instanceChange = new Subject<void>();
    userInfo = new BehaviorSubject<UserInfo[]>(null);
    aggregationSettingsSmartGateTable = new BehaviorSubject<string>(null);
    smartGateDasboardType = new BehaviorSubject<string>(null);
    availableInstances = new BehaviorSubject<any>(null);

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }
    
    get defaultUserLanguage$() {
        return this.defaultUserLanguageChange.asObservable();
    }

    get openUserDefaults$() {
        return this.openUserDefaults;
    }

    get refreshUserDefaults$() {
        return this.refreshUserDefaults;
    }

    get getDefaultsSettings$() {
        return this.getDefaultsSettings;
    }

    get getInstanceSettings$() {
        return this.getInstanceSettings;
    }

    get getInstanceChange$() {
        return this.instanceChange;
    }

    get getUserInfo$() {
        return this.userInfo;
    }

    get aggregationTableSettings$() {
        return this.aggregationSettingsSmartGateTable;
    }

    get smartGateDasboardTypes$() {
        return this.smartGateDasboardType;
    }

    get availableInstance$() {
        return this.availableInstances;
    }

    public setDrawer(sidenav: MatDrawer) {
        this.drawer = sidenav;
    }

    public openSideNav() {
        this.openUserDefaults.next();
        this.getDefaultsSettings.next();
        return this.drawer.open();
    }

    public closeSideNav() {
        this.refreshUserDefaults.next();
        return this.drawer.close();
    }

    public toggleSideNav() {
        this.drawer.toggle();
    }

    public instanceReady() {
        this.getInstanceSettings.next();
    }

    public setUserInfo(userInfo) {
        this.userInfo.next(userInfo);
    }

    public instanceChanged() {
        this.instanceChange.next();
    }

    public setAggregationSettingsSmartGateTable(aggregationSettings) {
        this.aggregationSettingsSmartGateTable.next(aggregationSettings);
    }

    public setSmartGateDasboardType(dashboardType) {
        this.smartGateDasboardType.next(dashboardType);
    }

    public setAvailableInstances(availableInstances) {
        this.availableInstances.next(availableInstances);
    }
    
    setPushNotifications(status: boolean) {
        return this.httpClient.post(this.baseUrl + 'api/MobileApplication/SetPushNotificationsEnabledStatus?status=' + 
			status, null, {observe: 'response'});
    }
    
    updateLanguage(language: string) {
        return this.httpClient.patch(this.baseUrl + 'api/User/UpdateLanguage?languageCode=' + 
            language, null, {observe: 'response'});
    }
    
    getUserDefaults(systemType: number) {
        return this.httpClient.get(this.baseUrl + 'api/User/UserDefaultSettings?systemType=' + systemType);
    }
    
    createOrUpdateUserDefaults(userDefaults) {
        return this.httpClient.post(this.baseUrl + 'api/User/CreateOrUpdateUserDefaults', 
            userDefaults, {observe: 'response'});
    }
    
    getUserMobileDevices() {
        return this.httpClient.get(this.baseUrl + 'api/User/GetUserMobileDevices');
    }
    
    getUserInstances(userAzureId: string) {
        return this.httpClient.get(this.baseUrl + 'api/User/GetInstances?userAzureId=' + userAzureId);
    }
    
    updateInstanceId(userAzureId: string, instanceId: string) {
        return this.httpClient.patch(this.baseUrl + 'api/User/UpdateInstanceId?userAzureId=' + userAzureId + 
            '&instanceId=' + instanceId, 
            null, {observe: 'response'});
    }
    
    getCompanyParkingStatusForUser() {
        return this.httpClient.get(this.baseUrl + 'api/User/CompanyParkingStatusForUser',
            {observe: 'response'});
    }
    
    getParkingsByBuilding(buildingId: string) {
        return this.httpClient.get(this.baseUrl + 'api/Parking/Building?buildingId=' + buildingId);
    }
    
    getReceptionByBuilding(buildingId: string) {
        return this.httpClient.get(this.baseUrl + 'api/Reception/Building?buildingId=' + buildingId);
    }
}
