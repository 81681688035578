<mat-form-field>
    <mat-select #countrySelect [formControlName]="controlName" [placeholder]="this.label" [required]="this.required" [(value)]="selectedCountry" (selectionChange)="onOptionsSelected($event)">
        <mat-option>
            <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="{{ 'countries.searchCountry' | translate }}" noEntriesFoundLabel="{{ 'countries.notFound' | translate }}"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
            <img src="/assets/img/flag_placeholder.png" [class]="'flag flag-' + selectedCountry?.code.toLowerCase()" />
            {{selectedCountry?.name}}
        </mat-select-trigger>
        <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            <img src="/assets/img/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" />
            {{country.name}}
        </mat-option>
    </mat-select>
</mat-form-field>