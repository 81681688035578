import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from "./notfound/notfound.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./shared/auth/auth.guard";
import { DataProcessingComponent } from "./data-processing/data-processing.component";
import { QuicklinkStrategy } from 'ngx-quicklink';
import { environment } from "../environments/environment";
import { RedirectGuard } from "./shared/auth/redirect.guard";

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: '', pathMatch: 'full', redirectTo: environment.homePageRoute, canActivate: [RedirectGuard] },
    { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
    { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard] },
    { path: 'companies', loadChildren: () => import('./administration/companies/companies.module').then(m => m.CompaniesModule), canActivate: [AuthGuard] },
    { path: 'receptions', loadChildren: () => import('./administration/receptions/receptions.module').then(m => m.ReceptionsModule), canActivate: [AuthGuard] },
    { path: 'security-post', loadChildren: () => import('./administration/security-post/securitypost.module').then(m => m.SecurityPostModule), canActivate: [AuthGuard] },
    { path: 'devices', loadChildren: () => import('./administration/devices/devices.module').then(m => m.DevicesModule), canActivate: [AuthGuard] },
    { path: 'buildings', loadChildren: () => import('./administration/buildings/buildings.module').then(m => m.BuildingsModule), canActivate: [AuthGuard] },
    { path: 'vehicles', loadChildren: () => import('./vehicles/vehicles.module').then(m => m.VehiclesModule), canActivate: [AuthGuard] },
    { path: 'archive', loadChildren: () => import('./archive/archive.module').then(m => m.ArchiveModule), canActivate: [AuthGuard] },
    { path: 'smart-gate', loadChildren: () => import('./smart-gate/smart-gate.module').then(m => m.SmartGateModule), canActivate: [AuthGuard] },
    { path: 'smart-gate-archive', loadChildren: () => import('./smart-gate-archive/smart-gate-archive.module').then(m => m.SmartGateArchiveModule), canActivate: [AuthGuard] },
    { path: 'kpi', loadChildren: () => import('./kpi/kpi.module').then(m => m.KpiModule), canActivate: [AuthGuard] },
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard] },
    { path: 'longterm', loadChildren: () => import('./long-term-invitation/long-term-invitation.module').then(m => m.LongTermInvitationModule), canActivate: [AuthGuard] },
    { path: 'longterm-smartsync', loadChildren: () => import('./long-term-invitation-smart-sync/long-term-invitation-smart-sync.module').then(m => m.LongTermInvitationSmartSyncModule), canActivate: [AuthGuard] },
    { path: 'carrier', loadChildren: () => import('./carrier/carrier.module').then(m => m.CarrierLazyModule) },
    { path: 'guest/editplate', loadChildren: () => import('./guest/guest.module').then(m => m.GuestLazyModule) },
    { path: 'guest/rejectinvitation', loadChildren: () => import('./guest/guest.module').then(m => m.GuestLazyModule) },
    { path: 'company-details', loadChildren: () => import('./company-details/company-details.module').then(m => m.CompanyDetailsModule), canActivate: [AuthGuard] },
    { path: 'DataProcessing', component: DataProcessingComponent },
    { path: 'security', loadChildren: () => import('./security-officer/security-officer.module').then(m => m.SecurityLazyModule), canActivate: [AuthGuard] },
    { path: 'predefined-values', loadChildren: () => import('./administration/predefined-values/predefined-values.module').then(m => m.PredefinedValuesModule), canActivate: [AuthGuard] },
    { path: 'reception-messages', loadChildren: () => import('./administration/reception-messages/reception-messages.module').then(m => m.ReceptionMessagesModule), canActivate: [AuthGuard] },
    { path: 'parkings', loadChildren: () => import('./administration/parkings/parkings.module').then(m => m.ParkingsModule), canActivate: [AuthGuard] },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes,
            {
    preloadingStrategy: QuicklinkStrategy,
    relativeLinkResolution: 'legacy'
})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }