<div class="alarm-container">
    <div class="alarm-hader">
        <fa-icon class="fa-icon" [icon]="['fal','siren-on']" size="2x"></fa-icon>
        <span> {{ 'security-officer.view.alarm' | translate }}
        </span>
        <button mat-icon-button (click)="close()">
            <fa-icon [icon]="['fal','times']" size="2x"></fa-icon>
        </button>
    </div>
    <mat-divider></mat-divider>
    <div class="alarm-numbers">
        <fa-icon class="fa-icon" [icon]="['fal','phone-alt']" size="lg"></fa-icon>
        <div class="common-numbers-container">
            <h4>
                {{ 'security-officer.view.alarmNumbers.label' | translate }}
            </h4>
            <ng-container *ngFor="let alarm of commonAlarmList">
                <p>
                    {{alarm.number}} - {{alarm.label}}
                </p>
            </ng-container>
        </div>
    </div>
    <div class="alarm-type">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <mat-label>
                    {{ 'security-officer.view.buildingsList' | translate }}
                </mat-label>
                <mat-select formControlName="buildingId" required (selectionChange)="send = false">
                    <mat-option [value]="buildings.key" *ngFor="let buildings of buildingsList | keyvalue">
                        {{buildings.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>
                    {{ 'security-officer.view.alarmList' | translate }}
                </mat-label>
                <mat-select formControlName="notificationPurpose" required (selectionChange)="send = false">
                    <mat-option [value]="alarm.key" *ngFor="let alarm of alarmList | keyvalue">
                        {{alarm.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="!send">
                <button mat-button class="alarm-type-button confirm-alarm-button">
                    <span>
                        {{ 'shared.send' | translate }}
                    </span>
                </button>
            </ng-container>
            <ng-container *ngIf="send">
                <ng-container *ngIf="alarmInfo">
                    <p class="alarm-type-label">
                        {{ 'security-officer.view.confirmAlarmInfoOne' | translate }}
                        <strong> {{numberOfRecipients}} </strong>
                        {{ 'security-officer.view.confirmAlarmInfoTwo' | translate }}
                    </p>
                    <p class="alarm-type-info">
                        {{alarmInfo}}
                    </p>
                </ng-container>
                <div class="spinner-container" *ngIf="!alarmInfo">
                    <div class="spinner" [class.spinner]="loadingAlarmInfo">
                </div>
                </div>
                <button mat-button class="alarm-type-button cancel-alarm-button" (click)="cancelAlarm()">
                    <span>
                        {{ 'shared.cancel' | translate }}
                    </span>
                </button>
                <button mat-button class="alarm-type-button confirm-alarm-button">
                    <span>
                        {{ 'shared.confirm' | translate }}
                    </span>
                </button>
            </ng-container>
        </form>
    </div>
</div>