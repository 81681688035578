import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from "@ngx-translate/core";
import { AppIconModule } from "src/app/app-icon-module";
import { SharedModule } from "src/app/shared/shared.module";
import { UserSettingsDeviceInfoComponent } from "./user-settings-device-info.component";

@NgModule({
    imports: [
        BrowserAnimationsModule,
        AppIconModule,
        SharedModule,
        TranslateModule.forChild({})
    ],
    exports: [
        UserSettingsDeviceInfoComponent
    ],
    declarations: [
        UserSettingsDeviceInfoComponent
    ]
})

export class UserSettingsDevicesInfoComponentModule { }