import { Injectable } from "@angular/core";

@Injectable()
export class ConverterReportLogsService {

    convert(dataLogs: any, invitationType: number = 0) {
        let i = 0;
        let array = [];
        dataLogs.forEach(dt => {
            dt.eventLogs.forEach(e => {
                e.shipmentIdFromDb = dt.id;
                if (e.eventValue === 'Scheduled' && invitationType !== 6) {
                    array.push({
                        eventValue: "",
                        approach: ++i
                    });
                } else if (e.eventValue === 'Awaiting' && invitationType === 6) {
                    array.push({
                        eventValue: "",
                        approach: ++i
                    });
                }
                array.push(e);
                if (e.eventValue === 'Cancelled') {
                    e.cancelReason = dt.cancelReason;
                }
            });
        });
        return array;
    }
}