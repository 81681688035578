<ng-container *ngIf="isNotValid">
    <ng-container *ngIf="formControl.hasError('required')"> {{ 'error-control.view.requiredError' | translate }} </ng-container>
    <ng-container *ngIf="formControl.errors.email"> {{ 'error-control.view.emailError' | translate }} </ng-container>
    <ng-container *ngIf="formControl.hasError('maxlength')"> {{ 'error-control.view.maxlengthError' | translate }} {{formControl.errors.maxlength.requiredLength}}</ng-container>
    <ng-container *ngIf="formControl.hasError('minlength')"> {{ 'error-control.view.minlengthError' | translate }} {{formControl.errors.minlength.requiredLength}}</ng-container>
    <ng-container *ngIf="formControl.hasError('max')"> {{ 'error-control.view.maxValueError' | translate }} {{formControl.errors.max.max}}</ng-container>
    <ng-container *ngIf="formControl.hasError('min')"> {{ 'error-control.view.minValueError' | translate }} {{formControl.errors.min.min}}</ng-container>
    <ng-container *ngIf="formControl.errors.specialCharacters"> {{ 'error-control.view.specialCharacters' | translate }}</ng-container>
    <ng-container *ngIf="formControl.errors.lastName"> {{ 'error-control.view.lastNameError' | translate }}</ng-container>
    <ng-container *ngIf="formControl.errors.phoneNumber"> {{ 'error-control.view.phoneNumberError' | translate}}</ng-container>
    <ng-container *ngIf="formControl.errors.nip"> {{ 'error-control.view.nipError' | translate }}</ng-container>
    <ng-container *ngIf="formControl.errors.pastDate"> {{ 'error-control.view.pastDateError' | translate}}</ng-container>
    <ng-container *ngIf="formControl.errors.biggerStartDate"> {{ 'error-control.view.biggerStartDateError' | translate}}</ng-container>
    <ng-container *ngIf="formControl.errors.invalidDate"> {{ 'error-control.view.invalidDateError' | translate}}</ng-container>
    <ng-container *ngIf="formControl.errors.emailExists">{{ 'error-control.view.emailExistsError' | translate }}</ng-container>
    <ng-container *ngIf="formControl.errors.owlDateTimeMin">{{ 'error-control.view.pastDateError' | translate}}</ng-container>
    <ng-container *ngIf="formControl.errors.sameName">{{ 'error-control.view.sameNameError' | translate}}</ng-container>
    <ng-container *ngIf="formControl.errors.startDateInRange">{{ 'error-control.view.startDateInRange' | translate}}</ng-container>
    <ng-container *ngIf="formControl.errors.endDateInRange">{{ 'error-control.view.endDateInRange' | translate}}</ng-container>
</ng-container>