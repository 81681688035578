import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CompanyDto } from "../../../shared/interfaces/company-dto.interface";
import { IIdNamePair } from "../../../shared/interfaces/idname.interface";

const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

@Injectable()
export class CompaniesService {
    isBasicDataShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    isAdvancedDataShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    areParkingFormsShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    isAdminFormShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    openEdit: Subject<any> = new Subject();
    private refreshNeededCompany = new Subject<void>();
    private refreshNeededAdmin = new Subject<void>();
    private baseUrl: string;

    get refreshNeededCompany$() {
        return this.refreshNeededCompany;
    }
    get refreshNeededAdmin$() {
        return this.refreshNeededAdmin;
    }

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    getAdmins() {
        return this.httpClient.get(this.baseUrl +
            'api/User/AdminsRegistrationStatus');
    }

    getBuildings() {
        return this.httpClient.get(this.baseUrl + 'api/Building/List');
    }
    
    getParkingForBuildings(buildings: string[]) {
        const params = new HttpParams({
            fromObject: {'buildingsIds': buildings}
        });
        return this.httpClient.get(this.baseUrl + 'api/Parking/ListParkingsByBuildings?' + params.toString());
    }

    getAll(): Observable<HttpResponse<Array<CompanyDto>>> {
        return this.httpClient.get<Array<CompanyDto>>(`${this.baseUrl}api/Company/GetAll`, { observe: 'response' });
    }

    listIdNamePairs(): Observable<HttpResponse<Array<IIdNamePair>>> {
        return this.httpClient.get<Array<IIdNamePair>>(`${this.baseUrl}api/Company/ListIdNamePairs`, { observe: 'response' });
    }

    updateCompany(companyForm: any) {
        return this.httpClient.put(this.baseUrl + 'api/Company/Update',
            companyForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededCompany.next();
                })
            );
    }

    createCompany(companyForm: any) {
        return this.httpClient.post(this.baseUrl + 'api/Company/Create',
            companyForm, { observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededCompany.next();
                    this.refreshNeededAdmin.next();
                })
            );
    }

    removeAdmin(userId: string) {
        var cancelInvitation = true;
        return this.httpClient.delete(this.baseUrl + 'api/User/RemoveAdmin?userId=' + userId + '&cancelInvitations=' + cancelInvitation)
            .pipe(
                tap(() => {
                    this.refreshNeededAdmin.next();
                })
            );
    }

    removeCompany(companyId: string) {
        return this.httpClient.delete(this.baseUrl + 'api/Company/Remove?companyId=' + companyId)
            .pipe(
                tap(() => {
                    this.refreshNeededCompany.next();
                })
            );
    }

    createAdmin(userInfoForm: any) {
        return this.httpClient.post(this.baseUrl + 'api/User/CreateAdmin',
            JSON.stringify(userInfoForm),
            { headers: headers, observe: 'response' })
            .pipe(
                tap(() => {
                    this.refreshNeededAdmin.next();
                })
            );
    }
}