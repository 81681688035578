import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import intlTelInput, { CountryData, Options } from "intl-tel-input";

@Directive({
    selector: '[appCustomIntlTelInput]'
})
export class CustomIntlTelInputDirective implements OnInit {
    @Input('initialisationOptions') options: Options = {
        initialCountry: 'pl',
        preferredCountries: ['pl', 'gb']
    };
    @Output() countryChange = new EventEmitter<CountryData>();
    constructor(public viewContainerRef: ViewContainerRef) { }

    ngOnInit(): void {
        const inputRef: ElementRef<HTMLInputElement> = this.viewContainerRef.element;
        const input: HTMLInputElement = inputRef.nativeElement;

        if (input) {
            const iti = intlTelInput(input, this.options);
            
            iti.promise
            .then(() => {
                input.addEventListener("countrychange", (e) => {
                    const countryData: CountryData = iti.getSelectedCountryData();

                    this.countryChange.emit(countryData);
                });
            });
        }
    }
}