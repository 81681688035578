<div class="row">
    <div class="col-sm-12 text-center">
        <mat-form-field>
            <mat-select [(value)]="selected">
                <mat-option value="pl-PL">
                    <img with="10" height="10" src="assets/img/poland-flag-icon-64.png">
                    {{ 'shared.polish' | translate }}
                </mat-option>
                <mat-option value="en-GB">
                    <img with="10" height="10" src="assets/img/united-kingdom-flag-icon-64.png">
                    {{ 'shared.english' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-sm-12">
        <button class="button-edit m-1 w-100" mat-button (click)="onLangChange()">{{ 'language-dialog.view.changeLang' | translate }}</button>
    </div>
    <div class="col-sm-12">
        <mat-dialog-actions class="mat-dialog-actions-details" align="end">
            <button mat-button mat-dialog-close class="text-uppercase">{{ 'shared.close' | translate }}</button>
        </mat-dialog-actions>
    </div>
</div>