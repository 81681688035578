import { InvitationStatus } from "../enums/invitationStatus.enum";

export class CustomAlphaNumericSort {
    sortingFunction(value1: any, value2: any, order: number): any {
        let result: number;

        if (value1 === value2 || (!value1 && value2)) {
            result = 0;
        }
        else if (!value1) {
            result = 1;
        }
        else if (!value2) {
            result = -1;
        }
        else {
            result = value1.localeCompare(value2, undefined, { numeric: true });
        }
        return (order * result);
    }
}

export class InvitationStatusSort {
    sortingFunction(value1: any, value2: any, order: number): number {
        let result: number;
        if (value1 === value2) return 0;
        if (!value1 || !(value1 in InvitationStatus)) {
            result = 1;
        }
        else if (!value2 || !(value2 in InvitationStatus)) result = -1;
        else if (InvitationStatus[value1] < InvitationStatus[value2]) result = -1;
        else result = 1;
        return (order * result);
    }
}