import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class SecurityOfficerStatisticsService {
    swipeTab = new Subject<any>();
    changeStatisticType = new Subject<any>();
    filterDetails = new Subject<any>();
    filterStatisticType = new Subject<any>();
    invokeStatisticChange = new Subject<any>();
    liveParkingTypes = new BehaviorSubject<any>([]);
    liveParkingStatuses = new BehaviorSubject<any>([]);
    liveParkingCompanyIds = new BehaviorSubject<any>([]);
    liveParkingParkingIds = new BehaviorSubject<any>([]);
    liveParkingFilterChange = new Subject<any>();
    reservationParkingTypes = new BehaviorSubject<any>([]);
    reservationStatuses = new BehaviorSubject<any>([]);
    reservationCompanyIds = new BehaviorSubject<any>([]);
    reservationParkingIds = new BehaviorSubject<any>([]);
    reservationParkingFilterChange = new Subject<any>();

    swipeTab$(): Observable<any> {
        return this.swipeTab.asObservable();
    }

    changeStatisticType$(): Observable<any> {
        return this.changeStatisticType.asObservable();
    }
}