import { NgModule } from '@angular/core';
import { environment } from "../../environments/environment.prod";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireModule } from "@angular/fire/compat";

@NgModule({
    imports: [
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment["firebaseConfig"])
    ],
})

export class FireBaseModule {}
