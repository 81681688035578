<div class="main-content">
    <div class="container-fluid">
        <h2>
            Polityka prywatności
            SICCO sp. z o.o. z siedzibą w Warszawie
        </h2>

        <p>Zależy nam na tym, aby Państwa dane były bezpieczne i chcemy aby Państwo wiedzieli w jaki sposób je przetwarzamy. Niniejszy dokument powstał w celu udzielenia Państwu wszelkich informacji dotyczących ich przetwarzania w ramach strony https://sicco.cloud/. Niniejszy dokument zapewnia także podstawowe informacje dotyczące przetwarzania danych w ramach aplikacji Sicco.</p>

        <p>Zapewniamy, że robimy wszystko, aby Państwa dane pozostały poufne, a sposoby ich przetwarzania pozostawały w zgodzie z obowiązującymi przepisami prawa. Niniejszy dokument powstał, aby wyjaśnić jakie dane i w jakim celu przetwarzamy dane oraz jakie prawa przysługują Państwu w związku z przetwarzaniem przez nas Państwa danych osobowych.</p>

        <h4>Kto jest administratorem Państwa danych osobowych?</h4>

        <p>Administratorem Państwa danych osobowych jest SICCO sp. z o.o. z siedzibą w Warszawie, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 834758, NIP 5213893875, REGON 385818682, kapitał zakładowy 50.000 zł (zwana dalej Administratorem).</p>

        <h4>Jak mogą się Państwo skontaktować z administratorem?</h4>

        <p>Może się Pani/Pan skontaktować z Administratorem w następujący sposób:</p>
        <ul>
            <li>- listownie, wysyłając przesyłkę na adres: SICCO sp. z o.o. ul. Domaniewska 44, 02-672 Warszawa</li>
            <li>- pocztą elektroniczną na adres: office@sicco.cloud. </li>
        </ul>

        <h4>W jaki sposób przetwarzane są Państwa dane osobowe?</h4>

        <p>Państwa dane, w tym dane osobowe, trafiają do nas za pośrednictwem logów serwera oraz plików cookies wykorzystywanych przez stronę https://sicco.cloud/. Przetwarzamy je zgodnie z przepisami Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, Dz. Urz. UE L 119 z 04.05.2016) – zwanym dalej: „RODO”.</p>

        <p>Dodatkowo, przetwarzamy Państwa dane w związku z nawiązywaniem z nami kontaktu za pośrednictwem formularza kontaktowego albo innych danych kontaktowych podanych na naszej stronie internetowej. W takiej sytuacji możemy przetwarzać te dane, które Państwo podają nam za pośrednictwem tych środków komunikacji, w szczególności takie jak imię, nazwisko czy adres poczty elektronicznej. </p>

        <p>Ponadto, możemy przetwarzać niektóre dane osobowe również w celu zapewnienia działa platformy Sicco, wykorzystywanej przez naszych klientów. </p>

        <h4>Jaka jest podstawa prawna przetwarzania danych?</h4>

        <p>Prawną podstawą przetwarzania Państwa danych osobowych stanowi przepis art. 6 ust. 1 lit. f RODO, tj. prawnie uzasadnione interesy realizowane przez Administratora. Takim prawnie uzasadnionym interesem administratora jest umożliwienie prawidłowego wyświetlania naszej strony internetowej na Państwa urządzeniu, prowadzenie działań zmierzających do jej ulepszania i rozwijania, prowadzenie statystyk czy też likwidowanie błędów lub zapobieganie nielegalnym działaniom osób trzecich.</p>

        <p>W zakresie w jakim korzystają Państwo z usług świadczonych przez platformę Sicco, również możemy przetwarzać pani/Pana dane osobowe, takie jak logi czy pliki cookies. Jeżeli są Państwo stroną umowy  z nami, dotyczącą świadczenia usług, prawną podstawą do ich przetwarzania jest przepis art. 6 ust. 1 lit b) RODO, tzn. umożliwiający przetwarzanie danych osobowych w sytuacji gdy jest to niezbędne do wykonania umowy z klientem. W niektórych sytuacjach, np. gdy przetwarzamy dane pracowników oraz innych użytkowników platformy, którzy używają jej w związku z tym, że klient zdecydował się na jej wykorzystanie w ramach swojej działalności, prawną podstawą do przetwarzania tych danych jest nasz prawnie uzasadniony interes (przepis art. 6 ust. 1 lit f) RODO), jakim jest konieczność świadczenia usług na rzecz klienta zgodnie z umową. Nadmieniamy w tym miejscu, że przetwarzamy te dane jako administrator wyłącznie w wąskim zakresie, obejmującym zapewnienie prawidłowego działania platformy. Co do zasady, większość danych, które są zapisywane na platformie jest przetwarzana przez nas jako przez podmiot przetwarzający, zgodnie z umową powierzenia zawartą pomiędzy nami a podmiotem, który korzysta z funkcjonalności platformy.</p>

        <p>Dodatkowo, mogą Państwo skontaktować się z nami za pośrednictwem danych podanych na naszej stronie internetowej, takich jak adres e-mail albo formularz kontaktowy. W takim wypadku prawną podstawą do przetwarzania Państwa danych osobowych stanowi przepis art. 6 ust. 1 lit f) RODO, umożliwiający przetwarzanie danych w związku z istnieniem naszego prawnie uzasadnionego interesu. Takim interesem jest właśnie udzielenie odpowiedzi na Państwa wiadomości i nawiązanie kontaktu z Państwem.</p>

        <p>Niezależnie od tego, możemy poprosić Pana/Panią o wyrażenie zgody na zapisanie plików cookies na urządzeniu. Wyrażana przez Państwa zgoda jest zgodą udzielną zgodnie z przepisami ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. z 2019 r. poz. 2460) i dotyczy przechowywania informacji lub uzyskiwania dostępu do informacji już przechowywanej w telekomunikacyjnym urządzeniu końcowym abonenta lub użytkownika końcowego. Zgoda może zostać wyrażona również za pomocą ustawień oprogramowania zainstalowanego w wykorzystywanym telekomunikacyjnym urządzeniu końcowym lub konfiguracji usługi. Oznacza to, że zgoda taka może być wyrażona np. przez odpowiednie ustawienia przeglądarki (tj. programu wykorzystywanego do przeglądania stron internetowych). Również za ich pomocą może zostać wycofana w każdym czasie. Sposób zmiany ustawień zależy od używanego przez Państwa oprogramowania. Informacje o sposobie dokonywania zmian można znaleźć w pomocy do danego programu.</p>

        <h4>Jak długo przetwarzamy dane osobowe w związku z Państwa wizytą na naszej stronie?</h4>

        <p>Dane zapisane w związku z Państwa wizytą na naszej stronie internetowej, w tym w szczególności związane z tworzeniem logów oraz działaniem plików cookies, przetwarzane są przez okres jednego roku od dnia ich zapisania. Dotyczy to również logów oraz plików cookies, które są wykorzystywane przez nas w związku z działaniem platformy Sicco.</p>

        <p>Dane, które przetwarzamy w związku z kontaktowaniem się z nami za pomocą innych danych kontaktowych podanych w ramach strony internetowej, przetwarzane są przez okres trwania kontaktu oraz do upływu trzech miesięcy po jego zakończeniu.</p>

        <h4>W jaki sposób można zarządzać ustawieniami plików cookies?</h4>

        <p>Przetwarzanie Państwa danych osobowych nie stanowi obowiązku ustawowego lub umownego. Ustawienia plików cookies mogą zostać zmienione w ustawieniach przeglądarki. Wyłączenie plików cookies pochodzących ze strony https://sicco.cloud/ może powodować zmianę sposobu lub całkowitym zaprzestaniem wyświetlania się strony.</p>

        <h4>Jakie podmioty mogą być odbiorcami Państwa danych osobowych?</h4>

        <p>Jak większość stron internetowych, https://sicco.cloud/ korzysta z funkcjonalności podmiotów trzecich. Ich wykorzystywanie wiąże się z koniecznością wykorzystywania plików cookies od nich pochodzących. Odbiorcami Państwa danych osobowych mogą być podmioty świadczące usługi informatyczne i marketingowe na rzecz administratora strony. Dotyczy to również działania platformy Sicco. Są to:</p>
        <ul>
            <li>
                1)	Microsoft Ireland Ltd oraz Microsoft Corp z siedzibą w Redmond, Washington, USA – podmiot świadczący na naszą rzecz usługi chmurowe oraz serwerowe – przekazywanie danych do USA odbywa się na podstawie programu Tarcza Prywatności, którego Microsoft Corporation jest uczestnikiem. Więcej informacji na ten temat znajduje się pod tym linkiem: https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK&status=Active
            </li>
            <li>
                2)	Google Ireland Ltd oraz Google LLC z siedzibą w Mountain View, Kalifornia, USA – podmiot świadczący na naszą rzecz usługi analizy ruchu w ramach stron internetowych – przekazywanie danych do USA odbywa się na podstawie programu Tarcza Prywatności, którego Google LLC jest uczestnikiem. Więcej informacji na ten temat znajduje się pod tym linkiem: https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
            </li>
            <li>
                3)	Ingram Micro, Inc – podmiot świadczący na naszą rzecz usługi związane z obsługą chmury – przekazywanie danych do USA odbywa się na podstawie programu Tarcza Prywatności, którego Ingram Micro, Inc jest uczestnikiem. Więcej informacji na ten temat znajduje się pod tym linkiem: https://www.privacyshield.gov/participant?id=a2zt0000000TTPSAA4&status=Active
            </li>
            <li>
                4)	SquareTec Balicki Kozłowski Weissenberg sp. j. z siedzibą w Bydgoszczy – podmiot świadczący na naszą rzecz usługi wsparcia technicznego, rozwoju oprogramowania oraz bieżącego administrowania oprogramowaniem.
            </li>
        </ul>


        <h4>Jakie prawa przysługują mi w związku z przetwarzaniem moich danych osobowych?</h4>
        <p>Informujemy, że przysługują Pani/Panu prawa do:</p>
        <ul>
            
            <li>a) żądania dostępu do swoich danych osobowych,</li>
            
            <li>b) żądania sprostowania swoich danych osobowych, </li>
            
            <li>c) żądania usunięcia swoich danych osobowych, </li>
            
            <li>d) żądania ograniczenia przetwarzania swoich danych osobowych, </li>
            
            <li>e) przeniesienia swoich danych osobowych, w zakresie w jakim przetwarzanie danych odbywa się na podstawie umowy, </li>
            
            <li>f) wniesienia sprzeciwu wobec przetwarzania swoich danych osobowych, </li>
            
            <li>g) wniesienia skargi do organu nadzorczego. </li>
        </ul>

        <h4>Czy będą podejmowane wobec minie zautomatyzowane decyzje?</h4>
        <p>Administrator strony nie wykorzystuje plików cookies do podejmowania zautomatyzowanych decyzji wobec użytkownika, w tym nie stosuje profilowania. </p>

        <h4>Czym są logi serwera? </h4>

        <p>Logi serwera są to zapisy zdarzeń na serwerze wykorzystywanym przez stronę internetową. Obejmują one informacje takie jak IP wykorzystywanego przez Państwa urządzenia, dane dotyczące przeglądarki internetowej, rodzaj urządzenia ______ . Logi wykorzystywane są wyłącznie w celach administrowania stroną, a dostęp do nich mają tylko administratorzy.</p>

        <h4>Czym są pliki cookies? </h4>

        <p>Niemal wszystkie strony internetowe wykorzystują pliki cookies. Są to pliki zapisywane na urządzeniu odwiedzającego (np. komputerze lub telefonie). Zbierane informacje obejmować mogą zarówno dane osobowe jak i inne informacje, niepowiązane z konkretną osobą fizyczną. Wyróżniamy pliki cookies techniczne, które pozwalają na zapewnienie prawidłowego działania strony oraz poszczególnych jej funkcjonalności, analityczne, wykorzystywane w celu weryfikacji liczby odwiedzających stronę internetową oraz marketingowe, służące do wyświetlania treści reklamowych dostosowanych do użytkownika. </p>

        <p>W ramach naszej strony internetowej wykorzystujemy cookies techniczne oraz analityczne. Dzięki nim nasza strona poprawnie działa, co uzależnione jest m.in. od parametrów urządzeń odwiedzającego oraz używanej przez niego przeglądarki. Dają nam one również możliwość analizowania ruchu na stronie w celu zwiększenia jej czytelności i dostosowania do potrzeb odbiorców. Dane te nie mają na celu śledzenia działań poszczególnych osób fizycznych, a jedynie techniczną obsługę strony. </p>

        <h4>Przez jaki czas pliki cookies są przechowywane na Państwa urządzeniach? </h4>

        <p>Pliki cookies możemy podzielić na sesyjne oraz trwałe. Pierwsze z nich usuwane są wraz z zamknięciem przeglądarki internetowej, a więc z zakończeniem jej sesji. Pliki trwałe są przechowywane przez dłuższy czas, choć mogą również zostać usunięte fizycznie przez użytkownika. W przypadku ich nieusunięcia przez użytkownika będą przechowywane tak długo, jak zostało to określone w parametrach poszczególnych z nich. </p>

        <h4>Jakie dane mogą być przetwarzane przez pliki cookies? </h4>

        <p>Dzięki plikom cookies mogą być przetwarzane następujące dane: </p>
        <ul>

            <li>a) adres IP odwiedzającego;</li>
            
            <li>b) data i godzina wysłania żądania do serwera;</li>
            
            <li>c) rodzaj urządzenia, z którego uzyskiwany jest dostęp (np. urządzenie moblilne);</li>
            
            <li>d) nazwa i wersja przeglądarki internetowej;</li>
            
            <li>e) lokalizacja;</li>
            
            <li>f) rozdzielczość ekranu;</li>

        </ul>

    </div>
</div>